import React, { useEffect, useState } from "react";

import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { deleteUiRule, fetchRules } from "../../redux/action/rules.action";
import { Fragment } from "react";
import { Button, Collapse, Modal } from 'react-bootstrap';
import SimpleRuleEditor from "../components/SimpleRuleEditor.Component";
import { deleteCategory, fetchCategoyGroupList, fetchCategoyList } from "../../redux/action/category.action";
import { setShowAddRuleModal, setShowEditCategoryModal } from "../../redux/action/ui.action";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import { updateToken } from "../../redux/action/auth.action";
import _ from 'lodash'
import EditCategory from "../components/EditCategory.Component";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import {useTranslation} from "react-i18next";

export const CategoriesView = () => {
  const { t, i18n } = useTranslation('common');//国际化
  const dispatch = useDispatch()
  const { user, getAccessTokenSilently } = useAuth0();
  const categories = useSelector(state => state.category.list);
  const [token, setToken] = useState('')
  const showEditCategoryModal = useSelector(state => state.ui.showEditCategoryModal);

  const [currentCategory, setCurrentCategory] = useState({});

  const { SearchBar } = Search;

  function actionFormatter(cell, row) {
    return (
      <div className="group group-middle">
        <button type="submit" className="button button-sm button-primary-outline button-winona" onClick={(e) => {
          e.preventDefault();
          setCurrentCategory(row)
          dispatch(setShowEditCategoryModal({ show: true }));
        }}
        >{t('home.Edit')}</button>
        <button type="submit" className="button button-sm button-primary-outline button-winona" onClick={(e) => {
          if (window.confirm('Are you sure you wish to delete this item?')) {
            dispatch(deleteCategory({ token: token, id: row.id }));
          }
        }}
        >{t('home.Delete')}</button>
      </div>
    );
  }

  function conditionsFormatter(cell, row) {
    return (
      <Fragment key={"rule_" + row.id}>
        {row.properties &&
          row.properties.map(function (elem) {
            return elem.name;
          }).join("; ")
        }
      </Fragment>
    );
  }

  function groupInfoFormatter(cell, row) {
    return (
      <Fragment>
        {row.name}<br />
        {t('home.Type')}: {row.categoryType}<br />
        {t('home.Group')}: {row.categoryGroup ? row.categoryGroup.name : "-"}
      </Fragment>
    );
  }

  const columns = [{
    dataField: 'id',
    text: 'ID',
    sort: true,
    headerClasses: 'width-procent-10'
  }, {
    dataField: 'name',
    text: 'Category Name',
    sort: true,
    formatter: groupInfoFormatter,
    headerClasses: 'width-procent-20'
  }, {
    dataField: 'properties',
    text: 'Properties',
    formatter: conditionsFormatter
  },
  {
    dataField: 'action',
    text: 'Action',
    formatter: actionFormatter,
    headerClasses: 'width-procent-20'
  }];


  useEffect(() => {
    getAccessTokenSilently().then(token => {
      setToken(token)
      dispatch(fetchCategoyList({ token: token }))
      dispatch(updateToken({ token: token }))
    })
  }, [])

  const options = {
    custom: true,
    totalSize: categories.length
  };

  return (
    <>
      <Modal show={showEditCategoryModal} size="xl" dialogClassName="modal-details">
        <Modal.Header>
          <Modal.Title>{t('home.EditCategory')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditCategory category={currentCategory} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="button button-icon button-icon-left button-winona" onClick={(e) => {
            dispatch(setShowEditCategoryModal({ show: false }));
          }}>
            {t('home.Close')}
          </Button>
        </Modal.Footer>
      </Modal>

      <section className="section section-lg text-center">
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <div className="group group-middle">
                <button type="button" className="button" onClick={(e) => {
                  e.preventDefault();
                  setCurrentCategory({})
                  dispatch(setShowEditCategoryModal({ show: true }));
                }}
                >{t('home.Addnewcategory')}</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {categories && categories.length > 0 &&
        <section className="section section-lg text-center">
          <div className="container">
            <PaginationProvider
              pagination={paginationFactory(options)}
            >
              {
                ({
                  paginationProps,
                  paginationTableProps,
                }) => (
                  <div>
                    <PaginationListStandalone
                      {...paginationProps}
                    />
                    <ToolkitProvider
                      bootstrap4
                      keyField="id"
                      data={categories}
                      columns={columns}
                      search
                    >
                      {
                        toolkitprops => (
                          <div>
                            <div style={{ float: "left", padding: "10px 0px" }}>
                              <SearchBar {...toolkitprops.searchProps} style={{ float: "left" }} placeholder="{t('home.SearchCategoryName')}" />
                            </div>
                            <BootstrapTable
                              striped
                              hover
                              {...toolkitprops.baseProps}
                              {...paginationTableProps}
                            />
                          </div>
                        )
                      }
                    </ToolkitProvider>
                    <PaginationListStandalone
                      {...paginationProps}
                    />
                  </div>

                )
              }
            </PaginationProvider>
          </div>
        </section>
      }
    </>
  );
};

export default withAuthenticationRequired(CategoriesView, {
  onRedirecting: () => <Loading />,
});
