import React, { useEffect} from 'react';
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import { fetchCurrentEditProperty, resetCurrentEditProperty, updateProperty } from '../../../redux/action/category.action';
import { Field, Formik } from 'formik';

const EditProperty = (props) => {
  const dispatch = useDispatch()
  let currentEditProperty = useSelector(state => state.category.currentEditProperty);

  
  useEffect(() => {
    if (props.property.id ){
      dispatch(fetchCurrentEditProperty({ id: props.property.id}))
    } else {
      dispatch(resetCurrentEditProperty())
    }
  }, [props.property])

  return (
    <>
      { currentEditProperty && currentEditProperty.id &&
         <Formik
         initialValues={currentEditProperty}
         enableReinitialize={true}
         validate={values => {
           const errors = {};
           return errors;
         }}
         onSubmit={(values) => {
           dispatch(updateProperty({id: values.id, parameters:values}));
         }}>
         {({ values, errors, handleSubmit, setFieldValue, setValues }) => (
           <form onSubmit={handleSubmit} autoComplete="off" className="rd-form rd-mailform">
             <div className="row">
               <div className="col-md-6">
                 <div className="form-group">
                   <label htmlFor="listOrder">Name</label>
                   <Field id="name" type="text" name="name" placeholder="Name" className="form-control" value={values.name || ''}
                     onChange={(event) => {
                       setFieldValue("name", event.currentTarget.value);
                     }}
                   />
                 </div>
               </div>
             </div>
             <div className="row">
               <div className="col-md-6">
                 <div className="form-group">
                   <label htmlFor="counter">Counter</label>
                   <Field id="counter" type="text" name="counter" placeholder="counter" className="form-control" value={values.counter || ''}
                     onChange={(event) => {
                       setFieldValue("counter", event.currentTarget.value);
                     }}
                   />
                 </div>
               </div>
             </div>
             <div className="row">
               <div className="col-md-6">
                 <div className="form-group">
                   <label htmlFor="listOrder">List Order</label>
                   <Field id="listOrder" type="text" name="listOrder" placeholder="listOrder" className="form-control" value={values.listOrder || ''}
                     onChange={(event) => {
                       setFieldValue("listOrder", event.currentTarget.value);
                     }}
                   />
                 </div>
               </div>
             </div>
             <div className="row">
               <div className="col-md-12">
                 <div className="form-group">
                   <button type="submit" className="button button-primary">Update</button>
                 </div>
               </div>
             </div>
           </form>
         )}
       </Formik>
      }
    </>
  );
}

export default EditProperty;
