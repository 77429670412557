import { apiRequest } from "../action/api.action"
import { getHost } from "../../react/utils/host"
import { error,  info,  message,  success, warning} from 'react-toastify-redux';
import { ADD_CLAIM_ITEM_BY_ID, ADD_CLAIM_ITEM_BY_ID_FAILURE, ADD_CLAIM_ITEM_BY_ID_SUCCESS, DELETE_CLAIM_ATTACHMENT_BY_ID, DELETE_CLAIM_ATTACHMENT_BY_ID_FAILURE, DELETE_CLAIM_ATTACHMENT_BY_ID_SUCCESS, DELETE_CLAIM_ITEM_ATTACHMENT_BY_ID, DELETE_CLAIM_ITEM_ATTACHMENT_BY_ID_FAILURE, DELETE_CLAIM_ITEM_ATTACHMENT_BY_ID_SUCCESS, DELETE_CLAIM_ITEM_BY_ID, DELETE_CLAIM_ITEM_BY_ID_FAILURE, DELETE_CLAIM_ITEM_BY_ID_SUCCESS, DELETE_CLAIM_REASON_BY_ID, DELETE_CLAIM_REASON_BY_ID_FAILURE, DELETE_CLAIM_REASON_BY_ID_SUCCESS, FETCH_CLAIM_BY_ID, FETCH_CLAIM_BY_ID_FAILURE, FETCH_CLAIM_BY_ID_SUCCESS, FETCH_CLAIM_HISTORY_BY_CLAIM_ID, FETCH_CLAIM_HISTORY_BY_CLAIM_ID_FAILURE, FETCH_CLAIM_HISTORY_BY_CLAIM_ID_SUCCESS, FETCH_CLAIM_LIST, FETCH_CLAIM_LIST_FAILURE, FETCH_CLAIM_LIST_SUCCESS, FETCH_CLAIM_REASONS, FETCH_CLAIM_REASONS_FAILURE, FETCH_CLAIM_REASONS_SUCCESS, FETCH_CLAIM_REASON_TREE, FETCH_CLAIM_REASON_TREE_FAILURE, FETCH_CLAIM_REASON_TREE_SUCCESS, GET_SERIEN_NUMBERS, GET_SERIEN_NUMBERS_FAILURE, GET_SERIEN_NUMBERS_SUCCESS, POST_CLAIM, POST_CLAIM_FAILURE, POST_CLAIM_SUCCESS, SUBMIT_CLAIM_BY_ID, SUBMIT_CLAIM_BY_ID_FAILURE, SUBMIT_CLAIM_BY_ID_SUCCESS, UPDATE_CLAIM_ITEM_BY_ID, UPDATE_CLAIM_REASON, UPDATE_CLAIM_REASON_FAILURE, UPDATE_CLAIM_REASON_SUCCESS } from "../action/claim.action";

export const claimFlow = ({ dispatch }) => next => action => {
  next(action)
  if (action.type === POST_CLAIM) {
    let url = `${getHost()}/internal-api/v1/claims/new`
    dispatch(apiRequest("POST", url, action.payload, POST_CLAIM_SUCCESS, POST_CLAIM_FAILURE));
  }

  if (action.type === FETCH_CLAIM_LIST) {
    let url = `${getHost()}/internal-api/v1/claims?status=${action.payload.status?action.payload.status:""}`
    dispatch(apiRequest("GET", url, null, FETCH_CLAIM_LIST_SUCCESS, FETCH_CLAIM_LIST_FAILURE));
  }

  if (action.type === FETCH_CLAIM_BY_ID || action.type === POST_CLAIM_SUCCESS || action.type === SUBMIT_CLAIM_BY_ID_SUCCESS || action.type=== DELETE_CLAIM_ATTACHMENT_BY_ID_SUCCESS || action.type === DELETE_CLAIM_ITEM_BY_ID_SUCCESS || action.type === ADD_CLAIM_ITEM_BY_ID_SUCCESS || action.type === DELETE_CLAIM_ITEM_ATTACHMENT_BY_ID_SUCCESS) {
    let url = `${getHost()}/internal-api/v1/claims/${action.payload.id}`
    dispatch(apiRequest("GET", url, null, FETCH_CLAIM_BY_ID_SUCCESS, FETCH_CLAIM_BY_ID_FAILURE));
  }

  if (action.type === DELETE_CLAIM_ITEM_BY_ID) {
    let url = `${getHost()}/internal-api/v1/claims/${action.payload.id}/item/${action.payload.claimItemId}`
    dispatch(apiRequest("DELETE", url, null, DELETE_CLAIM_ITEM_BY_ID_SUCCESS, DELETE_CLAIM_ITEM_BY_ID_FAILURE));
  }

  if (action.type === ADD_CLAIM_ITEM_BY_ID) {
    let url = `${getHost()}/internal-api/v1/claims/${action.payload.id}/item`
    dispatch(apiRequest("POST", url, {parameters: action.payload.item}, ADD_CLAIM_ITEM_BY_ID_SUCCESS, ADD_CLAIM_ITEM_BY_ID_FAILURE));
  }

  if (action.type === UPDATE_CLAIM_ITEM_BY_ID) {
    let url = `${getHost()}/internal-api/v1/claims/${action.payload.id}/item`
    dispatch(apiRequest("POST", url, {parameters: action.payload.item}, ADD_CLAIM_ITEM_BY_ID_SUCCESS, ADD_CLAIM_ITEM_BY_ID_FAILURE));
  }

  if (action.type === DELETE_CLAIM_ATTACHMENT_BY_ID) {
    let url = `${getHost()}/internal-api/v1/claims/${action.payload.id}/attachment/${action.payload.claimAttachmentId}`
    dispatch(apiRequest("DELETE", url, null, DELETE_CLAIM_ATTACHMENT_BY_ID_SUCCESS, DELETE_CLAIM_ATTACHMENT_BY_ID_FAILURE));
  }
  
  if (action.type === DELETE_CLAIM_ITEM_ATTACHMENT_BY_ID) {
    let url = `${getHost()}/internal-api/v1/claims/${action.payload.id}/item/${action.payload.claimItemId}/attachment/${action.payload.claimItemAttachmentId}`
    dispatch(apiRequest("DELETE", url, null, DELETE_CLAIM_ITEM_ATTACHMENT_BY_ID_SUCCESS, DELETE_CLAIM_ITEM_ATTACHMENT_BY_ID_FAILURE));
  }

  if (action.type === SUBMIT_CLAIM_BY_ID) {
    let url = `${getHost()}/internal-api/v1/claims/${action.payload.id}/submit`
    dispatch(apiRequest("PUT", url, null, SUBMIT_CLAIM_BY_ID_SUCCESS, SUBMIT_CLAIM_BY_ID_FAILURE));
  }

  if (action.type === POST_CLAIM_SUCCESS || action.type === SUBMIT_CLAIM_BY_ID_SUCCESS) {
    dispatch(success("Claim  save / udpate successful"));
  }

  if (action.type === FETCH_CLAIM_REASONS || action.type === DELETE_CLAIM_REASON_BY_ID_SUCCESS || action.type === UPDATE_CLAIM_REASON_SUCCESS) {
    let url = `${getHost()}/internal-api/v1/reasons`
    dispatch(apiRequest("GET", url, null, FETCH_CLAIM_REASONS_SUCCESS, FETCH_CLAIM_REASONS_FAILURE));
  }

  if (action.type === FETCH_CLAIM_REASON_TREE || action.type === DELETE_CLAIM_REASON_BY_ID_SUCCESS || action.type === UPDATE_CLAIM_REASON_SUCCESS) {
    let url = `${getHost()}/internal-api/v1/reasons/tree`
    dispatch(apiRequest("GET", url, null, FETCH_CLAIM_REASON_TREE_SUCCESS, FETCH_CLAIM_REASON_TREE_FAILURE));
  }

  if (action.type === DELETE_CLAIM_REASON_BY_ID) {
    let url = `${getHost()}/internal-api/v1/reasons/${action.payload.id}`
    dispatch(apiRequest("DELETE", url, null, DELETE_CLAIM_REASON_BY_ID_SUCCESS, DELETE_CLAIM_REASON_BY_ID_FAILURE));
  }

  if (action.type === UPDATE_CLAIM_REASON) {
    let url = `${getHost()}/internal-api/v1/reasons`
    dispatch(apiRequest("POST", url, action.payload, UPDATE_CLAIM_REASON_SUCCESS, UPDATE_CLAIM_REASON_FAILURE));
  }

  if (action.type === GET_SERIEN_NUMBERS) {
    let url = `${getHost()}/internal-api/v1/lieferschein/${action.payload.lieferscheinNr}/serien-numbers/`
    dispatch(apiRequest("GET", url, null, GET_SERIEN_NUMBERS_SUCCESS, GET_SERIEN_NUMBERS_FAILURE));
  }
  if (action.type === FETCH_CLAIM_HISTORY_BY_CLAIM_ID) {
    let url = `${getHost()}/internal-api/v1/claims/${action.payload.id}/history/`
    dispatch(apiRequest("GET", url, null, FETCH_CLAIM_HISTORY_BY_CLAIM_ID_SUCCESS, FETCH_CLAIM_HISTORY_BY_CLAIM_ID_FAILURE));
  }

  

};

export const claimMdl = [claimFlow];