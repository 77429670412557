import { ADD_PROPERTY_SUCCESS, DELETE_PROPERTY_SUCCESS, UPDATE_CATEGORY_SUCCESS } from "../action/category.action";
import { ADD_TO_BLOCK_ARRAY, ADD_TO_CHANGED_CATEGORY_IDS, ADD_TO_DEFAULT_ARRAY, ADD_TO_MAX_VALUE_ARRAY, REMOVE_FROM_BLOCK_ARRAY, REMOVE_FROM_DEFAULT_ARRAY, REMOVE_FROM_MAX_VALUE_ARRAY, SHOW_ADD_RULE_MODAL, SHOW_EDIT_CATEGORY_MODAL, UPDATE_UI } from "../action/ui.action";

const initialState = {
  showTree: false,
  blockArray: new Set(),
  defaultArray: new Set(),
  maxValueArray: new Set(),
  showAddRuleModal:false,
  showEditCategoryModal:false,
  changedCategoryIds:new Set(),
  seq : 1,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_UI:
      return {
        ...state,
        ...action.payload,
      }
    case ADD_TO_BLOCK_ARRAY:
      action.payload.forEach(d => {
        state.blockArray.add(d)
      })
      return {
        ...state,
        seq : state.seq + 1
      }
    case REMOVE_FROM_BLOCK_ARRAY:
      action.payload.forEach(d => {
        state.blockArray.delete(d)
      })
      return {
        ...state,
        seq : state.seq + 1
      }
    case ADD_TO_MAX_VALUE_ARRAY:
      action.payload.forEach((d) => {
        state.maxValueArray.add(d)
      })
      return {
        ...state,
        seq : state.seq + 1
      }
    case REMOVE_FROM_MAX_VALUE_ARRAY:
      action.payload.forEach((d) => {
        state.maxValueArray.forEach(x => x.name === d.name ? state.maxValueArray.delete(x) : x)
      })
      return {
        ...state,
        seq : state.seq + 1
      }
    case ADD_TO_DEFAULT_ARRAY:
      action.payload.forEach(d => {
        state.defaultArray.add(d)
      })
      return {
        ...state,
        seq : state.seq + 1
      }
      case ADD_TO_CHANGED_CATEGORY_IDS:
        state.changedCategoryIds.add(action.payload.id)
        return {
          ...state,
          seq : state.seq + 1
        }
    case REMOVE_FROM_DEFAULT_ARRAY:
      action.payload.forEach(d => {
        state.defaultArray.delete(d)
      })
      return {
        ...state,
        seq : state.seq + 1
      }
    case SHOW_ADD_RULE_MODAL:
      return {
        ...state,
        showAddRuleModal:action.payload.show
      }
    case  UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        showEditCategoryModal:false
      }
    case SHOW_EDIT_CATEGORY_MODAL:
      return {
        ...state,
        showEditCategoryModal:action.payload.show
      }
    default:
      return state;
  }
}