import { NEXT_STEP, RESET_STEPS, SET_CURRENT_STEP } from "../action/step.action";

const initialState = {
  stepsArray: [{ id: 1, name: "Baureihen" , nextLevel:'BAUREIHEN' }],
  currentStep: { id: 1, name: "Baureihen" , nextLevel:'BAUREIHEN'}
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case NEXT_STEP:
      if (state.stepsArray[state.stepsArray.length - 1].id !== action.payload.category.id) {
        state.stepsArray.push(action.payload.category)
      }
      return {
        ...state,
        currentStep: action.payload.category
      }
    case SET_CURRENT_STEP:
      state.stepsArray.length = 1;
      return {
        ...state,
        currentStep: state.stepsArray[0]
      }
    case RESET_STEPS:
      return {
        stepsArray: [{ id: 1, name: "Baureihen" , nextLevel:'BAUREIHEN' }],
        currentStep: { id: 1, name: "Baureihen" , nextLevel:'BAUREIHEN'}
      }
    default:
      return state;
  }
}