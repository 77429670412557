export const POST_USER_CLAIM = "post user claim"
export const POST_USER_CLAIM_SUCCESS = "post user claim success"
export const POST_USER_CLAIM_FAILURE = "post user claim failure"

export const FETCH_USER_CLAIM_LIST = "fetch user claim list"
export const FETCH_USER_CLAIM_LIST_SUCCESS = "fetch user claim list success"
export const FETCH_USER_CLAIM_LIST_FAILURE = "fetch user claim list failure"

export const FETCH_USER_CLAIM_BY_ID = "fetch user claim by id"
export const FETCH_USER_CLAIM_BY_ID_SUCCESS = "fetch user claim by id success"
export const FETCH_USER_CLAIM_BY_ID_FAILURE = "fetch user claim by id failure"

export const DELETE_USER_CLAIM_ITEM_BY_ID = "delete user claim item by id"
export const DELETE_USER_CLAIM_ITEM_BY_ID_SUCCESS = "delete user claim item by id success"
export const DELETE_USER_CLAIM_ITEM_BY_ID_FAILURE = "delete user claim item by id failure"

export const UPDATE_USER_CLAIM_ITEM_BY_ID = "update user claim item by id"
export const UPDATE_USER_CLAIM_ITEM_BY_ID_SUCCESS = "update user claim item by id success"
export const UPDATE_USER_CLAIM_ITEM_BY_ID_FAILURE = "update user claim item by id failure"

export const ADD_USER_CLAIM_ITEM_BY_ID = "add user claim item by id"
export const ADD_USER_CLAIM_ITEM_BY_ID_SUCCESS = "add user claim item by id success"
export const ADD_USER_CLAIM_ITEM_BY_ID_FAILURE = "add user claim item by id failure"

export const SET_CURRENT_USER_CLAIM_ITEM = "set current user claim item"

export const UPDATE_USER_CLAIM_ITEM = "update user claim item"

export const postUserClaim = (data) => ({
  type: POST_USER_CLAIM,
  payload: data
});

export const fetchUserClaimList = (data) => ({
  type: FETCH_USER_CLAIM_LIST,
  payload: data
});

export const fetchUserClaimById = (data) => ({
  type: FETCH_USER_CLAIM_BY_ID,
  payload: data
});

export const deleteUserClaimItemById = (data) => ({
  type: DELETE_USER_CLAIM_ITEM_BY_ID,
  payload: data
});

export const setCurrentUserClaimItem = (data) => ({
  type: SET_CURRENT_USER_CLAIM_ITEM,
  payload: data
});


export const updateUserClaimItemById = (data) => ({
  type: UPDATE_USER_CLAIM_ITEM_BY_ID,
  payload: data
});

export const updateUserClaimItem = (data) => ({
  type: UPDATE_USER_CLAIM_ITEM,
  payload: data
});

export const addUserClaimItemById = (data) => ({
  type: ADD_USER_CLAIM_ITEM_BY_ID,
  payload: data
});






