import { JSONEditor } from '@json-editor/json-editor'
import React, { Component, useEffect, useRef, useState } from "react";
import { Field, Formik, useFormikContext, ErrorMessage } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { addUiRule, fetchEditRuleById } from '../../redux/action/rules.action';
import _ from 'lodash'
import { Fragment } from 'react';
import { setShowAddRuleModal } from '../../redux/action/ui.action';

const SimpleRuleEditor = (props) => {
  const dispatch = useDispatch()
  const { user, getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState('')
  const [checkAllBaugroesse, setCheckAllBaugroesse] = useState(true);
  const [checkAllBauform, setCheckAllBauform] = useState(true);
  const [checkAllFlanschgroesse, setCheckAllFlanschgroesse] = useState(true);
  const categoryList = useSelector(state => state.category.list);
  const groupList = useSelector(state => state.category.groupList);
  const [selectedCategory, setSelectedCategory] = useState('');
  const currentEditRule = useSelector(state => state.rules.currentEditRule);
  const [disableFreeText, setDisableFreeText] = useState(false)

  useEffect(() => {
    if (props.ruleId) {
      dispatch(fetchEditRuleById({ id: props.ruleId }))
    }
  }, [])

  return (
    <>
      <Formik
        initialValues={currentEditRule}
        enableReinitialize={true}
        validate={values => {
          console.info("validate rule:", values);
          const errors = {};
          if (!values.name) {
            errors.name = 'Required';
          }
          return errors;
        }}
        onSubmit={(values) => {
          let array = []
          values.constaintCategories.forEach((c) => {
            array.push({ name: c.name, values: values[_.snakeCase(c.name)] })
          })
          values.eventCategories.forEach((c) => {
            array.push({
              name: c.name,
              values: values[_.snakeCase(c.name)],
              defaultValue: values[_.snakeCase(c.name) + "_default"],
              defaultValues: values[_.snakeCase(c.name) + "_default_values"],
              valueConstraint: values[_.snakeCase(c.name) + "_value_constraint"],
              blockCategory: values[_.snakeCase(c.name) + "_block_category"]
            })
          })
          dispatch(addUiRule({ token: token, parameters: { ...values, categoryValues: array } }))
          dispatch(setShowAddRuleModal({ show: false }));
        }}>
        {({ values, errors, handleSubmit, setFieldValue, setValues }) => (
          <form onSubmit={handleSubmit} autoComplete="off" className="rd-form rd-mailform">
            {values.id &&
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="id">ID</label>
                    <Field id="id" type="text" name="id" disabled className="form-control" value={values.id || ''}
                    />
                  </div>
                </div>
              </div>
            }

            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="name">Rule Name(Required)<ErrorMessage name="name" render={msg => <span className="error">(*{msg})</span>} /></label>
                  <Field id="name" type="text" name="name" placeholder="Rule Name" className="form-control" value={values.name || ''}
                    onChange={(event) => {
                      setFieldValue("name", event.currentTarget.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="name">Rule Check Order</label>
                  <Field id="name" type="text" name="listOrder" placeholder="Rule Check Order" className="form-control" value={values.listOrder || '999'}
                    onChange={(event) => {
                      setFieldValue("listOrder", event.currentTarget.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-3">
                <select className="form-input select" value={selectedCategory || ''} onChange={(e) => {
                  setSelectedCategory(e.currentTarget.value)
                }}>
                  <option value="" label="Select a category" />
                  {groupList &&
                    groupList.map((g, index) => {
                      return (
                        <optgroup key={"properties_country_group_" + index} label={g.name}>
                          {
                            g.categories.map((c, index) => {
                              return (
                                <option key={"categoy_" + c.id} value={c.id}>{c.name}</option>
                              )
                            })
                          }
                        </optgroup>
                      )
                    })
                  }
                </select>
              </div>
              <div className="col-md-9">
                <div className="group group-middle">
                  <button type="button" className="button button-primary" onClick={() => {
                    let categroy = categoryList.find(x => x.id.toString() === selectedCategory)
                    if (values.constaintCategories.indexOf(categroy) >= 0 || values.eventCategories.indexOf(categroy) >= 0) {
                      return
                    }
                    let array = [...values.constaintCategories]
                    array.push(categroy);
                    let a = categroy.properties.map(p => p.name)
                    setFieldValue(_.snakeCase(categroy.name), a)
                    setFieldValue("constaintCategories", array)
                  }}> Add To Conditions </button>
                  <button type="button" className="button button-primary-outline" onClick={() => {
                    let categroy = categoryList.find(x => x.id.toString() === selectedCategory)
                    if (values.constaintCategories.indexOf(categroy) >= 0 || values.eventCategories.indexOf(categroy) >= 0) {
                      return
                    }
                    let array = [...values.eventCategories]
                    array.push(categroy);
                    let a = categroy.properties.map(p => p.name)
                    setFieldValue(_.snakeCase(categroy.name), a)
                    setFieldValue("eventCategories", array)
                  }}> Add To events </button>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6" style={{ backgroundColor: "lightyellow" }}>
                <h3>Conditions</h3>
                {values.constaintCategories &&
                  values.constaintCategories.map(cc => {
                    return (
                      <Fragment key={"cc_" + cc.id}>
                        <div className="row">
                          <div className="col-md-12">
                            <div id="checkbox-group" className="checkbox-list">{cc.name}
                              <button type="button" onClick={() => {
                                let a = cc.properties.map(p => p.id.toString())
                                setFieldValue(_.snakeCase(cc.name), a)
                              }} >check all </button>
                              <button type="button" onClick={() => {
                                setFieldValue(_.snakeCase(cc.name), [])
                              }} >uncheck all </button>
                              <button type="button" onClick={() => {
                                let myArr = [...values.constaintCategories]
                                myArr = myArr.filter(function (item) { return item.id != cc.id; });
                                setFieldValue("constaintCategories", myArr)
                              }} >remove from conditions </button>
                            </div>
                            <div role="group" aria-labelledby="checkbox-group" className="checkbox-list">
                              {cc.properties &&
                                cc.properties.map(cp => {
                                  return (
                                    <label key={"cp_" + cp.id}><Field type="checkbox" name={_.snakeCase(cc.name)} value={cp.id.toString()} /> {cp.name}   </label>
                                  )
                                })
                              }
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    )
                  })
                }
              </div>
              <div className="col-md-6" style={{ backgroundColor: "lightcyan" }}>
                <h3>Events</h3>
                {values.eventCategories &&
                  values.eventCategories.map(cc => {
                    return (
                      <Fragment key={"cce_" + cc.id}>
                        {cc.categoryType === 'FREE_TEXT' &&
                          <div className="row" style={{ marginBottom: "10px" }}>
                            <div className="col-md-12">
                              <div id="checkbox-group" className="checkbox-list">{cc.name}
                                <button type="button" onClick={() => {
                                  let a = cc.properties.map(p => p.name)
                                  setFieldValue(_.snakeCase(cc.name), a)
                                }} >check all </button>
                                <button type="button" onClick={() => {
                                  setFieldValue(_.snakeCase(cc.name), [])
                                }} >uncheck all </button>
                                <button type="button" onClick={() => {
                                  let myArr = [...values.eventCategories]
                                  myArr = myArr.filter(function (item) { return item.id != cc.id; });
                                  setFieldValue("eventCategories", myArr)
                                }} >remove from events </button>
                              </div>
                            </div>

                            <div className="col-md-12" style={{ backgroundColor: "lightgreen" }}>
                              <label>
                                <Field type="checkbox" checked={values[_.snakeCase(cc.name) + "_block_category"] || false}
                                  onChange={(e) => {
                                    values[_.snakeCase(cc.name) + "_block_category"] = e.currentTarget.checked
                                    if (e.currentTarget.checked) {
                                      values[_.snakeCase(cc.name) + "_value_constraint"] = ''
                                      values[_.snakeCase(cc.name) + "_default"] = ''
                                      setDisableFreeText(true)
                                    } else {
                                      setDisableFreeText(false)
                                    }
                                    setValues(values)
                                  }} />Block Category</label>
                            </div>

                            <div className="col-md-12" style={{ backgroundColor: "lightgreen" }}>
                              <label htmlFor="valueConstraint">Max Constrain Value</label>
                              <Field disabled={disableFreeText} id="valueConstraint" type="text" name={_.snakeCase(cc.name) + "_value_constraint"} className="form-control" value={values[_.snakeCase(cc.name) + "_value_constraint"] || ''}
                              ></Field>
                            </div>

                            <div className="col-md-12" style={{ backgroundColor: "lightgreen" }}>
                              <label htmlFor="defaultValue">Default Value</label>
                              <Field id="defaultValue" disabled={disableFreeText} type="text" name={_.snakeCase(cc.name) + "_default"} className="form-control" value={values[_.snakeCase(cc.name) + "_default"] || ''}
                              ></Field>
                            </div>
                          </div>
                        }
                        {cc.categoryType === 'DROPDOWN' &&
                          <div className="row" style={{ marginBottom: "10px" }}>
                            <div className="col-md-12">
                              <div id="checkbox-group" className="checkbox-list">{cc.name}
                                <button type="button" onClick={() => {
                                  let a = cc.properties.map(p => p.name)
                                  setFieldValue(_.snakeCase(cc.name), a)
                                }} >check all </button>
                                <button type="button" onClick={() => {
                                  setFieldValue(_.snakeCase(cc.name), [])
                                }} >uncheck all </button>
                                <button type="button" onClick={() => {
                                  let myArr = [...values.eventCategories]
                                  myArr = myArr.filter(function (item) { return item.id != cc.id; });
                                  setFieldValue("eventCategories", myArr)
                                }} >remove from events </button>
                              </div>
                              <div role="group" aria-labelledby="checkbox-group" className="checkbox-list">
                                {cc.properties &&
                                  cc.properties.map(cp => {
                                    return (
                                      <label key={"cp_" + cp.id}><Field type="checkbox" name={_.snakeCase(cc.name)} value={cp.name} /> {cp.name}   </label>
                                    )
                                  })
                                }
                              </div>
                            </div>
                            <div className="col-md-12" style={{ backgroundColor: "lightgreen" }}>
                              <div id="my-radio-group">Default value</div>
                              <div role="group" aria-labelledby="my-radio-group" className="checkbox-list">
                                {cc.properties &&
                                  cc.properties.map(cp => {
                                    return (
                                      <label key={"cp_radio_" + cp.id}><Field type="radio" name={_.snakeCase(cc.name) + "_default"} value={cp.name} /> {cp.name}   </label>
                                    )
                                  })
                                }
                              </div>
                            </div>
                          </div>
                        }

                        {cc.categoryType === 'COLOR_DROPDOWN' &&
                          <div className="row" style={{ marginBottom: "10px" }}>
                            <div className="col-md-12">
                              <div id="checkbox-group" className="checkbox-list">{cc.name}
                                <button type="button" onClick={() => {
                                  let a = cc.properties.map(p => p.name)
                                  setFieldValue(_.snakeCase(cc.name), a)
                                }} >check all </button>
                                <button type="button" onClick={() => {
                                  setFieldValue(_.snakeCase(cc.name), [])
                                }} >uncheck all </button>
                                <button type="button" onClick={() => {
                                  let myArr = [...values.eventCategories]
                                  myArr = myArr.filter(function (item) { return item.id != cc.id; });
                                  setFieldValue("eventCategories", myArr)
                                }} >remove from events </button>
                              </div>
                              <div role="group" aria-labelledby="checkbox-group" className="checkbox-list">
                                {cc.properties &&
                                  cc.properties.map(cp => {
                                    return (
                                      <label key={"cp_" + cp.id}><Field type="checkbox" name={_.snakeCase(cc.name)} value={cp.name} /> {cp.name}   </label>
                                    )
                                  })
                                }
                              </div>
                            </div>
                            <div className="col-md-12" style={{ backgroundColor: "lightgreen" }}>
                              <div id="my-radio-group">Default value</div>
                              <div role="group" aria-labelledby="my-radio-group" className="checkbox-list">
                                {cc.properties &&
                                  cc.properties.map(cp => {
                                    return (
                                      <label key={"cp_radio_" + cp.id}><Field type="radio" name={_.snakeCase(cc.name) + "_default"} value={cp.name} /> {cp.name}   </label>
                                    )
                                  })
                                }
                              </div>
                            </div>
                          </div>
                        }

                        {cc.categoryType === 'CHECKBOX' &&
                          <div className="row" style={{ marginBottom: "10px" }}>
                            <div className="col-md-12">
                              <div id="checkbox-group" className="checkbox-list">{cc.name}
                                <button type="button" onClick={() => {
                                  let a = cc.properties.map(p => p.name)
                                  setFieldValue(_.snakeCase(cc.name), a)
                                }} >check all </button>
                                <button type="button" onClick={() => {
                                  setFieldValue(_.snakeCase(cc.name), [])
                                }} >uncheck all </button>
                                <button type="button" onClick={() => {
                                  let myArr = [...values.eventCategories]
                                  myArr = myArr.filter(function (item) { return item.id != cc.id; });
                                  setFieldValue("eventCategories", myArr)
                                }} >remove from events </button>
                              </div>
                              <div role="group" aria-labelledby="checkbox-group" className="checkbox-list">
                                {cc.properties &&
                                  cc.properties.map(cp => {
                                    return (
                                      <label key={"cp_" + cp.id}><Field type="checkbox" name={_.snakeCase(cc.name)} value={cp.name} /> {cp.name}   </label>
                                    )
                                  })
                                }
                              </div>
                            </div>
                            <div className="col-md-12" style={{ backgroundColor: "lightgreen" }}>
                              <div id="my-radio-group">Default values</div>
                              <div role="group" aria-labelledby="my-radio-group" className="checkbox-list">
                                {cc.properties &&
                                  cc.properties.map(cp => {
                                    return (
                                      <label key={"cp_radio_" + cp.id}><Field type="checkbox" name={_.snakeCase(cc.name) + "_default_values"} value={cp.name} /> {cp.name}   </label>
                                    )
                                  })
                                }
                              </div>
                            </div>
                          </div>
                        }
                      </Fragment>
                    )
                  })
                }
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <button type="submit" className="button button-primary">Submit</button>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}

export default SimpleRuleEditor;
