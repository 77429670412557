import React, { useEffect, useState, useRef } from 'react';
import { Button, Collapse, Table } from 'react-bootstrap';
import _ from 'lodash'
import { Field, useFormikContext } from 'formik';
import * as Icon from 'react-bootstrap-icons';
import { updateUi } from '../../redux/action/ui.action';
import { useDispatch, useSelector } from 'react-redux';
import ItemComponent from '../components/Item.Component '
import { NavLink } from 'react-router-dom';
import { saveRfq } from '../../redux/action/rfq.action';
import { updateToken } from '../../redux/action/auth.action';
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import {useTranslation} from "react-i18next";

const RequestForQuotation = (props) => {
  const { t, i18n } = useTranslation('common');//国际化
  const currentCategory = props.category
  const [showChild, setShowChild] = useState(props.showChild);
  const { user, getAccessTokenSilently } = useAuth0();
  const lastChild = props.lastChild
  const dispatch = useDispatch();
  let rfq = useSelector(state => state.rfq);


  useEffect(() => {
    getAccessTokenSilently().then(token => {
      dispatch(updateToken({ token: token }))
    })
  }, [])

  return (
    <div>
      {rfq.id &&
        <>
          <section className="section section-lg">
            <div className="container">
              <div className="row row-50 justify-content-center justify-content-lg-between">
                <div className="col-md-10 col-lg-6 col-xl-5">
                  <h3 className="wow-outer"><span className="wow slideInDown">Vielen Dank für Ihre Anfrage, wir melden uns bei Ihnen so schnell wie möglich.</span></h3>
                  <div className="wow-outer button-outer">
                    <NavLink to="/rfq-histories">
                    <button type="button" className="button button-icon button-icon-left button-lg button-primary button-winona" 
                      >{t('home.yourRFQhistory')}</button>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      }
      { !rfq.id && rfq.items && rfq.items.length > 0 &&
        <>
          <table className="table table-hover table-job-positions  product-list">
            <thead>
              <tr><th>{t('home.Description')}</th><th>{t('home.Amount')}</th><th>{t('home.Price')}</th><th></th></tr>
            </thead>
            <tbody>
              {rfq.items.map((item) => {
                return (
                  <ItemComponent key={"item_" + item.id} item={item} />
                )
              })}
            </tbody>
          </table>

          <div className="row">
            <div className="col-md-6">
              <div className="group group-middle">
                <NavLink to="/configurator">
                  <button type="submit" className="button button-icon button-icon-left button-lg button-primary button-winona" onClick={(e) => {
                  }}
                  >{t('home.AddNewMotor')}</button>
                </NavLink>
              </div>
            </div>
            <div className="col-md-6">
              <div className="group group-middle">
                <button type="submit" className="button button-icon button-icon-left button-lg button-primary button-winona" onClick={(e) => {
                  getAccessTokenSilently().then(token => {
                    dispatch(saveRfq({ parameters: rfq, token, user }));
                    dispatch(updateToken({ token: token }))
                  })
                }}
                >{t('home.RFQSend')}</button>
              </div>
            </div>
          </div>
        </>
      }
    </div>
  );
}

export default withAuthenticationRequired(RequestForQuotation, {
  onRedirecting: () => <Loading />,
});
