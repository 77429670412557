import { FETCH_CLAIM_BY_ID_SUCCESS, FETCH_CLAIM_LIST_SUCCESS, SET_CURRENT_CLAIM, SET_CURRENT_CLAIM_ITEM, UPDATE_CLAIM_ITEM, FETCH_CLAIM_REASONS_SUCCESS, FETCH_CLAIM_REASON_TREE, FETCH_CLAIM_REASON_TREE_SUCCESS } from "../../action/claim.action";

const initialState = {
  list: [],
  tree: [],
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CLAIM_REASONS_SUCCESS:
      return {
        ...state,
        list: action.payload
      }
    case FETCH_CLAIM_REASON_TREE_SUCCESS:
      return {
        ...state,
        tree: action.payload
      }
    default:
      return state;
  }
}