export const FETCH_USER_ORDERS = "fetch user orders"
export const FETCH_USER_ORDERS_SUCCESS = "fetch user orders success"
export const FETCH_USER_ORDERS_FAILURE = "fetch user orders failure"

export const fetchUserOrders = (data) => ({
  type: FETCH_USER_ORDERS,
  payload: data
});


