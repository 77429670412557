export const FETCH_CUSTOMERS = "fetch customers"
export const FETCH_CUSTOMERS_SUCCESS = "fetch customers success"
export const FETCH_CUSTOMERS_FAILURE = "fetch customers failure"

export const SEARCH_CUSTOMERS = "search customers"
export const SEARCH_CUSTOMERS_SUCCESS = "search customers success"
export const SEARCH_CUSTOMERS_FAILURE = "search customers failure"

export const fetchCustomers = (data) => ({
  type: FETCH_CUSTOMERS,
  payload: data
});

export const searchCustomers = (data) => ({
  type: SEARCH_CUSTOMERS,
  payload: data
});


