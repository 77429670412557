import { apiRequest } from "../action/api.action"
import { SEARCH_PRODUCT_PRICE, SEARCH_PRODUCT_PRICE_FAILURE, SEARCH_PRODUCT_PRICE_SUCCESS } from "../action/product-price.action";
import querystring from 'querystring'
import { getHost } from "../../react/utils/host"
import { ADD_UI_RULE, ADD_UI_RULE_FAILURE, ADD_UI_RULE_SUCCESS, DELETE_UI_RULE, DELETE_UI_RULE_FAILURE, DELETE_UI_RULE_SUCCESS, FETCH_RULES, FETCH_RULES_FAILURE, FETCH_RULES_SUCCESS } from "../action/rules.action";
import { FETCH_COUNTRY_GROUP, FETCH_COUNTRY_GROUP_CATEGORY, FETCH_COUNTRY_GROUP_CATEGORY_FAILURE, FETCH_COUNTRY_GROUP_CATEGORY_SUCCESS, FETCH_COUNTRY_GROUP_FAILURE, FETCH_COUNTRY_GROUP_SUCCESS } from "../action/country.action";

export const countryFlow = ({ dispatch }) => next => action => {
  next(action)
  if (action.type === FETCH_COUNTRY_GROUP ) {
    if (action.token) {
      action.payload["token"] = action.token
    }
    let url = `${getHost()}/api/v1/countries/group`
    dispatch(apiRequest("GET", url, action.payload, FETCH_COUNTRY_GROUP_SUCCESS, FETCH_COUNTRY_GROUP_FAILURE));
  } else if (action.type === FETCH_COUNTRY_GROUP_CATEGORY ) {
    if (action.token) {
      action.payload["token"] = action.token
    }
    let url = `${getHost()}/api/v1/category/find?name=target_country_group`
    dispatch(apiRequest("GET", url, action.payload, FETCH_COUNTRY_GROUP_CATEGORY_SUCCESS, FETCH_COUNTRY_GROUP_CATEGORY_FAILURE));
  }  
};

export const countryMdl = [countryFlow];