import React, { useEffect } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";

import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Profile from "./views/user/Profile";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import ConfiguratorWrapper from "./views/ConfiguratorWrapper";
import Script from "./useScript";
import { TreeView } from "./views/TreeView";
import { RulesView } from "./views/RulesView";
import { CategoriesView } from "./views/CategoriesView";
import RequestForQuotation from "./views/RequestForQuotation.Compnent";
import RfqHistory from "./views/RfqHistory.Component";
import Preloader from "./components/Preloader";

import ClaimSuccess from "./views/ClaimSuccess";
import ClaimsView from "./views/ClaimsView";
import UserMyClaimsView from "./views/user/UserMyClaimsView"
import UserNewClaimView from "./views/user/UserNewClaimView";
import { AdminAufpreisRulesView } from "./views/admin/AdminAufpreisRulesView";
import AdminEditCategoryGroupView from "./views/admin/AdminEditCategoryGroupView";
import ClaimView from "./views/ClaimView";
import ClaimEdit from "./views/ClaimEdit";
import RfqHistoryView from "./views/user/RfqHistoryView.Component";
import { AdminClaimReasonsView } from "./views/admin/AdminClaimReasonsView";
import { ProtectedRoute } from "./ProtectedRoute";
import NotAuthorized from "./views/NotAuthorized";
import NeedVerifyEmail from "./views/NeedVerifyEmail";
import { AdminUsersView } from "./views/admin/AdminUsersView";
import { UserOrdersView } from "./views/user/UserOrdersView";
import AdminRfqsView from "./views/admin/AdminRfqsView";
import AdminRfqDetailView from "./views/admin/AdminRfqDetailView";
import { InternalOrdersView } from "./views/internal/IntenalOrdersView";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";
import { fetchTranslation } from "../redux/action/public/translation.action";
import AdminTranslationsView from "./views/admin/AdminTranslationsView";
import { updateToken } from "../redux/action/auth.action";
import LeadTimes from "./views/LeadTimes";

initFontAwesome();

const App = () => {
  const { isLoading, error, logout } = useAuth0();
  const dispatch = useDispatch()
  const translation = useSelector(state => state.translation);
  
  const { user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    getAccessTokenSilently().then(token => {
      dispatch(updateToken({ token: token }))
      dispatch(fetchTranslation())
    })
  }, [])
  
  useEffect(() => {
    i18next.init({
      interpolation: { escapeValue: false },  // React already does escaping
      fallbackLng: 'de', 
      returnNull: false,    
      resources: {
        en: {
          common: translation.common_en               // 'common' is our custom namespace
        },
        de: {
          common: translation.common_de
        },
      },
    });
  }, [translation])

  if (error && error.message.toLowerCase().indexOf("email") >= 0) {
    logout()
    window.location.replace("/digicat/need-verify-email");
  }

  return (
    <BrowserRouter basename="/digicat" history={history}>
      <div id="app" className="d-flex flex-column h-100">
        <NavBar />
        <div className="container" style={{ minHeight: "80vh" }}>
          <Switch>
            <Route path="/" exact component={ConfiguratorWrapper} />
            <Route path="/configurator" component={ConfiguratorWrapper} />
            <Route path="/leadtimes" component={LeadTimes} />
            <Route path="/rfq" component={RequestForQuotation} />
            <Route path="/rfq-histories" component={RfqHistory} />
            <Route path="/rfq-history/view" component={RfqHistoryView} />
            <Route path="/user/profile" component={Profile} />
            <Route path="/user/orders" component={UserOrdersView} />
            <Route path="/user/claims/new" component={UserNewClaimView} />
            <ProtectedRoute role="internal" path="/claims/all" component={ClaimsView} />
            <ProtectedRoute role="internal" path="/claims/status/:status" component={ClaimsView} exact={true} />
            <ProtectedRoute role="internal" path="/claim/edit" component={ClaimEdit} />
            <ProtectedRoute role="internal" path="/claim/view" component={ClaimView} />
            <ProtectedRoute role="internal" path="/user/my-claims" component={UserMyClaimsView} />
            <ProtectedRoute role="internal" path="/internal/orders" component={InternalOrdersView} />
            <ProtectedRoute role="admin" path="/tree" component={TreeView} />
            <ProtectedRoute role="admin" path="/rules" component={RulesView} />
            <ProtectedRoute role="admin" path="/categories" component={CategoriesView} />
            <ProtectedRoute role="admin" path="/admin/aufpreis-rules" component={AdminAufpreisRulesView} />
            <ProtectedRoute role="admin" path="/admin/category-groups" component={AdminEditCategoryGroupView} />
            <ProtectedRoute role="admin" path="/admin/reasons" component={AdminClaimReasonsView} />
            <ProtectedRoute role="admin" path="/admin/users" component={AdminUsersView} />
            <ProtectedRoute role="admin" path="/admin/rfqs" component={AdminRfqsView} exact />
            <ProtectedRoute role="admin" path="/admin/rfqs/:id" component={AdminRfqDetailView} exact={true} />
            <ProtectedRoute role="admin" path="/admin/tranlations" component={AdminTranslationsView} />
            <Route path="/not-authorized" component={NotAuthorized} />
            <Route path="/need-verify-email" component={NeedVerifyEmail} />
            <Route path="/claim-success" component={ClaimSuccess} />
          </Switch>
        </div>
        <Footer />
        <Script src='/digicat/js/core.min.js' async={false}></Script>
        <Script src='/digicat/js/script.js' async={false}></Script>
        <Script src="https://cdn.tiny.cloud/1/sbbo0lncp3hqx05t5laew9cextewe5srg44vubvkc10i6fxa/tinymce/6/tinymce.min.js" referrerpolicy="origin"></Script>
      </div>
      <Preloader />
    </BrowserRouter>
  );
};

export default App;
