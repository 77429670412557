import { apiRequest } from "../../action/api.action"
import { getHost } from "../../../react/utils/host"
import { FETCH_TRANSLATION, FETCH_TRANSLATIONS_LIST, FETCH_TRANSLATIONS_LIST_FAILURE, FETCH_TRANSLATIONS_LIST_SUCCESS, FETCH_TRANSLATION_FAILURE, FETCH_TRANSLATION_SUCCESS, UPDATE_TRANSLATION, UPDATE_TRANSLATION_FAILURE, UPDATE_TRANSLATION_SUCCESS } from "../../action/public/translation.action";

export const translationFlow = ({ dispatch }) => next => action => {
  next(action)
  if (action.type === FETCH_TRANSLATION) {
    let url = `${getHost()}/public-api/v1/translation`
    dispatch(apiRequest("GET", url, null, FETCH_TRANSLATION_SUCCESS, FETCH_TRANSLATION_FAILURE));
  }

  if (action.type === FETCH_TRANSLATIONS_LIST || action.type === UPDATE_TRANSLATION_SUCCESS) {
    let url = `${getHost()}/admin-api/v1/translations`
    dispatch(apiRequest("GET", url, null, FETCH_TRANSLATIONS_LIST_SUCCESS, FETCH_TRANSLATIONS_LIST_FAILURE));
  }

  if (action.type === UPDATE_TRANSLATION) {
    let url = `${getHost()}/admin-api/v1/translations`
    dispatch(apiRequest("POST", url, action.payload, UPDATE_TRANSLATION_SUCCESS, UPDATE_TRANSLATION_FAILURE));
  }

};

export const translationMdl = [translationFlow];