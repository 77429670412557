import { POST_CLAIM_SUCCESS } from "../action/claim.action";
import { ADD_TO_BLOCK_ARRAY, CLEAN_REDIRECT, REMOVE_FROM_BLOCK_ARRAY, UPDATE_UI } from "../action/ui.action";
import { POST_USER_CLAIM_SUCCESS } from "../action/user/claim.action";

const initialState = {
  redirect: "",
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case POST_CLAIM_SUCCESS:
      return {
        ...state,
        redirect: "/claim-success"
      }
      case POST_USER_CLAIM_SUCCESS:
        return {
          ...state,
          redirect: "/claim-success"
        }
    case CLEAN_REDIRECT:
      return {
        ...state,
        redirect: ""
      }
    default:
      return state;
  }
}