import React, { Fragment, useEffect } from "react";
import { Container } from "reactstrap";

import Loading from "../../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { updateToken } from "../../../redux/action/auth.action";
import { fetchUserOrders } from "../../../redux/action/user/user-orders.action";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import {useTranslation} from "react-i18next";

export const UserOrdersView = () => {
  const { t, i18n } = useTranslation('common');//国际化
  const dispatch = useDispatch()
  const { user, getAccessTokenSilently } = useAuth0();
  const orders = useSelector(state => state.orders.list);

  const { SearchBar } = Search;

  useEffect(() => {
    getAccessTokenSilently().then(token => {
      dispatch(updateToken({ token: token }))
      dispatch(fetchUserOrders())
    })
  }, [])

  const columns = [{
    dataField: 'prodOrder',
    text: 'Prod. Order',
    sort: true,
  }, {
    dataField: 'materialnummer',
    text: 'Materialnummer',
  }, {
    dataField: 'material',
    text: 'Material',
  }, {
    dataField: 'released',
    text: 'released',
  }];

  const options = {
    custom: true,
    totalSize: orders.length
  };

  return (
    <Fragment>
      {orders && orders.length > 0 &&
        <section className="section section-lg text-center">
          <div className="container">
            <PaginationProvider
              pagination={paginationFactory(options)}
            >
              {
                ({
                  paginationProps,
                  paginationTableProps,
                }) => (
                  <div>
                    <PaginationListStandalone
                      {...paginationProps}
                    />
                    <ToolkitProvider
                      bootstrap4
                      keyField="id"
                      data={orders}
                      columns={columns}
                      search
                    >
                      {
                        toolkitprops => (
                          <div>
                            <div style={{ float: "left", padding: "10px 0px" }}>
                              <SearchBar {...toolkitprops.searchProps} style={{ float: "left" }} placeholder="Search Category Name / ID" />
                            </div>
                            <BootstrapTable
                              striped
                              hover
                              {...toolkitprops.baseProps}
                              {...paginationTableProps}
                            />
                          </div>
                        )
                      }
                    </ToolkitProvider>
                    <PaginationListStandalone
                      {...paginationProps}
                    />
                  </div>

                )
              }
            </PaginationProvider>
          </div>
        </section>
      }

    </Fragment>
  );
};

export default withAuthenticationRequired(UserOrdersView, {
  onRedirecting: () => <Loading />,
});
