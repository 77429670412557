export const FETCH_ALL_RFQS = "fetch all rfqs"
export const FETCH_ALL_RFQS_SUCCESS = "fetch all rfqs success"
export const FETCH_ALL_RFQS_FAILURE = "fetch all rfqs failure"


export const fetchAllRfqs = (data) => ({
  type: FETCH_ALL_RFQS,
  payload: data
});




