import React, { useEffect, useState } from "react";

import Loading from "../../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { Fragment } from "react";
import { Button, Collapse, Modal } from 'react-bootstrap';
import { fetchCategoyGroupList, fetchCategoyList } from "../../../redux/action/category.action";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import { updateToken } from "../../../redux/action/auth.action";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import EditCategoryGroup from "../../components/admin/EditCategoryGroup.Component";
import { fetchTranslationsList } from "../../../redux/action/public/translation.action";
import EditTranslation from "../../components/EditTranslation.Component";
import {useTranslation} from "react-i18next";

export const AdminTranslationsView = () => {
  const { t, i18n } = useTranslation('common');//国际化
  const dispatch = useDispatch()
  const { user, getAccessTokenSilently } = useAuth0();
  const translations = useSelector(state => state.translation.list);
  const [show, setShow] = useState(false);
  const [currentTranslation, setCurrentTranslation] = useState({});

  useEffect(() => {
    getAccessTokenSilently().then(token => {
      dispatch(updateToken({ token: token }))
      dispatch(fetchTranslationsList())
    })
  }, [])

  const { SearchBar } = Search;

  function actionFormatter(cell, row) {
    return (
      <div className="group group-middle">
        <button type="submit" className="button button-sm button-primary-outline button-winona" onClick={(e) => {
          e.preventDefault();
          setCurrentTranslation(row)
          setShow(true)
        }}
        >{t('admin.Edit')}</button>
      </div>
    );
  }

  function conditionsFormatter(cell, row) {
    return (
      <Fragment key={"rule_" + row.id}>
        {row.categories &&
          row.categories.map(function (elem) {
            return elem.name;
          }).join("; ")
        }
      </Fragment>
    );
  }

  const columns = [{
    dataField: 'id',
    text: 'ID',
    sort: true,
  }, {
    dataField: 'key',
    text: 'Key',
    sort: true,
  }, 
  {
    dataField: 'de',
    text: 'Deutsch',
  }, 
  {
    dataField: 'en',
    text: 'English',
  },
  {
    dataField: 'user.email',
    text: 'User',
    sort: true,
  },
  {
    dataField: 'action',
    text: 'Action',
    formatter: actionFormatter,
    headerClasses: 'width-procent-20'
  }];


  const options = {
    custom: true,
    totalSize: translations.length
  };

  return (
    <>
      <Modal show={show} size="xl" dialogClassName="modal-details">
        <Modal.Header>
          <Modal.Title>{t('admin.EditTranslation')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditTranslation translation={currentTranslation} setShow ={setShow}/>
        </Modal.Body>
        <Modal.Footer>
          <Button className="button button-icon button-icon-left button-winona" onClick={(e) => {
            setShow(false)
          }}>
            {t('admin.Close')}
          </Button>
        </Modal.Footer>
      </Modal>

      <section className="section section-lg text-center">
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <div className="group group-middle">
                <button type="button" className="button" onClick={(e) => {
                  e.preventDefault();
                  setCurrentTranslation({})
                  setShow(true)
                }}
                >{t('admin.Addnewtranslation')}</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {translations &&
        <section className="section section-lg text-center">
          <div className="container">
            <PaginationProvider
              pagination={paginationFactory(options)}
            >
              {
                ({
                  paginationProps,
                  paginationTableProps,
                }) => (
                  <div>
                    <PaginationListStandalone
                      {...paginationProps}
                    />
                    <ToolkitProvider
                      bootstrap4
                      keyField="id"
                      data={translations}
                      columns={columns}
                      search
                    >
                      {
                        toolkitprops => (
                          <div>
                            <div style={{ float: "left", padding: "10px 0px" }}>
                              <SearchBar {...toolkitprops.searchProps} style={{ float: "left" }} placeholder="Search Category Name / ID" />
                            </div>
                            <BootstrapTable
                              striped
                              hover
                              {...toolkitprops.baseProps}
                              {...paginationTableProps}
                            />
                          </div>
                        )
                      }
                    </ToolkitProvider>
                    <PaginationListStandalone
                      {...paginationProps}
                    />
                  </div>

                )
              }
            </PaginationProvider>
          </div>
        </section>
      }

    </>
  );
};

export default withAuthenticationRequired(AdminTranslationsView, {
  onRedirecting: () => <Loading />,
});
