export const  FETCH_CATEGORY_TREE = "fetch category tree"
export const  FETCH_CATEGORY_TREE_SUCCESS = "fetch category tree success"
export const  FETCH_CATEGORY_TREE_FAILURE = "fetch category tree failure"

export const  FETCH_CATEGORY_TREE_BY_STEP = "fetch category tree by step"
export const  FETCH_CATEGORY_TREE_BY_STEP_SUCCESS = "fetch category tree by step success"
export const  FETCH_CATEGORY_TREE_BY_STEP_FAILURE = "fetch category tree by step failure"

export const  FETCH_CHILD_CATEGORIES = "fetch child categories"
export const  FETCH_CHILD_CATEGORIES_SUCCESS = "fetch child categories success"
export const  FETCH_CHILD_CATEGORIES_FAILURE = "fetch child categories failure"

export const  FETCH_FULL_CHILD_CATEGORIES = "fetch full child categories"
export const  FETCH_FULL_CHILD_CATEGORIES_SUCCESS = "fetch full child categories success"
export const  FETCH_FULL_CHILD_CATEGORIES_FAILURE = "fetch full child categories failure"

export const  FETCH_FULL_CHILD_CATEGORIES_FOR_MERKMAL = "fetch full child categories for merkmal"
export const  FETCH_FULL_CHILD_CATEGORIES_FOR_MERKMAL_SUCCESS = "fetch full child categories for merkmal success"
export const  FETCH_FULL_CHILD_CATEGORIES_FOR_MERKMAL_FAILURE = "fetch full child categories for merkmal failure"

export const  FETCH_CATEGOY_LIST = "fetch category list"
export const  FETCH_CATEGOY_LIST_SUCCESS = "fetch category list success"
export const  FETCH_CATEGOY_LIST_FAILURE = "fetch category list failure"

export const  FETCH_CATEGOY_GROUP_LIST = "fetch category group list"
export const  FETCH_CATEGOY_GROUP_LIST_SUCCESS = "fetch category group list success"
export const  FETCH_CATEGOY_GROUP_LIST_FAILURE = "fetch category group list failure"

export const  DELETE_CATEGORY = "delete category"
export const  DELETE_CATEGORY_SUCCESS = "delete category success"
export const  DELETE_CATEGORY_FAILURE = "delete category failure"

export const  UPDATE_CATEGORY = "update category"
export const  UPDATE_CATEGORY_SUCCESS = "update category success"
export const  UPDATE_CATEGORY_FAILURE = "update category failure"

export const  DELETE_PROPERTY = "delete property"
export const  DELETE_PROPERTY_SUCCESS = "delete property success"
export const  DELETE_PROPERTY_FAILURE = "delete property failure"

export const  ADD_PROPERTY = "add property"
export const  ADD_PROPERTY_SUCCESS = "add property success"
export const  ADD_PROPERTY_FAILURE = "add property failure"

export const  FETCH_CURRENT_EDIT_CATEGORY = "fetch current edit category"
export const  FETCH_CURRENT_EDIT_CATEGORY_SUCCESS = "fetch current edit category success"
export const  FETCH_CURRENT_EDIT_CATEGORY_FAILURE = "fetch current edit category failure"

export const  RESET_CURRENT_EDIT_CATEGORY = "reset current edit category"

export const  FETCH_PARENT_CATEGORY_CANDIDATES = "fetch parent category candidates"
export const  FETCH_PARENT_CATEGORY_CANDIDATES_SUCCESS = "fetch parent category candidates success"
export const  FETCH_PARENT_CATEGORY_CANDIDATES_FAILURE = "fetch parent category candidates failure"

export const  FETCH_CHILD_CATEGORY_TREE = "fetch child category tree"
export const  FETCH_CHILD_CATEGORY_TREE_SUCCESS = "fetch child category tree success"
export const  FETCH_CHILD_CATEGORY_TREE_FAILURE = "fetch child category tree failure"

export const  ADD_TREE_NODE = "add tree node"
export const  ADD_TREE_NODE_SUCCESS = "add tree node success"
export const  ADD_TREE_NODE_FAILURE = "add tree node failure"

export const  DELETE_CATEGORY_TREE_NODE = "delete tree node"
export const  DELETE_CATEGORY_TREE_NODE_SUCCESS = "delete tree node success"
export const  DELETE_CATEGORY_TREE_NODE_FAILURE = "delete tree node failure"

export const  EXCHANGE_CATEGORY_TREE_NODE_ORDER = "exchange tree node order"
export const  EXCHANGE_CATEGORY_TREE_NODE_ORDER_SUCCESS = "exchange tree node order success"
export const  EXCHANGE_CATEGORY_TREE_NODE_ORDER_FAILURE = "exchange tree node order failure"

export const  EXCHANGE_CATEGORY_PROPERTY_ORDER = "exchange category property order"
export const  EXCHANGE_CATEGORY_PROPERTY_ORDER_SUCCESS = "exchange category property order success"
export const  EXCHANGE_CATEGORY_PROPERTY_ORDER_FAILURE = "exchange category property order failure"

export const  ADD_TO_CATEGORY_GROUP = "add to category group"
export const  ADD_TO_CATEGORY_GROUP_SUCCESS = "add to category group success"
export const  ADD_TO_CATEGORY_GROUP_FAILURE = "add to category group failure"

export const  DELETE_TREE_NODE_BY_ID = "delete tree node by id"
export const  DELETE_TREE_NODE_BY_ID_SUCCESS = "delete tree node by id success"
export const  DELETE_TREE_NODE_BY_ID_FAILURE = "delete tree node by id failure"


export const  FETCH_CURRENT_EDIT_PROPERTY = "fetch current edit property"
export const  FETCH_CURRENT_EDIT_PROPERTY_SUCCESS = "fetch current edit property success"
export const  FETCH_CURRENT_EDIT_PROPERTY_FAILURE = "fetch current edit property failure"

export const  RESET_CURRENT_EDIT_PROPERTY = "reset current edit property"

export const  UPDATE_PROPERTY = "update property"
export const  UPDATE_PROPERTY_SUCCESS = "update property success"
export const  UPDATE_PROPERTY_FAILURE = "update property failure"

export const  FETCH_CURRENT_EDIT_CATEGORY_GROUP = "fetch current edit category group"
export const  FETCH_CURRENT_EDIT_CATEGORY_GROUP_SUCCESS = "fetch current edit category group success"
export const  FETCH_CURRENT_EDIT_CATEGORY_GROUP_FAILURE = "fetch current edit category group failure"

export const  RESET_CURRENT_EDIT_CATEGORY_GROUP = "reset current edit category group"

export const  UPDATE_CATEGORY_GROUP = "update category group"
export const  UPDATE_CATEGORY_GROUP_SUCCESS = "update category group success"
export const  UPDATE_CATEGORY_GROUP_FAILURE = "update category group failure"

export const  FETCH_SAP_MERKMAL_LIST = "fetch sap merkmal"
export const  FETCH_SAP_MERKMAL_LIST_SUCCESS = "fetch sap merkmal success"
export const  FETCH_SAP_MERKMAL_LIST_FAILURE = "fetch sap merkmal failure"

export const fetchCategoryTree = (data) => ({
  type: FETCH_CATEGORY_TREE,
  payload: data
});

export const fetchCategoryTreeByStep = (data) => ({
  type: FETCH_CATEGORY_TREE_BY_STEP,
  payload: data
});

export const fetchChildCategories= (data) => ({
  type: FETCH_CHILD_CATEGORIES,
  payload: data
});

export const fetchFullChildCategories= (data) => ({
  type: FETCH_FULL_CHILD_CATEGORIES,
  payload: data
});

export const fetchFullChildCategoriesForMerkmal= (data) => ({
  type: FETCH_FULL_CHILD_CATEGORIES_FOR_MERKMAL,
  payload: data
});

export const fetchCategoyList= (data) => ({
  type: FETCH_CATEGOY_LIST,
  payload: data
});

export const fetchCategoyGroupList= (data) => ({
  type: FETCH_CATEGOY_GROUP_LIST,
  payload: data
});

export const deleteCategory= (data) => ({
  type: DELETE_CATEGORY,
  payload: data
});

export const deleteProperty= (data) => ({
  type: DELETE_PROPERTY,
  payload: data
});

export const updateCategory= (data) => ({
  type: UPDATE_CATEGORY,
  payload: data
});

export const addProperty= (data) => ({
  type: ADD_PROPERTY,
  payload: data
});

export const fetchCurrentEditCategory= (data) => ({
  type: FETCH_CURRENT_EDIT_CATEGORY,
  payload: data
});

export const resetCurrentEditCategory= (data) => ({
  type: RESET_CURRENT_EDIT_CATEGORY,
  payload: data
});

export const fetchParentCategoryCandidates= (data) => ({
  type: FETCH_PARENT_CATEGORY_CANDIDATES,
  payload: data
});

export const fetchChildCategoryTree= (data) => ({
  type: FETCH_CHILD_CATEGORY_TREE,
  payload: data
});

export const addTreeNode= (data) => ({
  type: ADD_TREE_NODE,
  payload: data
});

export const deleteCategoryTreeNode= (data) => ({
  type: DELETE_CATEGORY_TREE_NODE,
  payload: data
});

export const exchangeCategoryTreeNodeOrder= (data) => ({
  type: EXCHANGE_CATEGORY_TREE_NODE_ORDER,
  payload: data
});

export const exchangeCategoryPropertyOrder= (data) => ({
  type: EXCHANGE_CATEGORY_PROPERTY_ORDER,
  payload: data
});

export const addToCategoryGroup= (data) => ({
  type: ADD_TO_CATEGORY_GROUP,
  payload: data
});

export const deleteTreeNodeById= (data) => ({
  type: DELETE_TREE_NODE_BY_ID,
  payload: data
});

export const fetchCurrentEditProperty= (data) => ({
  type: FETCH_CURRENT_EDIT_PROPERTY,
  payload: data
});

export const resetCurrentEditProperty= (data) => ({
  type: RESET_CURRENT_EDIT_PROPERTY,
  payload: data
});

export const updateProperty= (data) => ({
  type: UPDATE_PROPERTY,
  payload: data
});

export const fetchCurrentEditCategoryGroup= (data) => ({
  type: FETCH_CURRENT_EDIT_CATEGORY_GROUP,
  payload: data
});

export const resetCurrentEditCategoryGroup= (data) => ({
  type: RESET_CURRENT_EDIT_CATEGORY_GROUP,
  payload: data
});

export const updateCategoryGroup= (data) => ({
  type: UPDATE_CATEGORY_GROUP,
  payload: data
});

export const fetchSapMerkmalList= (data) => ({
  type: FETCH_SAP_MERKMAL_LIST,
  payload: data
});
