export const FETCH_TRANSLATION = "fetch translation"
export const FETCH_TRANSLATION_SUCCESS = "fetch translation success"
export const FETCH_TRANSLATION_FAILURE = "fetch translation failure"

export const FETCH_TRANSLATIONS_LIST = "fetch translations list"
export const FETCH_TRANSLATIONS_LIST_SUCCESS = "fetch translations list success"
export const FETCH_TRANSLATIONS_LIST_FAILURE = "fetch translations list failure"

export const UPDATE_TRANSLATION = "update translation"
export const UPDATE_TRANSLATION_SUCCESS = "update translation success"
export const UPDATE_TRANSLATION_FAILURE = "update translation failure"

export const fetchTranslation = (data) => ({
  type: FETCH_TRANSLATION,
  payload: data
});

export const fetchTranslationsList = (data) => ({
  type: FETCH_TRANSLATIONS_LIST,
  payload: data
});

export const updateTranslation = (data) => ({
  type: UPDATE_TRANSLATION,
  payload: data
});




