import { apiRequest } from "../../action/api.action"
import { getHost } from "../../../react/utils/host"
import { ADD_USER_CLAIM_ITEM_BY_ID, ADD_USER_CLAIM_ITEM_BY_ID_FAILURE, ADD_USER_CLAIM_ITEM_BY_ID_SUCCESS, DELETE_USER_CLAIM_ITEM_BY_ID, DELETE_USER_CLAIM_ITEM_BY_ID_FAILURE, DELETE_USER_CLAIM_ITEM_BY_ID_SUCCESS, FETCH_USER_CLAIM_BY_ID, FETCH_USER_CLAIM_BY_ID_FAILURE, FETCH_USER_CLAIM_BY_ID_SUCCESS, FETCH_USER_CLAIM_LIST, FETCH_USER_CLAIM_LIST_FAILURE, FETCH_USER_CLAIM_LIST_SUCCESS, POST_USER_CLAIM, POST_USER_CLAIM_FAILURE, POST_USER_CLAIM_SUCCESS, UPDATE_USER_CLAIM_ITEM_BY_ID } from "../../action/user/claim.action";
import { success } from "react-toastify-redux";

export const userClaimFlow = ({ dispatch }) => next => action => {
  next(action)

  if (action.type === POST_USER_CLAIM) {
    let url = `${getHost()}/user-api/v1/claims/new`
    dispatch(apiRequest("POST", url, action.payload, POST_USER_CLAIM_SUCCESS, POST_USER_CLAIM_FAILURE));
  }

  if (action.type === FETCH_USER_CLAIM_LIST) {
    let url = `${getHost()}/user-api/v1/claims`
    dispatch(apiRequest("GET", url, null, FETCH_USER_CLAIM_LIST_SUCCESS, FETCH_USER_CLAIM_LIST_FAILURE));
  }

  if (action.type === POST_USER_CLAIM_SUCCESS) {
    dispatch(success("Claim  create successful"));
  }

  if (action.type === FETCH_USER_CLAIM_BY_ID || action.type === POST_USER_CLAIM_SUCCESS || action.type === DELETE_USER_CLAIM_ITEM_BY_ID_SUCCESS || action.type === ADD_USER_CLAIM_ITEM_BY_ID_SUCCESS) {
    let url = `${getHost()}/user-api/v1/claims/${action.payload.id}`
    dispatch(apiRequest("GET", url, null, FETCH_USER_CLAIM_BY_ID_SUCCESS, FETCH_USER_CLAIM_BY_ID_FAILURE));
  }

  if (action.type === DELETE_USER_CLAIM_ITEM_BY_ID) {
    let url = `${getHost()}/user-api/v1/claims/${action.payload.id}/item/${action.payload.claimItemId}`
    dispatch(apiRequest("DELETE", url, null, DELETE_USER_CLAIM_ITEM_BY_ID_SUCCESS, DELETE_USER_CLAIM_ITEM_BY_ID_FAILURE));
  }

  if (action.type === ADD_USER_CLAIM_ITEM_BY_ID) {
    let url = `${getHost()}/user-api/v1/claims/${action.payload.id}/item`
    dispatch(apiRequest("POST", url, {parameters: action.payload.item}, ADD_USER_CLAIM_ITEM_BY_ID_SUCCESS, ADD_USER_CLAIM_ITEM_BY_ID_FAILURE));
  }

  if (action.type === UPDATE_USER_CLAIM_ITEM_BY_ID) {
    let url = `${getHost()}/user-api/v1/claims/${action.payload.id}/item`
    dispatch(apiRequest("POST", url, {parameters: action.payload.item}, ADD_USER_CLAIM_ITEM_BY_ID_SUCCESS, ADD_USER_CLAIM_ITEM_BY_ID_FAILURE));
  }
};

export const userClaimMdl = [userClaimFlow];