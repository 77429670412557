import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Redirect } from "react-router";
import { useHistory } from "react-router-dom";

const roleClaimType = "https://atb.51jobs.de/roles";

export const AuthorizeUser = ({ component: component, role }) => {
  const { getIdTokenClaims } = useAuth0();
  const { user, getAccessTokenSilently } = useAuth0();
  let history = useHistory();

  useEffect(() => {
    getAccessTokenSilently().then(token => {
      if (user && !user[roleClaimType].includes(role)) {
        history.push("/not-authorized");
      }
    })
  }, [user])

  return component 

}