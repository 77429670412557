import React from "react";

const Footer = () => (
  <footer className="section footer-advanced bg-gray-700">
    <div className="footer-advanced-aside">
      <div className="container">
        <div className="footer-advanced-layout"><a className="brand" href="index.html"><img style={{backgroundColor:"white"}} src="/digicat/images/logo.png" alt="" width="171" height="39" /></a>
          <p className="rights"><span>&copy;&nbsp;</span><span className="copyright-year"></span><span>&nbsp;</span><span>All Rights Reserved.</span><span>&nbsp;</span><br className="d-sm-none" /><a href="#">Terms of Use</a><span> and</span><span>&nbsp;</span><a href="privacy-policy.html">Privacy Policy</a></p>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
