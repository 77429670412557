import React, { useEffect, useState } from "react";


const Preloader = () => {

  useEffect(() => {
  }, [])

  const [preloader, setPreloader] = useState('preloader')

  useEffect(() => {
    setTimeout(() => {
      setPreloader("preloader loaded")
    }, 1000);
  }, [])

  return (
    <div className={preloader}>
      <div className="preloader-logo"><img src="/digicat/images/logo.png" alt="" width="171" height="39" />
      </div>
      <div className="preloader-body">
        <div id="loadingProgressG">
          <div className="loadingProgressG" id="loadingProgressG_1"></div>
        </div>
      </div>
    </div>
  )
}

export default Preloader;