import { apiMdl } from "./api.middleware"
import { categoryMdl } from "./category.middleware"
import { countryMdl } from "./country.middleware"
import { productPriceMdl } from "./product-price.middleware"
import { rfqMdl } from './rfq.middleware'
import { rulesMdl } from "./rules.middleware"
import { claimMdl } from "./claim.middleware"
import { userClaimMdl } from "./user/claim.middleware"
import { priceRuleMdl } from "./user/price-rule.middleware"
import { adminAufpreisRuleMdl } from "./admin/admin-aufpreic-rule.middleware"
import { webContentsMdl } from "./public/web-contents.middleware"
import { userMdl } from "./user/user.middleware"
import { suggestionsMdl } from "./internal/suggestions.middleware"
import { adminUsersMdl } from "./admin/admin-users.middleware"
import { adminCustomersMdl } from "./admin/admin-customers.middleware"
import { userOrdersMdl } from "./user/user-orders.middleware"
import { internalOrdersMdl } from "./internal/internal-orders.middleware"
import { translationMdl } from "./public/translation.middleware"

export const middlewares = [
  ...apiMdl,
  ...categoryMdl,
  ...productPriceMdl,
  ...rfqMdl,
  ...rulesMdl,
  ...countryMdl,
  ...claimMdl,
  ...userClaimMdl,
  ...priceRuleMdl,
  ...adminAufpreisRuleMdl,
  ...webContentsMdl,
  ...userMdl,
  ...suggestionsMdl,
  ...adminUsersMdl,
  ...adminCustomersMdl,
  ...userOrdersMdl,
  ...internalOrdersMdl,
  ...translationMdl
]