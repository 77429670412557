import React, { useEffect, useState, useRef } from 'react';
import { Button, Collapse, Modal, Table } from 'react-bootstrap';
import _ from 'lodash'
import { ErrorMessage, Field, Formik, useFormikContext } from 'formik';
import * as Icon from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { deleteClaimItemAttachmentById, setCurrentClaim, updateClaimItem, updateClaimItemById } from '../../../redux/action/claim.action';
import Dropzone from 'react-dropzone-uploader'
import { v4 as uuidv4 } from 'uuid';
import { getHost } from "../../utils/host";
import "../../styles/dropzone_style.css"
import { AuthenticatedLink } from '../AuthenticatedLink';

const ViewClaimItem = (props) => {
  const dispatch = useDispatch()
  let currentClaimItem = props.item
  let setCurrentClaimItem = props.setItem
  let currentClaim = props.claim
  const token = useSelector(state => state.auth.token);
  const reasonTree = useSelector(state => state.reasons.tree);

  return (
    <>
      <Formik
        initialValues={currentClaimItem}
        enableReinitialize={true}
        validate={values => {
          const errors = {};
          return errors;
        }}
        onSubmit={(values) => {
          setCurrentClaimItem(values)
          if (props.index) {
            currentClaim.items.splice(props.index, 1, values)
          } else {
            currentClaim.items.push(values)
          }

          dispatch(setCurrentClaim(currentClaim))
          if (currentClaim.id) {
            dispatch(updateClaimItemById({ id: currentClaim.id, item: values }))
          }
        }}>
        {({ values, errors, handleSubmit, setFieldValue, setValues }) => (
          <form onSubmit={handleSubmit} autoComplete="off" className="rd-form rd-mailform">
            <div className="row row-10">
              {currentClaimItem.id &&
                <>
                  <div className="col-md-6 ">
                    <div className="form-wrap wow fadeSlideInUp">
                      <label className="form-label-outside" htmlFor="ID">ID</label>
                      <Field disabled disabled id="ID" type="text" name="ID" placeholder="ID" className="form-input" value={values.id || ''} />
                    </div>
                  </div>
                  <div className="col-md-6 "></div>
                </>
              }
              <div className="col-md-6 ">
                <div className="form-wrap wow fadeSlideInUp">
                  <label className="form-label-outside" htmlFor="Artike Nr">Artike Nr</label>
                  <Field disabled id="Artike Nr" type="text" name="Artike Nr" placeholder="Artike Nr" className="form-input" value={values.artikeNr || ''}
                    onChange={(event) => {
                      setFieldValue("artikeNr", event.currentTarget.value);
                    }} />
                </div>
              </div>
              <div className="col-md-6 ">
                <div className="form-wrap wow fadeSlideInUp">
                  <label className="form-label-outside" htmlFor="menge">Menge</label>
                  <Field disabled id="menge" type="text" name="menge" placeholder="Menge" className="form-input" value={values.menge || ''}
                    onChange={(event) => {
                      setFieldValue("menge", event.currentTarget.value);
                    }} />
                </div>
              </div>
              <div className="col-md-12">

                <div className="form-wrap ">
                  <label className="form-label-outside" htmlFor="Reasons">Reasons <ErrorMessage name="reasons" render={msg => <span className="error">(*{msg})</span>} /> </label>
                  {reasonTree &&
                    reasonTree.map((r, index) => {
                      return (
                        <div key={"reason_r_" + index}>
                          {r.children.filter(c => values.reasonIds.includes(c.id.toString())).length > 0 &&
                            <>
                              <b>{r.name}</b>:
                              {r.children.filter(c => values.reasonIds.includes(c.id.toString())).map((c, index) => {
                                return (
                                  <label key={"cp_" + c.id} className='pl10'>
                                    {c.name}
                                  </label>
                                )
                              })
                              }
                            </>
                          }
                        </div>
                      )
                    })
                  }
                </div>

              </div>

              <div className="col-md-12 ">
                <div className="form-wrap wow fadeSlideInUp">
                  <label className="form-label-outside" htmlFor="description">Description</label>
                  <Field disabled id="description" as="textarea" type="text" name="description" placeholder="Description" className="form-input" value={values.description || ''}
                    onChange={(event) => {
                      setFieldValue("description", event.currentTarget.value);
                    }} />
                </div>
              </div>

              <div className="col-md-6">
                {values.images && values.images.length > 0 &&
                  <table className="table table-default">
                    <thead></thead>
                    <tbody>
                      {
                        values.images.map((attachment, index) => {
                          return (
                            <tr key={"attachment_" + attachment["uuid"]}>
                              <td>{attachment["file-name"]}</td>
                              <td>
                                <AuthenticatedLink url={`/internal-api/v1/claims/download`} filepath={attachment["file-path"]} filename={attachment["file-name"]}>
                                  <i className="icon icon-sm link-default mdi mdi-eye"></i>
                                </AuthenticatedLink>
                                <AuthenticatedLink url={`/internal-api/v1/claims/download`} filepath={attachment["file-path"]} filename={attachment["file-name"]} downloadLink={true}>
                                  <i className="icon icon-sm link-default mdi mdi-download"></i>
                                </AuthenticatedLink>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                }
              </div>

              <div className="col-md-6">
                {values.attachments && values.attachments.length > 0 &&
                  <table className="table table-default">
                    <thead></thead>
                    <tbody>
                      {
                        values.attachments.map((attachment, index) => {
                          return (
                            <tr key={"attachment_" + attachment["uuid"]}>
                              <td>{attachment["file-name"]}</td>
                              <td>
                                <AuthenticatedLink url={`/internal-api/v1/claims/download`} filepath={attachment["file-path"]} filename={attachment["file-name"]}>
                                  <i className="icon icon-sm link-default mdi mdi-eye"></i>
                                </AuthenticatedLink>
                                <AuthenticatedLink url={`/internal-api/v1/claims/download`} filepath={attachment["file-path"]} filename={attachment["file-name"]} downloadLink={true}>
                                  <i className="icon icon-sm link-default mdi mdi-download"></i>
                                </AuthenticatedLink>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                }
              </div>

            </div>
            <div className="row row-10">
              <div className="col-md-6 ">
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}

export default ViewClaimItem;
