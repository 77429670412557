import { useAuth0 } from '@auth0/auth0-react';
import React, { createRef, useState } from "react";
import { getHost } from '../utils/host';


export function AuthenticatedLink({ url, filename, children, filepath, downloadLink }) {
  const link = createRef()
  const { user, getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState('')

  getAccessTokenSilently().then(token => {
    setToken(token)
  })

  const handleAction = async () => {
    if (link.current.href) { return }

    const result = await fetch(getHost() + url + "?filepath=" + filepath + "&filename=" + filename, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    const blob = await result.blob()
    const href = window.URL.createObjectURL(blob)
    if (downloadLink) {
      link.current.download = filename
    }
    link.current.href = href

    link.current.click()
  }

  return (
    <>
      <a role='button' target='_blank' ref={link} onClick={handleAction} style={{ cursor: "pointer" }}>{children}</a>
    </>
  )
}