import React, { useEffect, useState } from "react";
import { Container, Row, Col, Accordion, Card, Button } from "react-bootstrap";
import _ from 'lodash'
import Highlight from "../components/Highlight";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { fetchCategoryTree } from "../../redux/action/category.action";
import { useDispatch, useSelector } from "react-redux";
import TreeComponent from "../components/Tree.Compnent";
import { ErrorMessage, Field, Formik, useFormikContext } from 'formik';
import { updateUi } from "../../redux/action/ui.action";
import { setCurrentStep } from "../../redux/action/step.action";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const ConfiguratorSteps = () => {
  const dispatch = useDispatch();
  const { user, getAccessTokenSilently } = useAuth0();
  let stepsArray = useSelector(state => state.steps.stepsArray);
  let currentStep = useSelector(state => state.steps.currentStep);
  const { setFieldValue } = useFormikContext();
  const { t, i18n } = useTranslation('common');

  return (
    <>
      <section className="breadcrumbs-custom context-dark" style={{minHeight:"unset"}}>
        <div>
          <div className="container breadcrumbs-custom-container">
            <ul className="breadcrumbs-custom-path">
              { stepsArray && 
                stepsArray.map((s, index) => {
                  return (
                    <li key={"step_" + index}>
                      { s.id === currentStep.id &&
                        <>{_.upperCase(s.name)==='PRE SELECTION'? 'MERKMALEN': t(`category.name.${_.snakeCase(s.name)}`) }</>
                      }
                      { s.id !== currentStep.id &&
                        <a href="/configurator" onClick={(e)=>{
                          e.preventDefault();
                          dispatch(setCurrentStep({category: s}))
                          setFieldValue("quantity", 1)
                          setFieldValue("changedCategory", {})
                        }}>{t(`category.name.${_.snakeCase(s.name)}`)}</a>
                      }
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

