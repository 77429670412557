import React, { useEffect, useRef } from 'react';
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import { fetchCurrentEditCategoryGroup, fetchCurrentEditProperty, resetCurrentEditCategoryGroup, resetCurrentEditProperty, updateCategoryGroup, updateProperty } from '../../../redux/action/category.action';
import { Field, Formik } from 'formik';
import { Editor } from '@tinymce/tinymce-react';

const EditCategoryGroup = (props) => {
  const dispatch = useDispatch()
  let currentEditCategoryGroup = useSelector(state => state.category.currentEditCategoryGroup);
  const editorRef = useRef(null);

  const jwtToken = useSelector(state => state.auth.jwtToken);

  const getToken = () => {
    return jwtToken;
  }

  useEffect(() => {
    if (props.categoryGroup.id) {
      dispatch(fetchCurrentEditCategoryGroup({ id: props.categoryGroup.id }))
    } else {
      dispatch(resetCurrentEditCategoryGroup())
    }
  }, [props.property])

  return (
    <>
      {currentEditCategoryGroup && currentEditCategoryGroup.id &&
        <Formik
          initialValues={currentEditCategoryGroup}
          enableReinitialize={true}
          validate={values => {
            const errors = {};
            return errors;
          }}
          onSubmit={(values) => {
            dispatch(updateCategoryGroup({ id: values.id, parameters: values }));
          }}>
          {({ values, errors, handleSubmit, setFieldValue, setValues, handleChange }) => (
            <form onSubmit={handleSubmit} autoComplete="off" className="rd-form rd-mailform">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="listOrder">Name</label>
                    <Field id="name" type="text" name="name" placeholder="Name" className="form-control" value={values.name || ''}
                      onChange={(event) => {
                        setFieldValue("name", event.currentTarget.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="tooltip">Tooltip</label>
                    <Field id="tooltip" as="textarea" rows="4" name="tooltip" className="form-control" value={values.tooltip || ''}
                      onChange={(event) => {
                        setFieldValue("tooltip", event.currentTarget.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <button type="submit" className="button button-primary">Update</button>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      }
    </>
  );
}

export default EditCategoryGroup;
