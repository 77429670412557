import { FETCH_TRANSLATIONS_LIST_SUCCESS, FETCH_TRANSLATION_SUCCESS } from "../../action/public/translation.action";

const initialState = {
  common_en: {
  },
  common_de: {
  },
  list:[]
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_TRANSLATION_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    case FETCH_TRANSLATIONS_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload
      }
    default:
      return state;
  }
}