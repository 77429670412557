export const  SEARCH_PRODUCT_PRICE = "search product price"
export const  SEARCH_PRODUCT_PRICE_SUCCESS = "search product price success"
export const  SEARCH_PRODUCT_PRICE_FAILURE = "search product price failure"

export const  RESET_CURRENT_PRODUCTS = "reset product price"

export const searchProductPrice = (data) => ({
  type: SEARCH_PRODUCT_PRICE,
  payload: data
});


export const resetCurrentProducts = (data) => ({
  type: RESET_CURRENT_PRODUCTS,
});

