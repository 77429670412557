import React, { Component } from "react";
import { Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { AuthorizeUser } from "./AuthorizeUser";
import Loading from "./components/Loading"

export const ProtectedRoute = ({ component, role, ...args }) => {
  return role ? (
    <Route
      component={AuthorizeUser({ component, role })}
      {...args}
    />
  ) : (
    <Route
      component={component}
      {...args}
    />
  );
};