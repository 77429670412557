import { API_REQUEST } from "../action/api.action";

// this middleware care only for API calls
export const apiFlow = ({ dispatch, getState }) => next => action => {
  if (action.type === API_REQUEST) {
    const { method, url, onSuccess, onError } = action.meta;

    let request_params = {
      method: method
    }

    if (action.payload && action.payload.parameters) {
      request_params["body"] = JSON.stringify(action.payload.parameters)
      request_params["headers"] = { 'Accept': 'application/json, text/plain, */*', 'Content-Type': 'application/json' }
    }
    if (action.payload && action.payload.token) {
      request_params["headers"] = { 'Accept': 'application/json, text/plain, */*', 'Content-Type': 'application/json', 'Authorization' : `Bearer ${action.payload.token}`}
    } 

    if (getState().auth && getState().auth.token ) {
      request_params["headers"] = { 'Accept': 'application/json, text/plain, */*', 'Content-Type': 'application/json', 'Authorization' : `Bearer ${getState().auth.token}`}
    } 

    fetch(url, request_params).then(response => response.json())
      .then((data) => dispatch({ type: onSuccess, payload: data }))
      .catch(error => dispatch({ type: onError, payload: error }))
  }
  return next(action)
};

export const apiMdl = [apiFlow];