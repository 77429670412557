import React, { useEffect, useState } from "react";

import Loading from "../../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { deleteUiRule, fetchRules, resetCurrentEditRule } from "../../../redux/action/rules.action";
import { Fragment } from "react";
import { Button, Collapse, Modal } from 'react-bootstrap';
import SimpleRuleEditor from "../../components/SimpleRuleEditor.Component";
import { fetchCategoyGroupList, fetchCategoyList } from "../../../redux/action/category.action";
import { setShowAddRuleModal } from "../../../redux/action/ui.action";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import { updateToken } from "../../../redux/action/auth.action";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { deleteAufpreisRule, fetchAufpreisRules, resetCurrentEditAufpreisRule } from "../../../redux/action/admin/aufpreisRules.action";
import SimpleAufpreisRuleEditor from "../../components/admin/SimpleAufpreisRuleEditor.Component";
import EditCategoryGroup from "../../components/admin/EditCategoryGroup.Component";
import {useTranslation} from "react-i18next";

export const AdminEditCategoryGroupView = () => {
  const { t, i18n } = useTranslation('common');//国际化
  const dispatch = useDispatch()
  const { user, getAccessTokenSilently } = useAuth0();

  let groupList = useSelector(state => state.category.groupList);

  const [showEditCategoryGroupModal, setShowEditCategoryGroupModal] = useState(false)

  const [currentCategoryGroup, setCurrentCategoryGroup] = useState({})

  useEffect(() => {
    getAccessTokenSilently().then(token => {
      dispatch(fetchCategoyGroupList({ token: token }))
      dispatch(updateToken({ token: token }))
    })
  }, [])

  const { SearchBar } = Search;

  function actionFormatter(cell, row) {
    return (
      <div className="group group-middle">
        <button type="submit" className="button button-sm button-primary-outline button-winona" onClick={(e) => {
          e.preventDefault();
          setCurrentCategoryGroup(row)
          setShowEditCategoryGroupModal(true)
        }}
        >{t('admin.Edit')}</button>
      </div>
    );
  }

  function conditionsFormatter(cell, row) {
    return (
      <Fragment key={"rule_" + row.id}>
        {row.categories &&
          row.categories.map(function (elem) {
            return elem.name;
          }).join("; ")
        }
      </Fragment>
    );
  }

  const columns = [{
    dataField: 'id',
    text: 'ID',
    sort: true,
    headerClasses: 'width-procent-10'
  }, {
    dataField: 'name',
    text: 'Group Name',
    sort: true,
    headerClasses: 'width-procent-20'
  }, {
    dataField: 'categories',
    text: 'Categories',
    formatter: conditionsFormatter,
    filterValue: (cell, row) => {
      let ret = ""
      ret = row.categories.map((c, index) => c.name)
      return ret;
    }
  }, {
    dataField: 'action',
    text: 'Action',
    formatter: actionFormatter,
    headerClasses: 'width-procent-20'
  }];


  const options = {
    custom: true,
    totalSize: groupList.length
  };

  return (
    <>
      <Modal show={showEditCategoryGroupModal} size="xl" dialogClassName="modal-details">
        <Modal.Header>
          <Modal.Title>{t('admin.EditCategoryGroup')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditCategoryGroup categoryGroup={currentCategoryGroup} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="button button-icon button-icon-left button-winona" onClick={(e) => {
            setShowEditCategoryGroupModal(false)
          }}>
            {t('admin.Close')}
          </Button>
        </Modal.Footer>
      </Modal>
      {groupList &&
        <section className="section section-lg text-center">
          <div className="container">
            <PaginationProvider
              pagination={paginationFactory(options)}
            >
              {
                ({
                  paginationProps,
                  paginationTableProps,
                }) => (
                  <div>
                    <PaginationListStandalone
                      {...paginationProps}
                    />
                    <ToolkitProvider
                      bootstrap4
                      keyField="id"
                      data={groupList}
                      columns={columns}
                      search
                    >
                      {
                        toolkitprops => (
                          <div>
                            <div style={{ float: "left", padding: "10px 0px" }}>
                              <SearchBar {...toolkitprops.searchProps} style={{ float: "left" }} placeholder="Search Category Name / ID" />
                            </div>
                            <BootstrapTable
                              striped
                              hover
                              {...toolkitprops.baseProps}
                              {...paginationTableProps}
                            />
                          </div>
                        )
                      }
                    </ToolkitProvider>
                    <PaginationListStandalone
                      {...paginationProps}
                    />
                  </div>

                )
              }
            </PaginationProvider>
          </div>
        </section>
      }

    </>
  );
};

export default withAuthenticationRequired(AdminEditCategoryGroupView, {
  onRedirecting: () => <Loading />,
});
