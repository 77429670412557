import React, { Fragment } from "react";
import { Field, Formik } from "formik";
import _ from 'lodash'
import { useDispatch } from "react-redux";
import { postClaim } from "../../redux/action/claim.action";

const ClaimSuccess = () => (
  <Fragment>
    <h1>Claim success reported</h1>
  </Fragment>
);

export default ClaimSuccess;