export const FETCH_USER_PROFILE = "fetch user profile"
export const FETCH_USER_PROFILE_SUCCESS = "fetch user profile success"
export const FETCH_USER_PROFILE_FAILURE = "fetch user profile failure"

export const UPDATE_USER = "update user"
export const UPDATE_USER_SUCCESS = "update user success"
export const UPDATE_USER_FAILURE = "update user failure"

export const fetchUserProfile = (data) => ({
  type: FETCH_USER_PROFILE,
  payload: data
});

export const updateUser = (data) => ({
  type: UPDATE_USER,
  payload: data
});


