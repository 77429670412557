import React, { useEffect, useState, useRef, Fragment } from 'react';
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import moment from 'moment';
import Loading from '../../components/Loading';
import { fetchAllRfqs } from '../../../redux/action/admin/adminRfq.action';
import { updateToken } from '../../../redux/action/auth.action';
import { AuthenticatedLink } from '../../components/AuthenticatedLink';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import {useTranslation} from "react-i18next";

const AdminRfqsView = () => {
  const rfqs = useSelector(state => state.rfqHistory);
  const { t, i18n } = useTranslation('common');//国际化
  const { user, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch()
  const [token, setToken] = useState('')

  useEffect(() => {
    getAccessTokenSilently().then(token => {
      setToken(token)
      dispatch(updateToken({ token: token }))
      dispatch(fetchAllRfqs({ token: token }))
    })
  }, [])

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { SearchBar } = Search;


  function actionFormatter(cell, row) {
    return (
      <div className="group group-middle">
        <a href={"/digicat/admin/rfqs/" + row.id}>
          <i className="icon icon-sm link-default mdi mdi-eye pointer" ></i>
        </a>
        {row.pdfPath &&
          <>
            <AuthenticatedLink url={`/admin-api/v1/rfq/${row.id}/download`} filepath={row.pdfPath} filename={`rfq_${row.id}.pdf`}>
              <i className="icon icon-sm link-default fa fa-file-pdf-o"></i>
            </AuthenticatedLink>
            <AuthenticatedLink url={`/admin-api/v1/rfq/${row.id}/download`} filepath={row.pdfPath} filename={`rfq_${row.id}.pdf`} downloadLink={true}>
              <i className="icon icon-sm link-default mdi mdi-download"></i>
            </AuthenticatedLink>
          </>
        }
      </div>
    );
  }

  const columns = [{
    dataField: 'id',
    text: 'ID',
    sort: true,
  }, {
    dataField: 'date',
    text: 'Date',
    formatter: (cell, row) => { return (<>{moment(row.creationDate).format('YYYY-MM-DD')}</>) },
    sort: true,
  }, {
    dataField: 'unitPrice',
    text: 'Unit Price',
    formatter: (cell, row) => { return (<>{row.quotationItems[0] ? row.quotationItems[0].unitPrice > 0 ? `${parseFloat(row.quotationItems[0].unitPrice).toFixed(2)} €` : 'Auf Anfrage' : ''}</>) }
  }, {
    dataField: 'quantity',
    text: 'Quantity',
    formatter: (cell, row) => { return (<>{row.quotationItems[0] ? row.quotationItems[0].quantity : ''}</>) }
  }, {
    dataField: 'totalPrice',
    text: 'Total Price',
    formatter: (cell, row) => { return (<>{row.quotationItems[0] ? row.quotationItems[0].totalPrice > 0 ?  `${parseFloat(row.quotationItems[0].totalPrice).toFixed(2)} €` : 'Auf Anfrage' : ''}</>) }
  }, {
    dataField: 'notice',
    text: 'Notice',
    formatter: (cell, row) => { return (<>{row.quotationItems[0] ? row.quotationItems[0].notice : ''}</>) }
  }, {
    dataField: 'action',
    text: '',
    formatter: actionFormatter
  }];


  const options = {
    custom: true,
    totalSize: rfqs.length
  };

  return (
    <Fragment>
      {rfqs && rfqs.length > 0 &&
        <section className="section section-lg text-center">
          <div className="container">
            <PaginationProvider
              pagination={paginationFactory(options)}
            >
              {
                ({
                  paginationProps,
                  paginationTableProps,
                }) => (
                  <div>
                    <PaginationListStandalone
                      {...paginationProps}
                    />
                    <ToolkitProvider
                      bootstrap4
                      keyField="id"
                      data={rfqs}
                      columns={columns}
                      search
                    >
                      {
                        toolkitprops => (
                          <div>
                            <div style={{ float: "left", padding: "10px 0px" }}>
                              <SearchBar {...toolkitprops.searchProps} style={{ float: "left" }} placeholder="Search Category Name / ID" />
                            </div>
                            <BootstrapTable
                              striped
                              hover
                              {...toolkitprops.baseProps}
                              {...paginationTableProps}
                            />
                          </div>
                        )
                      }
                    </ToolkitProvider>
                    <PaginationListStandalone
                      {...paginationProps}
                    />
                  </div>

                )
              }
            </PaginationProvider>
          </div>
        </section>
      }

    </Fragment>
  );
}

export default withAuthenticationRequired(AdminRfqsView, {
  onRedirecting: () => <Loading />,
});
