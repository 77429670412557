export const  FETCH_AUFPREIS_RULES = "fetch aufpreis rules"
export const  FETCH_AUFPREIS_RULES_SUCCESS = "fetch aufpreis rules success"
export const  FETCH_AUFPREIS_RULES_FAILURE = "fetch aufpreis rules failure"

export const  ADD_AUFPREIS_RULE = "add aufpreis rule"
export const  ADD_AUFPREIS_RULE_SUCCESS = "add aufpreis rule success"
export const  ADD_AUFPREIS_RULE_FAILURE = "add aufpreis rule failure"

export const  DELETE_AUFPREIS_RULE = "delete aufpreis rule"
export const  DELETE_AUFPREIS_RULE_SUCCESS = "delete aufpreis rule success"
export const  DELETE_AUFPREIS_RULE_FAILURE = "delete aufpreis rule failure"

export const  RESET_CURRENT_EDIT_AUFPREIS_RULE = "reset current edit aufpreis rule"

export const  FETCH_EDIT_AUFPREIS_RULE_BY_ID = "fetch aufpreis rule by id"
export const  FETCH_EDIT_AUFPREIS_RULE_BY_ID_SUCCESS = "fetch aufpreis rule by id success"
export const  FETCH_EDIT_AUFPREIS_RULE_BY_ID_FAILURE = "fetch aufpreis rule by id failure"

export const fetchAufpreisRules = (data) => ({
  type: FETCH_AUFPREIS_RULES,
  payload: data
});

export const addAufpreisRule = (data) => ({
  type: ADD_AUFPREIS_RULE,
  payload: data
});

export const deleteAufpreisRule = (data) => ({
  type: DELETE_AUFPREIS_RULE,
  payload: data
});

export const resetCurrentEditAufpreisRule = (data) => ({
  type: RESET_CURRENT_EDIT_AUFPREIS_RULE,
  payload: data
});

export const fetchEditAufpreisRuleById = (data) => ({
  type: FETCH_EDIT_AUFPREIS_RULE_BY_ID,
  payload: data
});



