import React, { useEffect, useState, useRef } from 'react';
import { Button, Collapse, Modal } from 'react-bootstrap';
import _ from 'lodash'
import { Field, useFormikContext } from 'formik';
import * as Icon from 'react-bootstrap-icons';
import { updateUi } from '../../../redux/action/ui.action';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRfqDetailById, fetchRfqHistory } from '../../../redux/action/rfq.action';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import moment from 'moment';
import RfqDetail from '../../components/RfqDetails.Component';
import { updateToken } from '../../../redux/action/auth.action';
import Loading from '../../components/Loading';
import { fetchFullChildCategoriesForMerkmal } from '../../../redux/action/category.action';
import {useTranslation} from "react-i18next";

function getParams(location, name) {
  const searchParams = new URLSearchParams(location.search);
  let ret = {};
  ret[name] = searchParams.get(name) || "";
  return ret;
}

const RfqHistoryView = () => {
  const { t, i18n } = useTranslation('common');//国际化
  const dispatch = useDispatch();
  const { user, getAccessTokenSilently } = useAuth0();
  const rfqDetail = useSelector(state => state.rfqDetail);
  const merkmalenChildren = useSelector(state => state.category.merkmalenChildren);

  useEffect(() => {
    getAccessTokenSilently().then(token => {
      dispatch(updateToken({ token: token }))
      dispatch(fetchFullChildCategoriesForMerkmal())
      let urlParams = getParams(window.location, "id");
      if (urlParams.id) {
        dispatch(fetchRfqDetailById({ id: urlParams.id }))
      }
    })
  }, [])

  return (
    <section className="section section-lg text-center">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <RfqDetail rfq={rfqDetail} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default withAuthenticationRequired(RfqHistoryView, {
  onRedirecting: () => <Loading />,
});
