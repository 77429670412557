import React, { useEffect, useState } from 'react';
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { Field, useFormikContext } from 'formik';
import { Button, Modal } from 'react-bootstrap';
import { Fragment } from 'react';
import { updateToken } from '../../redux/action/auth.action';
import ReactTooltip from 'react-tooltip';
import { addToChangedCategoryIds } from '../../redux/action/ui.action';
import { useTranslation } from 'react-i18next';

const invertColor = (hex) => {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  let r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);
  return (r * 0.299 + g * 0.587 + b * 0.114) > 150
    ? '#000000'
    : '#FFFFFF';

}

const Merkmal = (props) => {
  const currentCategory = props.category
  const dispatch = useDispatch();
  const children = useSelector(state => state.category.children);
  const { user, getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState('')
  const [collapse, setCollapse] = useState({})
  const { values, setFieldValue, submitForm, setValues, errors } = useFormikContext();
  let stepsArray = useSelector(state => state.steps.stepsArray);

  const blockArray = useSelector(state => state.ui.blockArray);
  const maxValueArray = useSelector(state => state.ui.maxValueArray);

  const [checkBoxModals, setCheckBoxModals] = useState({})
  const { t, i18n } = useTranslation('common');

  useEffect(() => {
    if (!token) {
      getAccessTokenSilently().then(token => {
        setToken(token)
        dispatch(updateToken({ token: token }))
      })
    }
  }, [])

  return (
    <>
      {
        currentCategory.subCategories.map((s, index) => {
          {
            const MAX_VALUE = [...maxValueArray].filter(x => x.name === "category_" + s.id)[0]?.value
            return (
              <div key={"sub_" + index} className="col-sm-12">
                {s.categoryType === 'DROPDOWN' &&
                  <Fragment>
                    <div className={errors["category_" + s.id] ? "form-wrap form-wrap-select error-div" : "form-wrap form-wrap-select  "} >
                      <label className="nowrap form-label-outside" htmlFor="counter">{t(`category.name.${_.snakeCase(s.name)}`)}
                        {s.tooltip &&
                          <>
                            <a data-tip className="inline-block inline-block" data-for={"tooltips_" + s.id}>
                              <i className="icon icon-sm link-default mdi mdi-help-circle"></i>
                            </a>
                            <ReactTooltip className="custom-mouseover-tooltip" place="right" type="dark" effect="float" id={"tooltips_" + s.id}>
                              <p style={{ whiteSpace: "pre-wrap", marginTop: "0px", maxWidth: "250px" }}>{t(`category.name.${_.snakeCase(s.name)}.tooltip`)}</p>
                            </ReactTooltip>
                          </>
                        }
                        
                        {errors["category_" + s.id] && <span className='error-text pl10'>({errors[`category_${s.id}`]})</span>}
                      </label>

                      <select disabled={blockArray.has("category_" + s.id)} className="form-control select" name="counter" data-minimum-results-for-search="Infinity" value={values[`category_${s.id}`]?.value || ''} onChange={(event) => {
                        if (event.currentTarget.value) {
                          let counter = s.properties.filter(p => p.name === event.currentTarget.value)[0]?.counter || 0
                          values[`category_${s.id}`] = { category: s, value: event.currentTarget.value, counter: counter }
                        } else {
                          delete values[`category_${s.id}`]
                        }
                        values.changedCategory = s
                        dispatch(addToChangedCategoryIds({id:s.id}))
                        setValues(values)
                      }}
                      >
                        <option value="">please choose</option>
                        {
                          s.properties.filter(p => !blockArray.has("property_" + p.id)).map((p, index) => {
                            return (
                              <Fragment key={"properties_" + p.id}>
                                <option value={p.value || p.name}>{t(`category_property.name.${_.snakeCase(p.name)}`)} </option>
                              </Fragment>
                            )
                          })
                        }
                      </select>
                    </div>
                  </Fragment>
                }

                {s.categoryType === 'CHECKBOX' &&
                  <Fragment>
                    <div className="form-wrap form-wrap-select mt10 mb10" >
                      <label className="nowrap form-label-outside" htmlFor="contact-2-first-name">
                        <button onClick={() => {
                          let m = {}
                          m["_" + s.id] = true;
                          setCheckBoxModals(m)
                        }}>
                          {t(`category.name.${_.snakeCase(s.name)}`)}
                        </button>
                        {s.tooltip &&
                          <>
                            <a data-tip className="inline-block inline-block" data-for={"tooltips_" + s.id}>
                              <i className="icon icon-sm link-default mdi mdi-help-circle"></i>
                            </a>
                            <ReactTooltip className="custom-mouseover-tooltip" place="right" type="dark" id={"tooltips_" + s.id}>
                              <p style={{ whiteSpace: "pre-wrap", marginTop: "0px", maxWidth: "250px" }}>{t(`category.name.${_.snakeCase(s.name)}.tooltip`)}</p>
                            </ReactTooltip>
                          </>
                        }
                        
                      </label>

                      <Modal show={checkBoxModals["_" + s.id]} dialogClassName="unset-height">
                        <Modal.Header>
                          <Modal.Title>{s.name}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className='row'>
                            {
                              s.properties.filter(p => !blockArray.has("property_" + p.id)).map((p, index) => {
                                return (
                                  <div key={"properties_" + p.id} className="col-md-6">
                                    <label ><Field type="checkbox" checked={values[`category_${s.id}`]?.value?.indexOf(";" + p.id.toString() + ";") >= 0 || false} onClick={(event) => {
                                      if (event.currentTarget.checked) {
                                        if (values[`category_${s.id}`]?.value?.length > 0) {
                                          values[`category_${s.id}`].value = values[`category_${s.id}`].value + ";" + p.id.toString() + ";"
                                        } else {
                                          values[`category_${s.id}`] = { category: s, value: ";" + p.id.toString() + ";" }
                                        }
                                      } else {
                                        if (values[`category_${s.id}`]?.value?.length > 0) {
                                          values[`category_${s.id}`].value = values[`category_${s.id}`].value.replaceAll(";" + p.id.toString() + ";", "")
                                        }
                                      }
                                      values.changedCategory = s
                                      setValues(values)
                                    }} /> {t(`category_property.name.${_.snakeCase(p.name)}`)} </label>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button className="button button-icon button-icon-left button-winona" onClick={(e) => {
                            let m = {}
                            m["_" + s.id] = false;
                            setCheckBoxModals(m)
                          }}>
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  </Fragment>
                }

                {s.categoryType === 'FREE_TEXT' &&
                  <Fragment>
                    <div className="nowrap form-wrap form-wrap-select ">
                      <label htmlFor="name">{t(`category.name.${_.snakeCase(s.name)}`)}
                        {s.tooltip &&
                          <>
                            <a data-tip className="inline-block inline-block" data-for={"tooltips_" + s.id}>
                              <i className="icon icon-sm link-default mdi mdi-help-circle"></i>
                            </a>
                            <ReactTooltip className="custom-mouseover-tooltip" place="right" type="dark" effect="float" id={"tooltips_" + s.id}>
                              <p style={{ whiteSpace: "pre-wrap", marginTop: "0px", maxWidth: "250px" }}>{t(`category.name.${_.snakeCase(s.name)}.tooltip`)}</p>
                            </ReactTooltip>
                          </>
                        }
                      </label>
                      {errors["category_" + s.id] && <span className='error-text pl10'>({errors[`category_${s.id}`]})</span>}
                      <Field disabled={blockArray.has("category_" + s.id)} id="name" type="text" name="name" className="form-control" value={values[`category_${s.id}`]?.value || ''}
                        onChange={(event) => {
                          let maxVaue = [...maxValueArray].filter(x => x.name === "category_" + s.id)[0]?.value

                          if (Number(event.currentTarget.value) > Number(maxVaue)) {
                            values["max_errors"] = {name: "category_" + s.id, message: "maximum value exceeded"}
                          }
                          
                          values[`category_${s.id}`] = { category: s, value: event.currentTarget.value }
                          setValues(values)
                        }}
                      />
                    </div>
                  </Fragment>
                }

                {s.categoryType === 'COLOR_DROPDOWN' &&
                  <Fragment>
                    <div className="form-wrap form-wrap-select ">
                      <label className="nowrap form-label-outside" htmlFor="contact-2-first-name">{t(`category.name.${_.snakeCase(s.name)}`)}
                        {s.tooltip &&
                          <>
                            <a data-tip className="inline-block inline-block" data-for={"tooltips_" + s.id}>
                              <i className="icon icon-sm link-default mdi mdi-help-circle"></i>
                            </a>
                            <ReactTooltip className="custom-mouseover-tooltip" place="right" type="dark" effect="float" id={"tooltips_" + s.id}>
                              <p style={{ whiteSpace: "pre-wrap", marginTop: "0px", maxWidth: "250px" }}>{t(`category.name.${_.snakeCase(s.name)}.tooltip`)}</p>
                            </ReactTooltip>
                          </>
                        }
                        
                      </label>
                      <select disabled={blockArray.has("category_" + s.id)} className="form-control select" name="find-job-location" data-minimum-results-for-search="Infinity" value={values[`category_${s.id}`]?.value || ''} onChange={(event) => {
                        if (event.currentTarget.value) {
                          let counter = s.properties.filter(p => p.name === event.currentTarget.value)[0]?.counter || 0
                          values[`category_${s.id}`] = { category: s, value: event.currentTarget.value, counter: counter }
                        } else {
                          delete values[`category_${s.id}`]
                        }
                        values.changedCategory = s
                        dispatch(addToChangedCategoryIds({id:s.id}))
                        setValues(values)
                      }}
                      >
                        <option value="">please choose</option>
                        {
                          s.properties.filter(p => !blockArray.has("property_" + p.id)).map((p, index) => {
                            return (
                              <Fragment key={"properties_" + p.id}>
                                <option value={p.name} style={{ backgroundColor: p.value, color: invertColor(p.value) }}>{t(`category_property.name.${_.snakeCase(p.name)}`)}</option>
                              </Fragment>
                            )
                          })
                        }
                      </select>
                    </div>
                  </Fragment>
                }
              </div>
            )
          }
        })
      }
    </>
  )
}
export default Merkmal;
