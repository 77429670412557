import { FETCH_ALL_RFQS_SUCCESS } from "../action/admin/adminRfq.action";
import { ADD_TO_RFQ, FETCH_RFQ_HISTORY_SUCCESS } from "../action/rfq.action";

const initialState = [];

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_RFQ_HISTORY_SUCCESS:
      return action.payload
    case FETCH_ALL_RFQS_SUCCESS:
      return action.payload
    default:
      return state;
  }
}