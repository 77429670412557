import { FETCH_WEB_CONTENTS_SUCCESS } from "../../action/public/web-content.action";

const initialState = {
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_WEB_CONTENTS_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state;
  }
}