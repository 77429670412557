import { FETCH_ALL_ORDERS_SUCCESS } from "../../action/internal/internal-orders.action";
import { FETCH_USER_ORDERS, FETCH_USER_ORDERS_SUCCESS } from "../../action/user/user-orders.action";

const initialState = {
  list: []
};

export default function userOrdersReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_USER_ORDERS_SUCCESS:
      return {
        ...state,
        list: action.payload
      }
    case FETCH_ALL_ORDERS_SUCCESS:
      return {
        ...state,
        list: action.payload
      }
    default:
      return state;
  }
}