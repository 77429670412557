import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategoryTreeByStep, fetchChildCategories } from '../../redux/action/category.action';
import { useAuth0 } from '@auth0/auth0-react';
import { ErrorMessage, Field, Formik, useFormikContext } from 'formik';
import { searchProductPrice } from '../../redux/action/product-price.action';
import { updateToken } from '../../redux/action/auth.action';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

const PreSelections = (props) => {
  const currentCategory = props.category
  const dispatch = useDispatch();
  const children = useSelector(state => state.category.children);
  const { user, getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState('')
  let countryGroups = useSelector(state => state.country.groups);
  let countryGroupMap = useSelector(state => state.country.countryGroupMap);
  let targetCountryGroupCategory = useSelector(state => state.country.targetCountyGroupCategory);
  let stepsArray = useSelector(state => state.steps.stepsArray);
  const { values, setFieldValue, submitForm , setValues} = useFormikContext();
  const blockArray = useSelector(state => state.ui.blockArray);
  const { t, i18n } = useTranslation('common');

  useEffect(() => {
    getAccessTokenSilently().then(token => {
      setToken(token)
      dispatch(fetchChildCategories({ token: token, categoryId: currentCategory.id }))
      dispatch(updateToken({ token: token }))
    })
  }, [currentCategory])

  useEffect(() => {
    children.forEach((c) => {
      delete values[`${c.productPriceColumn}`]
      delete values[`category_${c.id}`]
    })
    delete values[`category_${targetCountryGroupCategory.id}`]
  }, [children])

  return (
    <>
      <div className="row">
        {
          children.map(c => {
            return (
              <div key={"preselction_" + c.id} className="col-sm-3" style={{ height: "100px" }}>
                <article className="post-classic">
                  {c.categoryType === 'DROPDOWN' && c.name === 'target_country' &&
                    <>
                      <div className="form-wrap form-wrap-select" >
                        <label className="nowrap form-label-outside" htmlFor="contact-2-first-name">{t(`category.name.${_.snakeCase(c.name)}`)}
                          { c.tooltip &&
                            <>
                              <a data-tip className="inline-block inline-block" data-for={"tooltips_" + c.id}> 
                                <i className="icon icon-sm link-default mdi mdi-help-circle"></i>
                              </a>
                              <ReactTooltip className="custom-mouseover-tooltip" place="right" type="dark" effect="float" id={"tooltips_" + c.id} >
                                <p style={{whiteSpace:"pre-wrap", marginTop:"0px" , maxWidth:"250px"}}>{c.tooltip}</p>
                              </ReactTooltip>
                            </>
                          }
                          { c.tooltipWordpressId &&
                            <>
                              <a data-event='click' data-tip className="inline-block inline-block" data-for={"tooltips_wordpress_" + c.id}> 
                                <i className="icon icon-sm link-default mdi mdi-more"></i>
                              </a>
                              <ReactTooltip globalEventOff='click' effect='solid' clickable={true} place="right" type="dark" effect="float" id={"tooltips_wordpress_" + c.id}>
                                <div className="custom-tooltip">
                                  <p dangerouslySetInnerHTML={{ __html: c.tooltipWordpressContent }} />
                                </div>
                              </ReactTooltip>
                            </>
                          }
                        </label>
                        <select disabled={blockArray.has("category_" + c.id)} className="form-input select" name={c.productPriceColumn} value={values[`${c.productPriceColumn}`] || ''} data-minimum-results-for-search="Infinity" onChange={(event) => {
                          if (event.currentTarget.value) {
                            values[`${c.productPriceColumn}`] = event.currentTarget.value
                            values[`category_${c.id}`] = { category: c, value: event.currentTarget.value }
                            values[`category_${targetCountryGroupCategory.id}`] = { category: targetCountryGroupCategory, value: countryGroupMap[event.currentTarget.value] }
                          } else {
                            delete values[`${c.productPriceColumn}`]
                            delete values[`category_${c.id}`]
                            delete values[`category_${targetCountryGroupCategory.id}`]
                          }
                          setValues(values)
                        }}
                        >
                          <option value="">please choose</option>
                          {
                            countryGroups.map((g, index) => {
                              return (
                                <optgroup key={"properties_country_group_" + index} label={g.name}>
                                  { 
                                    g.countries.map((c, index) => {
                                      return (
                                        <option key={"properties_country_" +index} value={c.a2}>{c.name}</option>
                                      )
                                    })
                                  }
                                </optgroup>
                              )
                            })
                          }
                        </select>
                      </div>
                    </>
                  }
                  {c.categoryType === 'DROPDOWN' && c.name !== 'target_country' &&
                    <>
                      <div className="form-wrap form-wrap-select">
                        <label className="nowrap form-label-outside" htmlFor="contact-2-first-name">{t(`category.name.${_.snakeCase(c.name)}`)}
                          { c.tooltip &&
                            <>
                              <a data-tip className="inline-block inline-block" data-for={"tooltips_" + c.id}> 
                                <i className="icon icon-sm link-default mdi mdi-help-circle"></i>
                              </a>
                              <ReactTooltip className="custom-mouseover-tooltip"  place="right" type="dark" effect="float" id={"tooltips_" + c.id}>
                                <p style={{whiteSpace:"pre-wrap", marginTop:"0px" , maxWidth:"250px"}}>{c.tooltip}</p>
                              </ReactTooltip>
                            </>
                          }
                          { c.tooltipWordpressId &&
                            <>
                              <a data-event='click' data-tip className="inline-block inline-block" data-for={"tooltips_wordpress_" + c.id}>
                                <i className="icon icon-sm link-default mdi mdi-more"></i>
                              </a>
                              <ReactTooltip globalEventOff='click' effect='solid' clickable={true} place="right" type="dark" effect="float" id={"tooltips_wordpress_" + c.id}>
                                <div className="custom-tooltip">
                                  <p dangerouslySetInnerHTML={{ __html: c.tooltipWordpressContent }} />
                                </div>
                              </ReactTooltip>
                            </>
                          }
                        </label>
                        <select disabled={blockArray.has("category_" + c.id)} className="form-input select" name={c.productPriceColumn} value={values[`category_${c.id}`]?.value || ''} data-minimum-results-for-search="Infinity" onChange={(event) => {
                          if (event.currentTarget.value) {
                            values[`${c.productPriceColumn}`] = event.currentTarget.value
                            values[`category_${c.id}`] = { category: c, value: event.currentTarget.value }
                          } else {
                            delete values[`${c.productPriceColumn}`]
                            delete values[`category_${c.id}`]
                          }
                          setValues(values)
                        }}
                        >
                          <option value="">please choose</option>
                          {
                            c.properties.filter(p => !blockArray.has("property_" + p.id)).map((p, index) => {
                              return (
                                <option className={"option_" +c.productPriceColumn} key={"properties_" + p.id} value={p.value || p.name}>{t(`category_property.name.${_.snakeCase(p.name)}`)}</option>
                              )
                            })
                          }
                        </select>
                      </div>
                    </>
                  }
                  {c.categoryType === 'RANGE' &&
                    <>
                      <div className="row row-10">
                        <div className="col-md-6">
                          <div className="form-wrap">
                            <label className="nowrap form-label-outside" htmlFor="contact-2-first-name">{t(`category.name.${_.snakeCase(c.name)}`)}</label>
                            <input disabled={blockArray.has("category_" + c.id)} className="form-input" placeholder="von" value={values[`${c.productPriceColumn}Min`] || ''} type="text" name="name" onChange={(event) => {
                              values[`${c.productPriceColumn}Min`] = event.currentTarget.value
                              setValues(values)
                            }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-wrap">
                            <label className="nowrap form-label-outside" htmlFor="contact-2-last-name">{t(`category.name.${_.snakeCase(c.name)}`)}
                            { c.tooltip &&
                              <>
                                <a data-tip className="inline-block inline-block" data-for={"tooltips_" + c.id}> 
                                  <i className="icon icon-sm link-default mdi mdi-help-circle"></i>
                                </a>
                                <ReactTooltip className="custom-mouseover-tooltip" place="right" type="dark" effect="float" id={"tooltips_" + c.id}>
                                  <p style={{whiteSpace:"pre-wrap", marginTop:"0px" , maxWidth:"250px"}}>{c.tooltip}</p>
                                </ReactTooltip>
                              </>
                            }
                            { c.tooltipWordpressId &&
                              <>
                                <a data-event='click' data-tip className="inline-block inline-block" data-for={"tooltips_wordpress_" + c.id}>
                                  <i className="icon icon-sm link-default mdi mdi-more"></i>  
                                </a>
                                <ReactTooltip globalEventOff='click' effect='solid' clickable={true} place="right" type="dark" effect="float" id={"tooltips_wordpress_" + c.id}>
                                  <div className="custom-tooltip">
                                    <p dangerouslySetInnerHTML={{ __html: c.tooltipWordpressContent }} />
                                  </div>
                                </ReactTooltip>
                              </>
                            }
                            </label>
                            <input disabled={blockArray.has("category_" + c.id)} className="form-input" placeholder="bis" value={values[`${c.productPriceColumn}Max`] || ''} type="text" name="name" onChange={(event) => {
                              values[`${c.productPriceColumn}Max`] = event.currentTarget.value
                              setValues(values)
                            }}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  }
                </article>
              </div>
            )
          })
        }
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="group group-middle">
            <button type="submit" className="button button-icon button-icon-left button-lg button-primary button-winona" onClick={(e) => {
              values['productLine'] = _.last(stepsArray).value
              
              let baugroesseIn = []
              Array.from(document.querySelectorAll('.option_baugroesse')).forEach(element => {
                baugroesseIn.push(element.value);
              })
              values['baugroesseIn'] = baugroesseIn.join(",");
              
              let wirkungsgradIn = []
              Array.from(document.querySelectorAll('.option_wirkungsgrad')).forEach(element => {
                wirkungsgradIn.push(element.value);
              })
              values['wirkungsgradIn'] = wirkungsgradIn.join(",");

              Array.from(document.querySelectorAll('select')).forEach(element => {
                if (element.name && element.value) {
                  values[element.name] = element.value;
                }
              })
              
              dispatch(searchProductPrice({ parameters: values, token }))
            }}
            ><span className="icon mdi mdi-magnify"></span>Search</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default PreSelections;
