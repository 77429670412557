import React, { useEffect } from 'react';
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import { fetchCurrentEditProperty, resetCurrentEditProperty, updateProperty } from '../../../redux/action/category.action';
import { Field, Formik } from 'formik';
import { updateClaimReason } from '../../../redux/action/claim.action';

const EditReason = (props) => {
  const dispatch = useDispatch()
  let currentReason = props.reason;
  const reasonTree = useSelector(state => state.reasons.tree);

  return (
    <>
      {currentReason &&
        <Formik
          initialValues={currentReason}
          enableReinitialize={true}
          validate={values => {
            const errors = {};
            return errors;
          }}
          onSubmit={(values) => {
            dispatch(updateClaimReason({ id: values.id, parameters: values }));
          }}>
          {({ values, errors, handleSubmit, setFieldValue, setValues }) => (
            <form onSubmit={handleSubmit} autoComplete="off" className="rd-form rd-mailform">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="listOrder">Name</label>
                    <Field id="name" type="text" name="name" placeholder="Name" className="form-control" value={values.name || ''}
                      onChange={(event) => {
                        setFieldValue("name", event.currentTarget.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="listOrder">Parent</label>
                    <select className="form-input select" value={values.parentId || ''} onChange={(event) => {
                      setFieldValue("parentId", event.currentTarget.value);
                    }}>
                      <option value="" label="please choose parent reason" />
                      {reasonTree &&
                        reasonTree.map((r, index) => {
                          return (
                            <option key={"r_" + index} value={r.id} label={r.name} />
                          )
                        })
                      }
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <button type="submit" className="button button-primary">Create</button>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      }
    </>
  );
}

export default EditReason;
