import { apiRequest } from "../../action/api.action"
import { getHost } from "../../../react/utils/host"
import { FETCH_CUSTOMERS, FETCH_CUSTOMERS_FAILURE, FETCH_CUSTOMERS_SUCCESS, SEARCH_CUSTOMERS, SEARCH_CUSTOMERS_FAILURE, SEARCH_CUSTOMERS_SUCCESS } from "../../action/admin/admin-customers.action";

export const adminCustomersFlow = ({ dispatch }) => next => action => {
  next(action)
  if (action.type === FETCH_CUSTOMERS) {
    let url = `${getHost()}/admin-api/v1/customers`
    dispatch(apiRequest("GET", url, null, FETCH_CUSTOMERS_SUCCESS, FETCH_CUSTOMERS_FAILURE));
  }

  if (action.type === SEARCH_CUSTOMERS) {
    let url = `${getHost()}/admin-api/v1/customers/search?term=${action.payload.term}`;
    dispatch(apiRequest('GET', url, null, SEARCH_CUSTOMERS_SUCCESS, SEARCH_CUSTOMERS_FAILURE));
  }
};

export const adminCustomersMdl = [adminCustomersFlow];