export const  FETCH_COUNTRY_GROUP = "fetch country group "
export const  FETCH_COUNTRY_GROUP_SUCCESS = "fetch country group success"
export const  FETCH_COUNTRY_GROUP_FAILURE = "fetch country group failure"

export const  FETCH_COUNTRY_GROUP_CATEGORY = "fetch country group category"
export const  FETCH_COUNTRY_GROUP_CATEGORY_SUCCESS = "fetch country group category success"
export const  FETCH_COUNTRY_GROUP_CATEGORY_FAILURE = "fetch country group category failure"

export const fetchCountryGroup = (data) => ({
  type: FETCH_COUNTRY_GROUP,
  payload: data
});

export const fetchCountryGroupCategory = (data) => ({
  type: FETCH_COUNTRY_GROUP_CATEGORY,
  payload: data
});


