import { UPDATE_TOKEN } from "../action/auth.action";

const initialState = {
  token: ""
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_TOKEN:
      return {
        ...state,
        token: action.payload.token
      }
    default:
      return state;
  }
}