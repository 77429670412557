import { FETCH_AUFPREIS_RULES_SUCCESS, FETCH_EDIT_AUFPREIS_RULE_BY_ID_SUCCESS, RESET_CURRENT_EDIT_AUFPREIS_RULE } from "../../action/admin/aufpreisRules.action";
import _ from 'lodash'

const initialState = {
  rules: [],
  currentEditRule: {
    constaintCategories: [],
    prices: [],
    prozentPrices:[],
    leadTimeWeeks:0,
  },
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_AUFPREIS_RULES_SUCCESS:
      return {
        ...state,
        rules: action.payload
      }
    case FETCH_EDIT_AUFPREIS_RULE_BY_ID_SUCCESS:
      let categories = {}
      action.payload.categoryValues.forEach(element => {
        categories[_.snakeCase(element.name)] = element.values
        categories[_.snakeCase(element.name) + "_default"] = element.defaultValue
      });
      let prices = []
      action.payload.priceRuleEvents.forEach(e => {
        prices["baugroesse_" + e.priceRuleEventParamter.baugroesse] = e.priceRuleEventParamter.aufpreis
      });

      let prozentPrices = []
      action.payload.priceRuleEvents.forEach(e => {
        prozentPrices["baugroesse_" + e.priceRuleEventParamter.baugroesse] = (e.priceRuleEventParamter.aufpreisProzent * 100.0).toFixed(2)
      });

      let leadTimeWeeks = 0
      action.payload.priceRuleEvents.forEach(e => {
        if (e.priceRuleEventParamter.eventType === 'ADD_LEADTIME')
        leadTimeWeeks = e.priceRuleEventParamter.leadTimeWeeks
      });

      return {
        ...state,
        currentEditRule: {
          ...action.payload,
          ...categories,
          prices: prices,
          prozentPrices,
          leadTimeWeeks,
        }
      }
    case RESET_CURRENT_EDIT_AUFPREIS_RULE:
      return {
        ...state,
        currentEditRule: {
          constaintCategories: [],
          prices: [],
          prozentPrices:[]
        }
      }
    default:
      return state;
  }
}