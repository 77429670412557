export const POST_CLAIM = "post claim"
export const POST_CLAIM_SUCCESS = "post claim success"
export const POST_CLAIM_FAILURE = "post claim failure"

export const FETCH_CLAIM_LIST = "fetch claim list"
export const FETCH_CLAIM_LIST_SUCCESS = "fetch claim list success"
export const FETCH_CLAIM_LIST_FAILURE = "fetch claim list failure"

export const FETCH_CLAIM_BY_ID = "fetch claim by id"
export const FETCH_CLAIM_BY_ID_SUCCESS = "fetch claim by id success"
export const FETCH_CLAIM_BY_ID_FAILURE = "fetch claim by id failure"

export const DELETE_CLAIM_ITEM_BY_ID = "delete claim item by id"
export const DELETE_CLAIM_ITEM_BY_ID_SUCCESS = "delete claim item by id success"
export const DELETE_CLAIM_ITEM_BY_ID_FAILURE = "delete claim item by id failure"

export const UPDATE_CLAIM_ITEM_BY_ID = "update claim item by id"
export const UPDATE_CLAIM_ITEM_BY_ID_SUCCESS = "update claim item by id success"
export const UPDATE_CLAIM_ITEM_BY_ID_FAILURE = "update claim item by id failure"

export const ADD_CLAIM_ITEM_BY_ID = "add claim item by id"
export const ADD_CLAIM_ITEM_BY_ID_SUCCESS = "add claim item by id success"
export const ADD_CLAIM_ITEM_BY_ID_FAILURE = "add claim item by id failure"

export const SET_CURRENT_CLAIM_ITEM = "set current claim item"

export const SET_CURRENT_CLAIM = "set current claim"

export const UPDATE_CLAIM_ITEM = "update claim item"

export const DELETE_CLAIM_ATTACHMENT_BY_ID = "delete claim attachment by id"
export const DELETE_CLAIM_ATTACHMENT_BY_ID_SUCCESS = "delete claim attachment by id success"
export const DELETE_CLAIM_ATTACHMENT_BY_ID_FAILURE = "delete claim attachment by id failure"

export const DELETE_CLAIM_ITEM_ATTACHMENT_BY_ID = "delete claim item attachment by id"
export const DELETE_CLAIM_ITEM_ATTACHMENT_BY_ID_SUCCESS = "delete claim item attachment by id success"
export const DELETE_CLAIM_ITEM_ATTACHMENT_BY_ID_FAILURE = "delete claim item attachment by id failure"

export const SUBMIT_CLAIM_BY_ID = "submit claim by id"
export const SUBMIT_CLAIM_BY_ID_SUCCESS = "submit claim by id success"
export const SUBMIT_CLAIM_BY_ID_FAILURE = "submit claim by id failure"

export const FETCH_CLAIM_ITEMS_LIST = "fetch claim items list"
export const FETCH_CLAIM_ITEMS_LIST_SUCCESS = "fetch claim items list success"
export const FETCH_CLAIM_ITEMS_LIST_FAILURE = "fetch claim items list failure"

export const FETCH_CLAIM_REASONS = "fetch claim reasons"
export const FETCH_CLAIM_REASONS_SUCCESS = "fetch claim reasons success"
export const FETCH_CLAIM_REASONS_FAILURE = "fetch claim reasons failure"

export const FETCH_CLAIM_REASON_TREE = "fetch claim reason tree"
export const FETCH_CLAIM_REASON_TREE_SUCCESS = "fetch claim reason tree success"
export const FETCH_CLAIM_REASON_TREE_FAILURE = "fetch claim reason tree failure"

export const DELETE_CLAIM_REASON_BY_ID = "delete claim reason by id"
export const DELETE_CLAIM_REASON_BY_ID_SUCCESS = "delete claim reason by id success"
export const DELETE_CLAIM_REASON_BY_ID_FAILURE = "delete claim reason by id failure"

export const UPDATE_CLAIM_REASON = "update claim reason"
export const UPDATE_CLAIM_REASON_SUCCESS = "update claim reason success"
export const UPDATE_CLAIM_REASON_FAILURE = "update claim reason failure"

export const GET_SERIEN_NUMBERS = "get serien numbers"
export const GET_SERIEN_NUMBERS_SUCCESS = "get serien numbers success"
export const GET_SERIEN_NUMBERS_FAILURE = "get serien numbers failure"


export const FETCH_CLAIM_HISTORY_BY_CLAIM_ID = "fetch claim history by claim id"
export const FETCH_CLAIM_HISTORY_BY_CLAIM_ID_SUCCESS = "fetch claim history by claim id success"
export const FETCH_CLAIM_HISTORY_BY_CLAIM_ID_FAILURE = "fetch claim history by claim id failure"

export const postClaim = (data) => ({
  type: POST_CLAIM,
  payload: data
});

export const fetchClaimList = (data) => ({
  type: FETCH_CLAIM_LIST,
  payload: data
});

export const fetchClaimById = (data) => ({
  type: FETCH_CLAIM_BY_ID,
  payload: data
});

export const deleteClaimItemById = (data) => ({
  type: DELETE_CLAIM_ITEM_BY_ID,
  payload: data
});

export const setCurrentClaimItem = (data) => ({
  type: SET_CURRENT_CLAIM_ITEM,
  payload: data
});


export const updateClaimItemById = (data) => ({
  type: UPDATE_CLAIM_ITEM_BY_ID,
  payload: data
});

export const updateClaimItem = (data) => ({
  type: UPDATE_CLAIM_ITEM,
  payload: data
});

export const addClaimItemById = (data) => ({
  type: ADD_CLAIM_ITEM_BY_ID,
  payload: data
});


export const setCurrentClaim = (data) => ({
  type: SET_CURRENT_CLAIM,
  payload: data
});

export const deleteClaimAttachmentById = (data) => ({
  type: DELETE_CLAIM_ATTACHMENT_BY_ID,
  payload: data
});

export const deleteClaimItemAttachmentById = (data) => ({
  type: DELETE_CLAIM_ITEM_ATTACHMENT_BY_ID,
  payload: data
});

export const submitClaimById = (data) => ({
  type: SUBMIT_CLAIM_BY_ID,
  payload: data
});

export const fetchClaimItemsList = (data) => ({
  type: FETCH_CLAIM_ITEMS_LIST,
  payload: data
});

export const fetchClaimReasons = (data) => ({
  type: FETCH_CLAIM_REASONS,
  payload: data
});

export const fetchClaimReasonTree = (data) => ({
  type: FETCH_CLAIM_REASON_TREE,
  payload: data
});

export const deleteClaimReasonById = (data) => ({
  type: DELETE_CLAIM_REASON_BY_ID,
  payload: data
});

export const updateClaimReason = (data) => ({
  type: UPDATE_CLAIM_REASON,
  payload: data
});

export const getSerienNumbers = (data) => ({
  type: GET_SERIEN_NUMBERS,
  payload: data
});

export const fetchClaimHistoryByClaimId = (data) => ({
  type: FETCH_CLAIM_HISTORY_BY_CLAIM_ID,
  payload: data
});

