import React, { useEffect, useState, useRef, Fragment } from 'react';
import { Button, Collapse, Modal } from 'react-bootstrap';
import _ from 'lodash'
import { Field, useFormikContext } from 'formik';
import * as Icon from 'react-bootstrap-icons';
import { updateUi } from '../../redux/action/ui.action';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRfqHistory } from '../../redux/action/rfq.action';
import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const RfqDetail = (props) => {
  const { t, i18n } = useTranslation('common');

  const merkmalenChildren = useSelector(state => state.category.merkmalenChildren);

  let details = props.rfq
  return (
    <>
      {details && details.quotationItems &&
        details.quotationItems.map(item => {
          return (
            <table key={"item_" + item.id} className="table table-hover table-job-positions  product-list">
              <thead>
                <tr>
                  <th>Category</th>
                  <th>Model</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                {item["productComposite"] &&
                  <tr>
                    <td style={{ textAlign: "left" }}>{item["productComposite"]["product"]["motorTyp"]}</td>
                    <td></td>
                    <td style={{ textAlign: "right" }}>{item["productComposite"].price ? `${item["productComposite"].price.toFixed(2)} €` : "Auf anfrage"}</td>
                  </tr>
                }

                {merkmalenChildren && item.categoryComposites && merkmalenChildren.map((g, idx) => {
                  return (
                    <Fragment key={`greoup_${idx}`}>
                      <tr><td colSpan={3} style={{ backgroundColor: "lightgray" }}><b>{t(`category_group.name.${_.snakeCase(g.name)}`)}</b></td></tr>
                      {g.subCategories && g.subCategories.map((s, index) => {
                        console.info("ssss", s);
                        return (
                          <Fragment key={"category_check_" + s.id}>
                            {s &&
                              <Fragment >
                                {
                                  item.categoryComposites.filter(cc => cc.category.id === s.id).map(cc => {
                                    return (
                                      <tr key={"cc_" + cc.id}>
                                        <td style={{ textAlign: "left" }}>{t(`category.name.${_.snakeCase(cc.category.name)}`)}</td>
                                        <td>{t(`category_property.name.${_.snakeCase(cc.value)}`)}</td>
                                        <td style={{ textAlign: "right" }}>{cc.price}</td>
                                      </tr>
                                    )
                                  })
                                }
                              </Fragment>
                            }
                          </Fragment>
                        )
                      })
                      }
                    </Fragment>
                  )
                })}


                {item.categoryComposites &&
                  item.categoryComposites.map(cc => {
                    return (
                      <tr key={"cc_" + cc.id}>
                        <td style={{ textAlign: "left" }}>{t(`category.name.${_.snakeCase(cc.category.name)}`)}</td>
                        <td>{t(`category_property.name.${_.snakeCase(cc.value)}`)}</td>
                        <td style={{ textAlign: "right" }}>{cc.price}</td>
                      </tr>
                    )
                  })
                }
                <tr>
                  <td style={{ textAlign: "left" }}>Unit Price</td>
                  <td></td>
                  <td style={{ textAlign: "right" }}>{item.unitPrice > 0 ? `${item.unitPrice.toFixed(2)} €` : "Auf Anfrage"}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left" }}>Quantity</td>
                  <td></td>
                  <td style={{ textAlign: "right" }}>{item.quantity}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left" }}>Total</td>
                  <td></td>
                  <td style={{ textAlign: "right" }}>{item.totalPrice > 0 ? `${item.totalPrice.toFixed(2)} €` : "Auf Anfrage"}</td>
                </tr>
              </tbody>
            </table>
          )
        })}

    </>
  );
}

export default RfqDetail;
