import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";

import Highlight from "../../components/Highlight";
import Loading from "../../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserProfile, updateUser } from "../../../redux/action/user/user.action";
import { updateToken } from "../../../redux/action/auth.action";
import { ErrorMessage, Field, Formik } from "formik";
import { Badge } from "react-bootstrap";
import {useTranslation} from "react-i18next";

export const ProfileComponent = () => {
  const { t, i18n } = useTranslation('common');//国际化
  const dispatch = useDispatch()

  const userProfile = useSelector(state => state.user.profile);
  const { user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    getAccessTokenSilently().then(token => {
      dispatch(updateToken({ token: token }))
      dispatch(fetchUserProfile())
    })
  }, [])

  return (
    <Container className="mb-5 mt-5">
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md={2}>
          <img
            src={user.picture}
            alt="Profile"
            className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
          />
        </Col>
        <Col md>
          <h2>{userProfile.name}</h2>
          <p className="lead text-muted">{userProfile.company || ''}</p>
          <p className="lead text-muted">{userProfile.email}</p>
          <p className="lead text-muted">
            {userProfile.userCustomers &&
              userProfile.userCustomers.map((uc) => {
                return(
                  <Badge key={"user_custoemr_" + uc.id}>{uc.customer.name1}</Badge>
                )
              })
            }
          </p>
        </Col>
      </Row>
      <section className="section">
        <div className="range justify-content-xl-between">
          <div className="cell-xl-12 align-self-center container">
            <Formik
              initialValues={userProfile}
              enableReinitialize={true}
              validate={values => {
                const errors = {};
                if (!values.firstname) {
                  errors["firstname"] = 'Required';
                }
                if (!values.lastname) {
                  errors["lastname"] = 'Required';
                }
                return errors;
              }}
              onSubmit={(values) => {
                dispatch(updateUser({ parameters: values }))
              }}>
              {({ values, errors, handleSubmit, setFieldValue, setValues }) => (
                <form onSubmit={handleSubmit} autoComplete="off" className="">
                  <h3 ><span className="">{t('user.UserInfo')}</span></h3>
                  <div className="row mt0">
                    <div className="col-md-3 ">
                      <div className="form-wrap  fadeSlideInUp">
                        <label className="" htmlFor="firstname">{t('user.Firstname')} <ErrorMessage name="firstname" render={msg => <span className="error">(*{msg})</span>} /> </label>
                        <Field id="firstname" type="text" name="firstname" placeholder="Firstname" className="form-input" value={values.firstname || ''}
                          onChange={(event) => {
                            setFieldValue("firstname", event.currentTarget.value);
                          }} />
                      </div>
                    </div>
                    <div className="col-md-3 ">
                      <div className="form-wrap  fadeSlideInUp">
                        <label className="" htmlFor="lastname">{t('user.Lastname')} <ErrorMessage name="lastname" render={msg => <span className="error">(*{msg})</span>} /> </label>
                        <Field id="lastname" type="text" name="lastname" placeholder="Lastname" className="form-input" value={values.lastname || ''}
                          onChange={(event) => {
                            setFieldValue("lastname", event.currentTarget.value);
                          }} />
                      </div>
                    </div>
                  </div>
              <div className="row mt0">
                  <div className="col-md-3 ">
                  <div className="form-wrap  fadeSlideInUp">
                  <label className="" htmlFor="userType">{t('user.userType')} <ErrorMessage name="userType" render={msg => <span className="error">(*{msg})</span>} /> </label>
              <Field id="userType" type="text" name="userType" placeholder="userType" className="form-input" value={values.userType || ''}
                  onChange={(event) => {
                  setFieldValue("userType", event.currentTarget.value);
              }} />
              </div>
              </div>
              <div className="col-md-3 ">
                  <div className="form-wrap  fadeSlideInUp">
                  <label className="" htmlFor="usTp">{t('user.userdisconunt%fortotalprice')} <ErrorMessage name="usTp" render={msg => <span className="error">(*{msg})</span>} /> </label>
              <Field id="usTp" type="text" name="usTp" placeholder="user disconunt % for total price" className="form-input" value={values.usTp || ''}
                  onChange={(event) => {
                  setFieldValue("usTp", event.currentTarget.value);
              }} />
              </div>
              </div>
              <div className="col-md-3 ">
                  <div className="form-wrap  fadeSlideInUp">
                  <label className="" htmlFor="cuZu">{t('user.CUzuschag%/orotherzuschlag')}<ErrorMessage name="cuZu" render={msg => <span className="error">(*{msg})</span>} /> </label>
              <Field id="cuZu" type="text" name="cuZu" placeholder="CU zuschag % /or other zuschlag" className="form-input" value={values.cuZu || ''}
                  onChange={(event) => {
                  setFieldValue("cuZu", event.currentTarget.value);
              }} />
              </div>
              </div>

              </div>
                  <div className="row row-10">
                    <div className="col-md-12">
                      <button className="button button-primary button-winona" type="submit">{t('user.update')}</button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </section>

      {userProfile?.userCustomers?.length <= 0 &&
        <section className="section mt20">
          <div className="range justify-content-xl-between">
            <div className="cell-xl-12 align-self-center container">
              <Formik
                initialValues={userProfile}
                enableReinitialize={true}
                validate={values => {
                  const errors = {};
                  if (!values.company) {
                    errors["company"] = 'Required';
                  }
                  return errors;
                }}
                onSubmit={(values) => {
                  dispatch(updateUser({ parameters: values }))
                }}>
                {({ values, errors, handleSubmit, setFieldValue, setValues }) => (
                  <form onSubmit={handleSubmit} autoComplete="off" className="">
                    <h3><span className="">{t('user.CompanyInfo')}</span></h3>
                    <div className="row mt0">
                      <div className="col-md-3 ">
                        <div className="form-wrap  fadeSlideInUp">
                          <label className="" htmlFor="company">{t('user.CompanyName')} <ErrorMessage name="company" render={msg => <span className="error">(*{msg})</span>} /> </label>
                          <Field id="company" type="text" name="company" placeholder="Company Name" className="form-input" value={values.company || ''}
                            onChange={(event) => {
                              setFieldValue("company", event.currentTarget.value);
                            }} />
                        </div>
                      </div>
                    </div>
                    <div className="row row-10">
                      <div className="col-md-12">
                        <button className="button button-primary button-winona" type="submit">{t('user.update')}</button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </section>
      }

    </Container>
  );
};

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
});
