import { FETCH_USER_PROFILE_SUCCESS } from "../../action/user/user.action";

const initialState = {
  profile: {
    firstname:"",
    lastname:"",
    company:"",
  }
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_USER_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload
      }
    default:
      return state;
  }
}