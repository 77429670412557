import React, { useEffect, useState } from "react";

import Loading from "../../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { deleteUiRule, fetchRules, resetCurrentEditRule } from "../../../redux/action/rules.action";
import { Fragment } from "react";
import { Button, Collapse, Modal } from 'react-bootstrap';
import SimpleRuleEditor from "../../components/SimpleRuleEditor.Component";
import { fetchCategoyGroupList, fetchCategoyList } from "../../../redux/action/category.action";
import { setShowAddRuleModal } from "../../../redux/action/ui.action";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import { updateToken } from "../../../redux/action/auth.action";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { deleteAufpreisRule, fetchAufpreisRules, resetCurrentEditAufpreisRule } from "../../../redux/action/admin/aufpreisRules.action";
import SimpleAufpreisRuleEditor from "../../components/admin/SimpleAufpreisRuleEditor.Component";
import {useTranslation} from "react-i18next";

const products = [{ id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 2, name: "sadf", price: 234.4 }]

export const AdminAufpreisRulesView = () => {
  const { t, i18n } = useTranslation('common');//国际化
  const dispatch = useDispatch()
  const { user, getAccessTokenSilently } = useAuth0();
  const rules = useSelector(state => state.aufpreis.rules);
  const [token, setToken] = useState('')
  const showAddRuleModal = useSelector(state => state.ui.showAddRuleModal);

  const [currentRule, setCurrentRule] = useState({});

  const { SearchBar } = Search;

  function actionFormatter(cell, row) {
    return (
      <div className="group group-middle">
        <button type="submit" className="button button-sm button-primary-outline button-winona" onClick={(e) => {
          dispatch(setShowAddRuleModal({ show: true }));
          setCurrentRule(row)
        }}
        >{t('admin.Edit')}</button>
        <button type="submit" className="button button-sm button-primary-outline button-winona" onClick={(e) => {
          if (window.confirm('Are you sure you wish to delete this item?')) {
            dispatch(deleteAufpreisRule({ id: row.id }));
          }
        }}
        >{t('admin.Delete')}</button>
      </div>
    );
  }

  function conditionsFormatter(cell, row) {
    return (
      <Fragment key={"rule_" + row.id}>
        <div style={{ backgroundColor: "lightyellow" }}>
          {row.simpleConditions &&
            row.simpleConditions.map((c, index) => {
              return (
                <div key={"condi_" + index}>{c}</div>
              )
            })
          }
        </div>
        {row.priceRuleEvents && row.priceRuleEvents.length > 0 &&
          <table className="table table-hover table-job-positions baugroesse-list" style={{ backgroundColor: "lightcyan", marginTop: "10px" }}>
            <thead><tr><th>{t('admin.Baugröße')}</th><th>{t('admin.Aufpreis')}</th><th>{t('admin.Aufpreis%')}</th></tr></thead>
            <tbody>
              {row.priceRuleEvents &&
                row.priceRuleEvents.map((c) => {
                  return (
                    <tr key={"events_" + c.id}>
                      <td>{c.priceRuleEventParamter.baugroesse}</td>
                      <td>{c.priceRuleEventParamter.aufpreis}</td>
                      <td>{(c.priceRuleEventParamter.aufpreisProzent * 100.0).toFixed(2)} %</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        }
      </Fragment>
    );
  }

  function leadTimeWeeksFormatter(cell, row) {
    return (
      <Fragment key={"rule_" + row.id}>
        {row.priceRuleEvents && row.priceRuleEvents.length > 0 &&
          <>
            {row.priceRuleEvents.filter(c => c.priceRuleEventParamter.eventType === 'ADD_LEADTIME').map((c) => {
              return (
                <Fragment key={"events_" + c.id}>
                  <>{c.priceRuleEventParamter.leadTimeWeeks}</>
                </Fragment>
              )
            })
            }
          </>
        }
      </Fragment>
    );
  }

  const columns = [{
    dataField: 'id',
    text: 'ID',
    sort: true,
    headerClasses: 'width-procent-10'
  }, {
    dataField: 'name',
    text: 'Rule Name',
    sort: true,
    headerClasses: 'width-procent-20'
  },
  {
    dataField: 'condition',
    text: 'Conditions & Events',
    formatter: conditionsFormatter,
    filterValue: (cell, row) => {
      let ret = ""
      ret = row.simpleConditions.map((c, index) => c)
      return ret;
    }
  },
  {
    dataField: 'leadTimeWeeks',
    text: 'Leadtime Weeks',
    formatter: leadTimeWeeksFormatter,
  },
  {
    dataField: 'action',
    text: 'Action',
    formatter: actionFormatter,
    headerClasses: 'width-procent-20'
  }];


  useEffect(() => {
    getAccessTokenSilently().then(token => {
      dispatch(updateToken({ token: token }))
      dispatch(fetchAufpreisRules())
      dispatch(fetchCategoyList({ token: token }))
      dispatch(fetchCategoyGroupList({ token: token }))
    })
  }, [])

  const options = {
    custom: true,
    totalSize: rules.length
  };

  return (
    <>
      <Modal show={showAddRuleModal} size="xl" dialogClassName="modal-details">
        <Modal.Header>
          <Modal.Title>{t('admin.Reklamation')}Add new Rule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SimpleAufpreisRuleEditor ruleId={currentRule.id ? currentRule.id : ""} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="button button-icon button-icon-left button-winona" onClick={(e) => {
            dispatch(setShowAddRuleModal({ show: false }));
          }}>
            {t('admin.Reklamation')}Close
          </Button>
        </Modal.Footer>
      </Modal>

      <section className="section section-lg text-center">
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <div className="group group-middle">
                <button type="button" className="button" onClick={(e) => {
                  e.preventDefault();
                  setCurrentRule({})
                  dispatch(resetCurrentEditAufpreisRule())
                  dispatch(setShowAddRuleModal({ show: true }));
                }}
                >{t('admin.Reklamation')}Add new rule</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {rules && rules.length > 0 &&
        <section className="section section-lg text-center">
          <div className="container">
            <PaginationProvider
              pagination={paginationFactory(options)}
            >
              {
                ({
                  paginationProps,
                  paginationTableProps
                }) => (
                  <div>
                    <PaginationListStandalone
                      {...paginationProps}
                    />
                    <ToolkitProvider
                      bootstrap4
                      keyField="id"
                      data={rules}
                      columns={columns}
                      search
                    >
                      {
                        toolkitprops => (
                          <div>
                            <div style={{ float: "left", padding: "10px 0px" }}>
                              <SearchBar {...toolkitprops.searchProps} style={{ float: "left" }} placeholder="Search Rule Name / ID" />
                            </div>
                            <BootstrapTable
                              striped
                              hover
                              {...toolkitprops.baseProps}
                              {...paginationTableProps}
                            />
                          </div>
                        )
                      }
                    </ToolkitProvider>
                    <PaginationListStandalone
                      {...paginationProps}
                    />
                  </div>
                )
              }
            </PaginationProvider>
          </div>
        </section>
      }
    </>
  );
};

export default withAuthenticationRequired(AdminAufpreisRulesView, {
  onRedirecting: () => <Loading />,
});
