import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
.use(Backend)
.use(LanguageDetector)
.use(initReactI18next)
.init({
  debug: false,
  interpolation: { escapeValue: false },  // React already does escaping
  fallbackLng: 'de',                           // language to use
  resources: {
    en: {
      common: {}               // 'common' is our custom namespace
    },
    de: {
      common: {}
    },
  },
});

export default i18n;
