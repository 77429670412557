import { FETCH_COUNTRY_GROUP_CATEGORY_SUCCESS, FETCH_COUNTRY_GROUP_SUCCESS } from "../action/country.action";

const initialState = {
  groups: [],
  targetCountyGroupCategory:{},
  countryGroupMap: {}
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_COUNTRY_GROUP_SUCCESS:
      let map = {}
      action.payload.forEach(g => {
        g.countries.forEach(c=>{
          map[c.a2]=g.name
        })
      });
      return {
        ...state,
        groups: action.payload,
        countryGroupMap : map
      }
    case FETCH_COUNTRY_GROUP_CATEGORY_SUCCESS:
      return {
        ...state,
        targetCountyGroupCategory: action.payload
      }
    default:
      return state;
  }
}