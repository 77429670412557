import { apiRequest } from "../action/api.action"
import { SEARCH_PRODUCT_PRICE, SEARCH_PRODUCT_PRICE_FAILURE, SEARCH_PRODUCT_PRICE_SUCCESS } from "../action/product-price.action";
import querystring from 'querystring'
import { getHost } from "../../react/utils/host"
import { ADD_UI_RULE, ADD_UI_RULE_FAILURE, ADD_UI_RULE_SUCCESS, COPY_UI_RULE, COPY_UI_RULE_FAILURE, COPY_UI_RULE_SUCCESS, DELETE_UI_RULE, DELETE_UI_RULE_FAILURE, DELETE_UI_RULE_SUCCESS, FETCH_ALL_RULES, FETCH_EDIT_RULE_BY_ID, FETCH_EDIT_RULE_BY_ID_FAILURE, FETCH_EDIT_RULE_BY_ID_SUCCESS, FETCH_RULES, FETCH_RULES_FAILURE, FETCH_RULES_SUCCESS } from "../action/rules.action";

export const rulesFlow = ({ dispatch }) => next => action => {
  next(action)
  if (action.type === FETCH_RULES || action.type === ADD_UI_RULE_SUCCESS || action.type === DELETE_UI_RULE_SUCCESS || action.type === COPY_UI_RULE_SUCCESS ) {
    if (action.token) {
      action.payload["token"] = action.token
    }
    let url = `${getHost()}/api/v1/ui-rules`
    dispatch(apiRequest("GET", url, action.payload, FETCH_RULES_SUCCESS, FETCH_RULES_FAILURE));
  } 
  
  if (action.type === FETCH_ALL_RULES ) {
    if (action.token) {
      action.payload["token"] = action.token
    }
    let url = `${getHost()}/admin-api/v1/ui-rules`
    dispatch(apiRequest("GET", url, action.payload, FETCH_RULES_SUCCESS, FETCH_RULES_FAILURE));
  }
  
  if (action.type === ADD_UI_RULE) {
    let url = `${getHost()}/admin-api/v1/ui-rules`
    dispatch(apiRequest("POST", url, action.payload, ADD_UI_RULE_SUCCESS, ADD_UI_RULE_FAILURE));
  } else if (action.type === DELETE_UI_RULE) {
    let url = `${getHost()}/admin-api/v1/ui-rules/${action.payload.id}`
    dispatch(apiRequest("DELETE", url, action.payload, DELETE_UI_RULE_SUCCESS, DELETE_UI_RULE_FAILURE));
  } 

  if (action.type === COPY_UI_RULE) {
    let url = `${getHost()}/admin-api/v1/ui-rules/${action.payload.id}/copy`
    dispatch(apiRequest("GET", url, action.payload, COPY_UI_RULE_SUCCESS, COPY_UI_RULE_FAILURE));
  } 
  
  if (action.type === FETCH_EDIT_RULE_BY_ID) {
    let url = `${getHost()}/api/v1/ui-rules/${action.payload.id}`
    dispatch(apiRequest("GET", url, action.payload, FETCH_EDIT_RULE_BY_ID_SUCCESS, FETCH_EDIT_RULE_BY_ID_FAILURE));
  }
};

export const rulesMdl = [rulesFlow];