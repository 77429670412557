import React, { Fragment, useEffect, useState } from "react";
import _ from 'lodash'
import { useDispatch, useSelector } from "react-redux";
import { fetchClaimList, postClaim, setCurrentClaim } from "../../redux/action/claim.action";
import { useHistory } from "react-router-dom";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { updateToken } from "../../redux/action/auth.action";
import Loading from "../components/Loading";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import moment from 'moment';
import { Button, Modal } from "react-bootstrap";
import ClaimHistory from "../components/internal/ClaimHistory";
import {useTranslation} from "react-i18next";

const ClaimsView = (props) => {
  const { t, i18n } = useTranslation('common');//国际化
  const dispatch = useDispatch();
  const redirect = useSelector(state => state.ui.redirect);
  let history = useHistory();
  const { user, getAccessTokenSilently } = useAuth0();

  const claims = useSelector(state => state.claims.list);
  const [show, setShow] = useState(false);
  const [currentClaim, setCurrentClaim] = useState({})

  useEffect(() => {
    getAccessTokenSilently().then(token => {
      dispatch(updateToken({ token: token }))
      dispatch(fetchClaimList({ token: token, status: _.upperCase(props.match.params.status) }))
    })
  }, [])


  const { SearchBar } = Search;

  function actionFormatter(cell, row) {
    return (
      <div className="group group-middle">
        <a href={"/digicat/claim/view?&id=" + row.id}>
          <i className="icon icon-sm link-default mdi mdi-eye"></i>
        </a>
        {["DRAFT"].includes(row.status) && row.user.sub === user.sub &&
          <a href={"/digicat/claim/edit?&id=" + row.id}>
            <i className="icon icon-sm link-default mdi mdi-pencil"></i>
          </a>
        }
        {row.pdfPath &&
          <>
            <a href={`/digicat/atb-attachments/claims/${row.pdfPath}`} target="_blank">
              <i className="icon icon-sm link-default fa fa-file-pdf-o"></i>
            </a>
            <a href={`/digicat/atb-attachments/claims/${row.pdfPath}`} download={`claim_${row.id}.pdf`} target="_blank">
              <i className="icon icon-sm link-default mdi mdi-download"></i>
            </a>
          </>
        }
        <i className="icon icon-sm link-default mdi mdi-history pointer" onClick={() => {
          setCurrentClaim(row)
          setShow(true)
        }}></i>
      </div>
    );
  }

  function conditionsFormatter(cell, row) {
    return (
      <Fragment key={"rule_" + row.id}>
        {row.properties &&
          row.properties.map(function (elem) {
            return elem.name;
          }).join("; ")
        }
      </Fragment>
    );
  }

  function groupInfoFormatter(cell, row) {
    return (
      <Fragment>
        {t('home.Name')}: {row.kundenname}<br />
        {t('home.Kundenummer')}: {row.kundennummer}<br />
        {t('home.LieferscheinNr')}: {row.lieferscheinNr}
      </Fragment>
    );
  }

  function statusInfoFormatter(cell, row) {
    return (
      <Fragment>
        {t('home.Status')}: {row.status}<br />
        {t('home.SubmitDate')}: {row.submitDate ? moment(row.submitDate).format('YYYY-MM-DD') : ""}<br />
        {t('home.Createdby')}: {row.user?.name}<br />
        {t('home.Createdat')}: {row.creationDate ? moment(row.creationDate).format('YYYY-MM-DD') : ""}
      </Fragment>
    );
  }

  const columns = [{
    dataField: 'id',
    text: 'ID',
    sort: true,
    headerClasses: 'width-procent-10'
  }, {
    dataField: 'name',
    text: 'Claim',
    sort: true,
    formatter: groupInfoFormatter,
    headerClasses: 'width-procent-20'
  }, {
    dataField: 'bemerkung',
    text: 'Bemerkung',
  }, {
    dataField: 'status',
    text: 'Status',
    formatter: statusInfoFormatter,
  }, {
    dataField: 'action',
    text: 'Action',
    formatter: actionFormatter,
    headerClasses: 'width-procent-20'
  }];


  const options = {
    custom: true,
    totalSize: claims.length
  };

  return (
    <Fragment>

      <Modal show={show} size="xl" dialogClassName="modal-details">
        <Modal.Header>
          <Modal.Title>{t('home.ClaimHistory')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ClaimHistory claimId={currentClaim.id} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="button button-icon button-icon-left button-winona" onClick={(e) => {
            setShow(false);
          }}>
            {t('home.Close')}
          </Button>
        </Modal.Footer>
      </Modal>

      <section className="section section-lg text-center">
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <div className="group group-middle">
                <a href="/digicat/claim/edit">
                  <button type="button" className="button" >{t('home.Addnewclaim')}</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {claims && claims.length > 0 &&
        <section className="section section-lg text-center">
          <div className="container">
            <PaginationProvider
              pagination={paginationFactory(options)}
            >
              {
                ({
                  paginationProps,
                  paginationTableProps,
                }) => (
                  <div>
                    <PaginationListStandalone
                      {...paginationProps}
                    />
                    <ToolkitProvider
                      bootstrap4
                      keyField="id"
                      data={claims}
                      columns={columns}
                      search
                    >
                      {
                        toolkitprops => (
                          <div>
                            <div style={{ float: "left", padding: "10px 0px" }}>
                              <SearchBar {...toolkitprops.searchProps} style={{ float: "left" }}/>
                            </div>
                            <BootstrapTable
                              striped
                              hover
                              {...toolkitprops.baseProps}
                              {...paginationTableProps}
                            />
                          </div>
                        )
                      }
                    </ToolkitProvider>
                    <PaginationListStandalone
                      {...paginationProps}
                    />
                  </div>

                )
              }
            </PaginationProvider>
          </div>
        </section>
      }

    </Fragment>
  )
}

export default withAuthenticationRequired(ClaimsView, {
  onRedirecting: () => <Loading />,
});
