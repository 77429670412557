import { apiRequest } from "../../action/api.action"
import { getHost } from "../../../react/utils/host"
import { FETCH_ALL_ORDERS, FETCH_ALL_ORDERS_FAILURE, FETCH_ALL_ORDERS_SUCCESS } from "../../action/internal/internal-orders.action";

export const internalOrdersFlow = ({ dispatch }) => next => action => {
  next(action)

  if (action.type === FETCH_ALL_ORDERS) {
    let url = `${getHost()}/internal-api/v1/orders`
    dispatch(apiRequest("GET", url, null, FETCH_ALL_ORDERS_SUCCESS, FETCH_ALL_ORDERS_FAILURE));
  }
};

export const internalOrdersMdl = [internalOrdersFlow];