import React, { useEffect, useState, useRef } from 'react';
import { Button, Collapse, Modal, Table } from 'react-bootstrap';
import _ from 'lodash'
import { ErrorMessage, Field, Formik, useFormikContext } from 'formik';
import * as Icon from 'react-bootstrap-icons';
import { updateUi } from '../../redux/action/ui.action';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRfqHistory } from '../../redux/action/rfq.action';
import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';
import { JSONEditor } from '@json-editor/json-editor'
import { Fragment } from 'react';
import { Provider } from 'react-redux';
import { store } from '../../index'
import ReactDOM from 'react-dom';
import { addProperty, addToCategoryGroup, addTreeNode, deleteCategoryTreeNode, deleteProperty, deleteTreeNodeById, exchangeCategoryPropertyOrder, exchangeCategoryTreeNodeOrder, fetchCategoryGroups, fetchCategoyGroupList, fetchChildCategories, fetchChildCategoryTree, fetchCurrentEditCategory, fetchParentCategoryCandidates, fetchSapMerkmalList, resetCurrentEditCategory, updateCategory } from '../../redux/action/category.action';
import { updateToken } from '../../redux/action/auth.action';
import EditProperty from './admin/EditProperty.Component';
import { Editor } from '@tinymce/tinymce-react';

const EditCategory = (props) => {
  const dispatch = useDispatch()
  let currentEditCategory = useSelector(state => state.category.currentEditCategory);
  let parentCandidates = useSelector(state => state.category.parentCandidates);
  let childrenTree = useSelector(state => state.category.childrenTree);
  const { user, getAccessTokenSilently } = useAuth0();
  let groupList = useSelector(state => state.category.groupList);
  let sapMerkmal = useSelector(state => state.category.sapMerkmal);
  const [currentSelectedGroupId, setCurrentSelectedGroupId] = useState('');

  const [showEditPropertyModal, setShowEditPropertyModal] = useState(false)

  const [currentEditProperty, setCurrentEditProperty] = useState({});
  const editorRef = useRef(null);
  const jwtToken = useSelector(state => state.auth.jwtToken);

  const getToken = () => {
    return jwtToken;
  }

  useEffect(() => {
    if (props.category.id ){
      dispatch(fetchCurrentEditCategory({ id: props.category.id}))
    } else {
      dispatch(resetCurrentEditCategory())
    }
  }, [props.category])

  useEffect(() => {
    dispatch(fetchParentCategoryCandidates())
    dispatch(fetchCategoyGroupList())
    dispatch(fetchSapMerkmalList())
  }, [])
  
  return (
    <>

      <Modal show={showEditPropertyModal} size="xl" dialogClassName="modal-details">
        <Modal.Header>
          <Modal.Title>Edit Property</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditProperty property = {currentEditProperty} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="button button-icon button-icon-left button-winona" onClick={(e) => {
            setShowEditPropertyModal(false);
          }}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Formik
        initialValues={currentEditCategory}
        enableReinitialize={true}
        validate={values => {
          const errors = {};
          return errors;
        }}
        onSubmit={(values) => {
          dispatch(updateCategory({parameters:values}))
        }}>
        {({ values, errors, handleSubmit, setFieldValue, setValues }) => (
          <form onSubmit={handleSubmit} autoComplete="off" className="rd-form rd-mailform">
            <h3>Basic Data</h3>
            { values.id &&
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="id">Id</label>
                    <Field id="id" type="text" name="id" disabled className="form-control" value={values.id || ''}
                    />
                  </div>
                </div>
              </div>
            }
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="name">Category Name(Required)<ErrorMessage name="name" render={msg => <span className="error">(*{msg})</span>} /></label>
                  <Field id="name" type="text" name="name" placeholder="Category Name" className="form-control" value={values.name || ''}
                    onChange={(event) => {
                      setFieldValue("name", event.currentTarget.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label className="form-label-outside" htmlFor="categoryType">Category Type</label>
                <select className="form-control select" name="categoryType" value={values.categoryType || ""} 
                  onChange={(event) => {
                    setFieldValue('categoryType', event.currentTarget.value)
                  }}
                >
                  <option value="">please choose</option>
                  <option value='DROPDOWN'>Dropdown Menu</option>
                  <option value='PLACEHOLDER'>Placeholder</option>
                  <option value='FREE_TEXT'>Free Text</option>
                  <option value='CHECKBOX'>Checkbox</option>
                  <option value='COLOR_DROPDOWN'>Dropdown Color</option>
                </select>
              </div>
              <div className="col-md-6">
                <label className="form-label-outside" htmlFor="categoryGroupId">Category Group</label>
                <select className="form-control select" name="categoryGroupId" value={values.categoryGroupId || ""} 
                  onChange={(event) => {
                    setFieldValue('categoryGroupId', event.currentTarget.value)
                  }}
                >
                  <option value="">please choose</option>
                  {
                    groupList.map((p, index) => {
                      return (
                        <option key={"group_" + p.id} value={p.id}>{p.name}</option>
                      )
                    })
                  }
                </select>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="tooltip">Tooltip</label>
                  <Field id="tooltip" as="textarea" rows="4" name="tooltip" className="form-control" value={values.tooltip || ''}
                    onChange={(event) => {
                      setFieldValue("tooltip", event.currentTarget.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="sapMerkmal">SAP Merkmal</label>
                  <select className="form-control select" name="sapMerkmal" value={values.sapMerkmal || ""} 
                    onChange={(event) => {
                      setFieldValue('sapMerkmal', event.currentTarget.value)
                    }}
                  >
                    <option value="">please choose</option>
                    {
                      sapMerkmal.map((p, index) => {
                        return (
                          <option key={"sapMerkmal_" + p.id} value={p.atnam}>{p.atbez} ({p.atnam})</option>
                        )
                      })
                    }
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="value">Value</label>
                  <Field id="value" type="text" name="value" placeholder="value"  className="form-control" value={values.value || ''}
                    onChange={(event) => {
                      setFieldValue("value", event.currentTarget.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="einheit">Einheit</label>
                  <Field id="einheit" type="text" name="einheit" placeholder="Einheit"  className="form-control" value={values.einheit || ''}
                    onChange={(event) => {
                      setFieldValue("einheit", event.currentTarget.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <button type="submit" className="button button-primary">{values.id?"Update":"Create"}</button>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>

      { currentEditCategory && currentEditCategory.properties &&
        <>
          <Formik
            initialValues={{name:""}}
            enableReinitialize={true}
            validate={values => {
              const errors = {};
              return errors;
            }}
            onSubmit={(values) => {
              dispatch(addProperty({categoryId:currentEditCategory.id, parameters:values}))
            }}>
            {({ values, errors, handleSubmit, setFieldValue, setValues }) => (
              <form onSubmit={handleSubmit} autoComplete="off" className="rd-form rd-mailform">
                <hr />
                <h3>Properties</h3>
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="name">Property Name</label>
                    <Field id="name" type="text" name="name" placeholder="Property Name" className="form-control" value={values.name || ''}
                      onChange={(event) => {
                        setFieldValue("name", event.currentTarget.value);
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="listOrder">Property List Order</label>
                    <Field id="listOrder" type="text" name="listOrder" placeholder="Property List Order" className="form-control" value={values.listOrder || ''}
                      onChange={(event) => {
                        setFieldValue("listOrder", event.currentTarget.value);
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="counter">Counter</label>
                    <Field id="counter" type="text" name="counter" placeholder="counter" className="form-control" value={values.counter || ''}
                      onChange={(event) => {
                        setFieldValue("counter", event.currentTarget.value);
                      }}
                    />
                  </div>
                </div>
                <button type="submit" className="button button-primary">Add new property</button>
              </form>
            )}
          </Formik>

          <table className="table table-default">
            <thead><tr><th>id</th><th>name</th><th>list order</th><th>Counter</th><th></th></tr></thead>
            <tbody>
              { currentEditCategory.properties.map((p, index)=>{
                  return(
                    <tr key={"propertie_" + p.id}>
                      <td>{p.id}</td>
                      <td>{p.name}</td>
                      <td>{p.listOrder}</td>
                      <td>{p.counter}</td>
                      <td>
                        <div className="group group-middle">
                          <button type="button" onClick={(e) => {
                            e.preventDefault();
                            setCurrentEditProperty(p)
                            setShowEditPropertyModal(true);
                          }}
                          >Edit</button>
                           <button type="submit" onClick={(e) => {
                            if (window.confirm('Are you sure you wish to delete this item?')) {
                              dispatch(deleteProperty({category_id:currentEditCategory.id, id: p.id }));
                            }
                          }}
                          >Delete</button>
                          { index !== 0 &&
                            <button type="submit" onClick={(e) => {
                                dispatch(exchangeCategoryPropertyOrder({parameters: {categoryId:currentEditCategory.id, categoryPropertyId:p.id , up: true}}));
                              }}
                              ><span className="icon mdi mdi-arrow-up"></span></button>
                          }
                          { index !==  currentEditCategory.properties.length - 1 &&
                            <button type="submit" onClick={(e) => {
                                dispatch(exchangeCategoryPropertyOrder({parameters: {categoryId:currentEditCategory.id, categoryPropertyId:p.id, up: false}}));
                              }}
                            ><span className="icon mdi mdi-arrow-down"></span></button>
                          }
                        </div>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </>
      }

      { currentEditCategory && currentEditCategory.id &&
        <Formik
          initialValues={{parentCategoryId:"", listOrder :"", categoryId:currentEditCategory.id}}
          enableReinitialize={true}
          validate={values => {
            const errors = {};
            return errors;
          }}
          onSubmit={(values) => {
            dispatch(addTreeNode({categoryId:values.parentCategoryId, parameters:values}))
          }}>
          {({ values, errors, handleSubmit, setFieldValue, setValues }) => (
            <form onSubmit={handleSubmit} autoComplete="off" className="rd-form rd-mailform">
              <hr />
              <h3>Tree position</h3>
              <div className="row">
                <div className="col-md-12">
                  { currentEditCategory.parentCategoryTrees.length > 0 &&
                    <table className="table table-default">
                      <thead>
                        <tr><th>Id</th><th>Parent Name</th><th></th></tr>
                      </thead>
                      <tbody>
                        { currentEditCategory.parentCategoryTrees.map(t => {
                            return(
                              <tr key={"tree_" + t.id}>
                                <td>{t.id}</td>
                                <td>{t.parent ? t.parent.name: ""}</td>
                                <td>
                                  <button type="button" className="button button-sm button-primary-outline button-winona" onClick={(e) => {
                                    if (window.confirm('Are you sure you wish to delete this item?')) {
                                      dispatch(deleteTreeNodeById({categoryId:currentEditCategory.id, categoryTreeId: t.id }));
                                    }
                                  }}
                                  >Delete</button>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  }
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                <label className="form-label-outside" htmlFor="contact-2-first-name">Parent Category</label>
                <select className="form-control select" name="find-job-location" value={values.parentCategoryId || ""} data-minimum-results-for-search="Infinity" 
                  onChange={(event) => {
                    setFieldValue('parentCategoryId', event.currentTarget.value)
                    dispatch(fetchChildCategoryTree({categoryId: event.currentTarget.value}))
                  }}
                >
                  <option value="">please choose</option>
                  {
                    parentCandidates.map((p, index) => {
                      return (
                        <option key={"parent_" + p.id} value={p.id}>{p.name} - ({p.id})</option>
                      )
                    })
                  }
                </select>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="listOrder">List Order</label>
                    <Field id="listOrder" type="text" name="listOrder" placeholder="List Order" className="form-control" value={values.listOrder || ''}
                      onChange={(event) => {
                        setFieldValue("listOrder", event.currentTarget.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <button type="submit" className="button button-primary">Add Categoy to Tree</button>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      }

      { childrenTree && childrenTree.length > 0 &&
        <table className="table table-default">
          <thead><tr><th>id</th><th>name</th><th>list order</th><th></th></tr></thead>
          <tbody>
            { childrenTree.map((p, index)=>{
                return(
                  <tr key={"childrent_tree_" + p.id}>
                    <td>{p.id}</td>
                    <td>{p.category.name}</td>
                    <td>{p.listOrder}</td>
                    <td>
                      <div className="group group-middle">
                        { index !== 0 &&
                          <button type="submit" className="button button-sm button-primary-outline button-winona" onClick={(e) => {
                              dispatch(exchangeCategoryTreeNodeOrder({parameters: {categoryTreeId:p.id , up: true}}));
                            }}
                            ><span className="icon mdi mdi-arrow-up"></span></button>
                        }
                        { index !==  childrenTree.length - 1 &&
                          <button type="submit" className="button button-sm button-primary-outline button-winona" onClick={(e) => {
                              dispatch(exchangeCategoryTreeNodeOrder({parameters: {categoryTreeId:p.id, up: false}}));
                            }}
                          ><span className="icon mdi mdi-arrow-down"></span></button>
                        }
                        <button type="submit" className="button button-sm button-primary-outline button-winona" onClick={(e) => {
                            if (window.confirm('Are you sure you wish to delete this item?')) {
                              dispatch(deleteCategoryTreeNode({categoryTreeId:p.id}));
                            }
                          }}
                        >Delete</button>
                      </div>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      }
    </>
  );
}

export default EditCategory;
