import React, { useEffect, useState, useRef } from 'react';
import { Button, Collapse, Modal, Table } from 'react-bootstrap';
import _ from 'lodash'
import { ErrorMessage, Field, Formik, useFormikContext } from 'formik';
import * as Icon from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserClaimItemById } from '../../../redux/action/user/claim.action';

import Dropzone from 'react-dropzone-uploader'
import { v4 as uuidv4 } from 'uuid';
import { getHost } from "../../utils/host";
import "../../styles/dropzone_style.css"
import '../../styles/suggestions_style.css'
import { deleteClaimItemAttachmentById, setCurrentClaim } from '../../../redux/action/claim.action';

const EditUserClaimItem = ({ setShow, addOrUpdateItem }) => {
  const dispatch = useDispatch()
  let currentClaimItem = useSelector(state => state.userClaims.currentItem);
  let currentClaim = useSelector(state => state.userClaims.current);
  const token = useSelector(state => state.auth.token);
  const reasonTree = useSelector(state => state.reasons.tree);

  return (
    <>
      <Formik
        initialValues={currentClaimItem}
        enableReinitialize={true}
        validate={values => {
          const errors = {};
          return errors;
        }}
        onSubmit={(values) => {
          addOrUpdateItem(values)
          setShow(false)
        }}>
        {({ values, errors, handleSubmit, setFieldValue, setValues }) => (
          <form onSubmit={handleSubmit} autoComplete="off" className="rd-form rd-mailform">
            <div className="row row-10">
              {currentClaimItem.id &&
                <>
                  <div className="col-md-6 ">
                    <div className="form-wrap wow fadeSlideInUp">
                      <label className="form-label-outside" htmlFor="ID">ID</label>
                      <Field disabled id="ID" type="text" name="ID" placeholder="ID" className="form-input" value={values.id || ''} />
                    </div>
                  </div>
                  <div className="col-md-6 "></div>
                </>
              }
              <div className="col-md-6 ">
                <div className="form-wrap wow fadeSlideInUp">
                  <label className="form-label-outside" htmlFor="Artike Nr">Artike Nr</label>
                  <Field id="Artike Nr" type="text" name="Artike Nr" placeholder="Artike Nr" className="form-input" value={values.artikeNr || ''}
                    onChange={(event) => {
                      setFieldValue("artikeNr", event.currentTarget.value);
                    }} />
                </div>
              </div>
              <div className="col-md-6 ">
                <div className="form-wrap wow fadeSlideInUp">
                  <label className="form-label-outside" htmlFor="menge">Menge</label>
                  <Field id="menge" type="text" name="menge" placeholder="Menge" className="form-input" value={values.menge || ''}
                    onChange={(event) => {
                      setFieldValue("menge", event.currentTarget.value);
                    }} />
                </div>
              </div>
              <div className="col-md-12 ">
                <div className="form-wrap ">
                  <label className="form-label-outside" htmlFor="Reasons">Reasons <ErrorMessage name="reasons" render={msg => <span className="error">(*{msg})</span>} /> </label>
                  {reasonTree &&
                    reasonTree.map((r, index) => {
                      return (
                        <div key={"reason_r_" + index}>
                          <b>{r.name}</b>:
                          {r.children.map((c, index) => {
                            return (
                              <label key={"cp_" + c.id} className='pl10'>
                                <Field className="mr5" type="checkbox" name="reasonIds" value={c.id.toString()} />
                                {c.name}
                              </label>
                            )
                          })
                          }
                        </div>
                      )
                    })
                  }
                </div>
              </div>
              <div className="col-md-12 "></div>

              <div className="col-md-6">
                <Dropzone
                  inputContent="Images Upload"
                  accept="image/*"
                  getUploadParams={({ file, meta }) => {
                    const uuid = uuidv4();
                    const body = new FormData()
                    body.append('uuid', uuid)
                    body.append('file', file)
                    body.append('type', 'IMAGE')
                    meta["uuid"] = uuid;
                    let headers = []
                    headers["Authorization"] = `Bearer ${token}`
                    let url = `${getHost()}/user-api/v1/claims/upload-attachment/${uuid}`
                    if (values.id) {
                      url = `${getHost()}/user-api/v1/claims/${currentClaim.id}/item/${values.id}/upload-attachment/${uuid}`
                    }
                    return { url: url, headers, body }
                  }}
                  onChangeStatus={({ meta, file, remove, xhr }, status) => {
                    if (status === "done") {
                      file["uuid"] = meta.uuid
                      file["file-type"] = 'IMAGE'
                      file["file-name"] = meta.name
                      let response = JSON.parse(xhr.response)
                      file["file-path"] = response.meta["file-path"]

                      if (response.meta["id"]) {
                        let uploadFiles = values.images
                        uploadFiles.push(file)
                        setFieldValue("images", uploadFiles);
                        remove();
                      } else {
                        let uploadFiles = values.tempImages||[]
                        uploadFiles.push(file)
                        setFieldValue("tempImages", uploadFiles);
                        remove();
                      }
                    }
                  }}
                  accept="image/*"
                />
                {values.tempImages && values.tempImages.length > 0 &&
                  <table className="table table-default">
                    <thead></thead>
                    <tbody>
                      {
                        values.tempImages.map((attachment, index) => {
                          return (
                            <tr key={"temp_attachment_" + attachment["uuid"]}>
                              <td>{attachment["file-name"]}</td>
                              <td>
                                <i className="icon icon-sm link-default mdi mdi-delete pointer" onClick={() => {
                                  if (window.confirm('Are you sure you wish to delete this item?')) {
                                    let uploadFiles = values.tempImages
                                    uploadFiles.splice(index, 1);
                                    setFieldValue("tempImages", uploadFiles);
                                  }
                                }}></i>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                }
                {values.images && values.images.length > 0 &&
                  <table className="table table-default">
                    <thead></thead>
                    <tbody>
                      {
                        values.images.map((attachment, index) => {
                          return (
                            <tr key={"attachment_" + attachment["uuid"]}>
                              <td>{attachment["file-name"]}</td>
                              <td>
                                <i className="icon icon-sm link-default mdi mdi-delete pointer" onClick={() => {
                                  if (window.confirm('Are you sure you wish to delete this item?')) {
                                    dispatch(deleteClaimItemAttachmentById({ id: currentClaim.id, claimItemId: currentClaimItem.id, claimItemAttachmentId: attachment.id }))
                                    let uploadFiles = values.images
                                    uploadFiles.splice(index, 1);
                                    setFieldValue("images", uploadFiles);
                                  }
                                }}></i>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                }
              </div>

              <div className="col-md-6">
                <Dropzone
                  inputContent="Attachments Upload"
                  getUploadParams={({ file, meta }) => {
                    const uuid = uuidv4();
                    const body = new FormData()
                    body.append('uuid', uuid)
                    body.append('file', file)
                    body.append('type', 'ATTACHMENT')
                    meta["uuid"] = uuid;
                    let headers = []
                    headers["Authorization"] = `Bearer ${token}`
                    let url = `${getHost()}/user-api/v1/claims/upload-attachment/${uuid}`
                    if (values.id) {
                      url = `${getHost()}/user-api/v1/claims/${currentClaim.id}/item/${values.id}/upload-attachment/${uuid}`
                    }
                    return { url: url, headers, body }
                  }}
                  onChangeStatus={({ meta, file, remove, xhr }, status) => {
                    if (status === "done") {
                      file["uuid"] = meta.uuid
                      file["file-type"] = 'ATTACHMENT'
                      file["file-name"] = meta.name
                      let response = JSON.parse(xhr.response)
                      file["file-path"] = response.meta["file-path"]
                      if (response.meta["id"]) {
                        let uploadFiles = values.attachments
                        uploadFiles.push(file)
                        setFieldValue("attachments", uploadFiles);
                        remove();
                      } else {
                        let uploadFiles = values.tempAttachments||[]
                        uploadFiles.push(file)
                        setFieldValue("tempAttachments", uploadFiles);
                        remove();
                      }
                    }
                  }}
                  accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                />


                {values.tempAttachments && values.tempAttachments.length > 0 &&
                  <table className="table table-default">
                    <thead></thead>
                    <tbody>
                      {
                        values.tempAttachments.map((attachment, index) => {
                          return (
                            <tr key={"temp_attachment_" + attachment["uuid"]}>
                              <td>{attachment["file-name"]}</td>
                              <td>
                                <i className="icon icon-sm link-default mdi mdi-delete pointer" onClick={() => {
                                  if (window.confirm('Are you sure you wish to delete this item?')) {
                                    let uploadFiles = values.tempAttachments
                                    uploadFiles.splice(index, 1);
                                    setFieldValue("tempAttachments", uploadFiles);
                                  }
                                }}></i>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                }
                {values.attachments && values.attachments.length > 0 &&
                  <table className="table table-default">
                    <thead></thead>
                    <tbody>
                      {
                        values.attachments.map((attachment, index) => {
                          return (
                            <tr key={"attachment_" + attachment["uuid"]}>
                              <td>{attachment["file-name"]}</td>
                              <td>
                                <i className="icon icon-sm link-default mdi mdi-delete pointer" onClick={() => {
                                  if (window.confirm('Are you sure you wish to delete this item?')) {
                                    dispatch(deleteClaimItemAttachmentById({ id: currentClaim.id, claimItemId: currentClaimItem.id, claimItemAttachmentId: attachment.id }))
                                    let uploadFiles = values.attachments
                                    uploadFiles.splice(index, 1);
                                    setFieldValue("attachments", uploadFiles);
                                  }
                                }}></i>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                }
              </div>

              <div className="col-md-12 ">
                <div className="form-wrap wow fadeSlideInUp">
                  <label className="form-label-outside" htmlFor="description">Description</label>
                  <Field id="description" as="textarea" type="text" name="description" placeholder="Description" className="form-input" value={values.description || ''}
                    onChange={(event) => {
                      setFieldValue("description", event.currentTarget.value);
                    }} />
                </div>
              </div>
              <div className="col-md-12 "></div>
              <div className="col-md-6 ">
                <button className="button button-primary button-winona" type="submit" >create</button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}

export default EditUserClaimItem;
