import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import * as Icon from 'react-bootstrap-icons';
import { nextStep } from "../../redux/action/step.action";
import { fetchCategoryTreeByStep } from "../../redux/action/category.action";
import { useAuth0 } from "@auth0/auth0-react";
import { useFormikContext } from "formik";
import { updateToken } from "../../redux/action/auth.action";
import { resetCurrentProducts } from "../../redux/action/product-price.action";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import { useTranslation } from "react-i18next";
import _ from 'lodash'

export const ProductPriceList = (props) => {
  const products = useSelector(state => state.productPrice.products);
  let stepsArray = useSelector(state => state.steps.stepsArray);
  let currentStep = useSelector(state => state.steps.currentStep);
  const dispatch = useDispatch();
  const [token, setToken] = useState('')
  const { user, getAccessTokenSilently } = useAuth0();
  const currentCategory = props.category
  const { values, setFieldValue, submitForm, setValues } = useFormikContext();
  const { t, i18n } = useTranslation('common');

  useEffect(() => {
    getAccessTokenSilently().then(token => {
      dispatch(fetchCategoryTreeByStep({ token: token, categoryId: currentStep.id }))
      setToken(token)
      dispatch(updateToken({ token: token }))
    })
  }, [currentStep])

  useEffect(() => {
    dispatch(resetCurrentProducts());
  }, [])

  function priceFormatter(cell, row) {
    return (
      <>
        {row.price ? `${row.price.toFixed(2)} €` : "Auf Anfrage"}
      </>
    );
  }

  function actionFormatter(cell, row) {
    return (
      <center>
        <a href="" onClick={(e) => {
          e.preventDefault()
          dispatch(nextStep({ category: currentCategory }))
          values["product"] = row
          setValues(values)
        }}>
          <Icon.Gear />
        </a>
      </center>
    );
  }

  const columns = [
    {
      dataField: 'productLine',
      text: t(`category.name.${_.snakeCase('product Line')}`),
      sort: true,
    },
    {
      dataField: 'exSchutzMotor',
      text: t(`category.name.${_.snakeCase('Ex Schutz Motor')}`),
      sort: true,
    },
    {
      dataField: 'frequenz',
      text: t(`category.name.${_.snakeCase('Frequenz')}`),
      sort: true,
    },
    {
      dataField: 'leistung',
      text: t(`category.name.${_.snakeCase('Leistung')}`),
      sort: true,
    },
    {
      dataField: 'motorTyp',
      text: t(`category.name.${_.snakeCase('Motor Typ')}`),
      sort: true,
    },
    {
      dataField: 'synchronDrehzahl',
      text: t(`category.name.${_.snakeCase('Synchron Drehzahl')}`),
      sort: true,
    },
    {
      dataField: 'wirkungsgrad',
      text: t(`category.name.${_.snakeCase('Wirkungsgrad')}`),
      sort: true,
      classes: 'ellipsis'
    },
    {
      dataField: 'baugroesse',
      text: t(`category.name.${_.snakeCase('Baugröße')}`),
      sort: true,
    },
    {
      dataField: 'price',
      text: t(`category.name.${_.snakeCase('Price')}`),
      formatter: priceFormatter,
      sort: true,
    },
    { 
      dataField: 'action',
      text: '',
      formatter: actionFormatter,
    }
  ];

  const options = {
    custom: true,
    totalSize: products.length,
    sizePerPage: 20
  };

  return (
    <div className="row">
      <div className="col-md-12">
        {products && products.length > 0 &&
          <PaginationProvider
            pagination={paginationFactory(options)}
          >
            {
              ({
                paginationProps,
                paginationTableProps,
              }) => (
                <div>
                  <ToolkitProvider
                    bootstrap4
                    keyField="id"
                    data={products}
                    columns={columns}
                    search
                  >
                    {
                      toolkitprops => (
                        <div>
                          <BootstrapTable
                            classes="product-list"
                            striped
                            hover
                            {...toolkitprops.baseProps}
                            {...paginationTableProps}
                          />
                        </div>
                      )
                    }
                  </ToolkitProvider>
                  <PaginationListStandalone
                    {...paginationProps}
                  />
                </div>
              )
            }
          </PaginationProvider>
        }
      </div>
    </div>
  );
}

