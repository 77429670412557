import { ADD_TO_RFQ, SAVE_RFQ_SUCCESS } from "../action/rfq.action";

const initialState = {
  items:[],
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_TO_RFQ:
      state.items.push(action.payload)
      return {
        ...state,
      }
    case SAVE_RFQ_SUCCESS:
      return action.payload
    default:
      return state;
  }
}