export const  ADD_TO_RFQ = "add to rfq"

export const  SAVE_RFQ = "save rfq"
export const  SAVE_RFQ_SUCCESS = "save rfq success"
export const  SAVE_RFQ_FAILURE = "save rfq failure"

export const  FETCH_RFQ_HISTORY = "fetch rfq history"
export const  FETCH_RFQ_HISTORY_SUCCESS = "fetch rfq history success"
export const  FETCH_RFQ_HISTORY_FAILURE = "fetch rfq history failure"

export const  FETCH_RFQ_DETAIL_BY_ID = "fetch rfq detail by id history"
export const  FETCH_RFQ_DETAIL_BY_ID_SUCCESS = "fetch rfq detail by id success"
export const  FETCH_RFQ_DETAIL_BY_ID_FAILURE = "fetch rfq detail by id failure"

export const addToRfq = (data) => ({
  type: ADD_TO_RFQ,
  payload: data
});

export const saveRfq = (data) => ({
  type: SAVE_RFQ,
  payload: data
});

export const fetchRfqHistory = (data) => ({
  type: FETCH_RFQ_HISTORY,
  payload: data
});

export const fetchRfqDetailById = (data) => ({
  type: FETCH_RFQ_DETAIL_BY_ID,
  payload: data
});





