import { FETCH_CUSTOMERS_SUCCESS, SEARCH_CUSTOMERS_SUCCESS } from "../../action/admin/admin-customers.action";

const initialState = {
  list: [],
  seq : 0,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        list: action.payload,
        seq : state.seq + 1
      }
    case SEARCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        list: action.payload,
        seq : state.seq + 1
      }
    default:
      return state;
  }
}