import React, { useEffect, useState, useRef, useContext } from 'react';
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategoryTreeByStep, fetchChildCategories } from '../../redux/action/category.action';
import { useAuth0 } from '@auth0/auth0-react';
import { ErrorMessage, Field, Formik, useFormikContext } from 'formik';
import { searchProductPrice } from '../../redux/action/product-price.action';
import { Accordion, AccordionContext, Button, Card, useAccordionToggle } from 'react-bootstrap';
import JwksRsa from 'jwks-rsa';
import { Link } from 'react-router-dom';
import { Fragment } from 'react';

const ItemComponent = (props) => {
  const currentItem = props.item
  const dispatch = useDispatch();

  return (
    <tr>
      <td>      
        {currentItem.product.productLine}
      </td>
      <td>{currentItem.amount || 1}</td>
      <td>{currentItem.totalPrice}</td>
      <td>
        <div className="group group-middle">
          <button type="submit" className="button button-icon button-icon-left button-winona" onClick={(e) => {
          }}
          >Edit</button>
          <button type="submit" className="button button-icon button-icon-left button-winona" onClick={(e) => {
          }}
          >Add amount</button>
          <button type="submit" className="button button-icon button-icon-left button-winona" onClick={(e) => {
          }}
          >Remove</button>
        </div>
      </td>
    </tr>
  )
}
export default ItemComponent;
