export const FETCH_ALL_ORDERS = "fetch all orders"
export const FETCH_ALL_ORDERS_SUCCESS = "fetch all orders success"
export const FETCH_ALL_ORDERS_FAILURE = "fetch all orders failure"

export const fetchAllOrders = (data) => ({
  type: FETCH_ALL_ORDERS,
  payload: data
});


