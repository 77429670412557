import React, { useEffect, useState, useRef, useContext } from 'react';
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategoryTreeByStep, fetchChildCategories, fetchFullChildCategories } from '../../redux/action/category.action';
import { useAuth0 } from '@auth0/auth0-react';
import { ErrorMessage, Field, Formik, useFormikContext } from 'formik';
import { searchProductPrice } from '../../redux/action/product-price.action';
import { Accordion, AccordionContext, Button, Card, useAccordionToggle } from 'react-bootstrap';
import JwksRsa from 'jwks-rsa';
import { Link } from 'react-router-dom';
import Merkmal from './Merkmal.Component';
import { updateToken } from '../../redux/action/auth.action';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <a
      type="button"
      className={isCurrentEventKey ? "" : "collapsed"}
      onClick={decoratedOnClick}
      style={{ cursor: "pointer" }}
    >
      {children}
    </a>
  );
}

const Merkmalen = (props) => {
  const currentCategory = props.category
  const dispatch = useDispatch();
  const merkmalenChildren = useSelector(state => state.category.merkmalenChildren);
  const { user, getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState('')
  const [collapse, setCollapse] = useState({})

  let stepsArray = useSelector(state => state.steps.stepsArray);
  const { values, setFieldValue, submitForm, setValues } = useFormikContext();

  const { t, i18n } = useTranslation('common');

  useEffect(() => {
    getAccessTokenSilently().then(token => {
      setToken(token)
      dispatch(fetchFullChildCategories({ token: token, categoryId: currentCategory.id }))
      dispatch(updateToken({ token: token }))
    })
  }, [currentCategory])

  return (
    <div className="row">
      <div className="col-sm-12">
        <Accordion defaultActiveKey="0">
          {merkmalenChildren &&
            merkmalenChildren.map((c, index) => {
              return (
                <article key={"arrodion" + index} className="card card-custom card-corporate wow fadeInDown" data-wow-delay=".1s">
                  <div className="card-header" role="tab">
                    <div className="card-title" style={{display: "inline-flex"}}>
                      <ContextAwareToggle eventKey={index.toString()}>
                        {t(`category_group.name.${_.snakeCase(c.name)}`)}
                        <div className="card-arrow"></div>
                      </ContextAwareToggle>
                      { c.tooltip &&
                        <span>
                          <a data-tip style={{padding: "15px 0px"}} className="inline-block inline-block" data-for={"tooltips_" + c.id}> 
                            <i className="icon icon-sm link-default mdi mdi-help-circle"></i>
                          </a>
                          <ReactTooltip className="custom-mouseover-tooltip" place="right" type="dark" effect="float" id={"tooltips_" + c.id} >
                            <p style={{whiteSpace:"pre-wrap", marginTop:"0px" , maxWidth:"250px"}}>{c.tooltip}</p>
                          </ReactTooltip>
                        </span>
                      }
                      { c.tooltipWordpressId &&
                        <span>
                          <a style={{padding: "15px 0px"}} data-event='click' data-tip className="inline-block inline-block" data-for={"tooltips_wordpress_" + c.id}> 
                            <i className="icon icon-sm link-default mdi mdi-more"></i>
                          </a>
                          <ReactTooltip globalEventOff='click' effect='solid' clickable={true} place="right" type="dark" id={"tooltips_wordpress_" + c.id}>
                            <div className="custom-tooltip">
                              <p dangerouslySetInnerHTML={{ __html: c.tooltipWordpressContent }} />
                            </div>
                          </ReactTooltip>
                        </span>
                      }
                    </div>
                  </div>
                  <Accordion.Collapse eventKey={index.toString()}>
                    <div className="row">
                      <Merkmal category={c} />
                    </div>
                  </Accordion.Collapse>
                </article>
              )
            })
          }
        </Accordion>
      </div>
    </div>

  );
}

export default Merkmalen;
