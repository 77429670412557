import { JSONEditor } from '@json-editor/json-editor'
import React, { Component, useEffect, useRef, useState } from "react";
import { Field, Formik, useFormikContext, ErrorMessage } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { addUiRule, fetchEditRuleById } from '../../../redux/action/rules.action';
import _ from 'lodash'
import { Fragment } from 'react';
import { setShowAddRuleModal } from '../../../redux/action/ui.action';
import { addAufpreisRule, fetchEditAufpreisRuleById } from '../../../redux/action/admin/aufpreisRules.action';

const SimpleAufpreisRuleEditor = (props) => {
  const dispatch = useDispatch()
  const { user, getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState('')
  const [checkAllBaugroesse, setCheckAllBaugroesse] = useState(true);
  const [checkAllBauform, setCheckAllBauform] = useState(true);
  const [checkAllFlanschgroesse, setCheckAllFlanschgroesse] = useState(true);
  const categoryList = useSelector(state => state.category.list);
  const groupList = useSelector(state => state.category.groupList);
  const [selectedCategory, setSelectedCategory] = useState('');
  const currentEditRule = useSelector(state => state.aufpreis.currentEditRule);

  useEffect(() => {
    if (props.ruleId) {
      dispatch(fetchEditAufpreisRuleById({ id: props.ruleId }))
    }
  }, [])

  return (
    <>
      <Formik
        initialValues={currentEditRule}
        enableReinitialize={true}
        validate={values => {
          console.info("validate rule:", values);
          const errors = {};
          if (!values.name) {
            errors.name = 'Required';
          }
          return errors;
        }}
        onSubmit={(values) => {
          let array = []
          values.constaintCategories.forEach((c) => {
            array.push({ name: c.name, values: values[_.snakeCase(c.name)] })
          })
          let priceArray = []
          for (let key in values.prices) {
            if (key !== 'baugroesse_undefined') {
              priceArray.push({ baugroesse: key.replace('baugroesse_', ''), value: values.prices[key], prozentValue: values.prozentPrices[key] / 100.0 })
            }
          }
          dispatch(addAufpreisRule({ parameters: { ...values, categoryValues: array, pricesValues: priceArray } }))
          dispatch(setShowAddRuleModal({ show: false }));
        }}>
        {({ values, errors, handleSubmit, setFieldValue, setValues }) => (
          <form onSubmit={handleSubmit} autoComplete="off" className="rd-form rd-mailform">
            {values.id &&
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="id">ID</label>
                    <Field id="id" type="text" name="id" disabled className="form-control" value={values.id || ''}
                    />
                  </div>
                </div>
              </div>
            }

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="name">Rule Name(Required)<ErrorMessage name="name" render={msg => <span className="error">(*{msg})</span>} /></label>
                  <Field id="name" type="text" name="name" placeholder="Rule Name" className="form-control" value={values.name || ''}
                    onChange={(event) => {
                      setFieldValue("name", event.currentTarget.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-3">
                <select className="form-input select" value={selectedCategory || ''} onChange={(e) => {
                  setSelectedCategory(e.currentTarget.value)
                }}>
                  <option value="" label="Select a category" />
                  {groupList &&
                    groupList.map((g, index) => {
                      return (
                        <optgroup key={"properties_country_group_" + index} label={g.name}>
                          {
                            g.categories.map((c, index) => {
                              return (
                                <option key={"categoy_" + c.id} value={c.id}>{c.name}</option>
                              )
                            })
                          }
                        </optgroup>
                      )
                    })
                  }
                </select>
              </div>
              <div className="col-md-9">
                <div className="group group-middle">
                  <button type="button" className="button button-primary" onClick={() => {
                    let categroy = categoryList.find(x => x.id.toString() === selectedCategory)
                    if (values.constaintCategories.indexOf(categroy) >= 0) {
                      return
                    }
                    let array = [...values.constaintCategories]
                    array.push(categroy);
                    let a = categroy.properties.map(p => p.name)
                    setFieldValue(_.snakeCase(categroy.name), a)
                    setFieldValue("constaintCategories", array)
                  }}> Add To Conditions </button>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6" style={{ backgroundColor: "lightyellow" }}>
                <h3>Conditions</h3>
                {values.constaintCategories &&
                  values.constaintCategories.map(cc => {
                    return (
                      <Fragment key={"cc_" + cc.id}>
                        <div className="row">
                          <div className="col-md-12">
                            <div id="checkbox-group" className="checkbox-list">{cc.name}
                              <button type="button" onClick={() => {
                                let a = cc.properties.map(p => p.id.toString())
                                setFieldValue(_.snakeCase(cc.name), a)
                              }} >check all </button>
                              <button type="button" onClick={() => {
                                setFieldValue(_.snakeCase(cc.name), [])
                              }} >uncheck all </button>
                              <button type="button" onClick={() => {
                                let myArr = [...values.constaintCategories]
                                myArr = myArr.filter(function (item) { return item.id != cc.id; });
                                setFieldValue("constaintCategories", myArr)
                              }} >remove from conditions </button>
                            </div>
                            <div role="group" aria-labelledby="checkbox-group" className="checkbox-list">
                              {cc.properties &&
                                cc.properties.map(cp => {
                                  return (
                                    <label key={"cp_" + cp.id}><Field type="checkbox" name={_.snakeCase(cc.name)} value={cp.id.toString()} /> {cp.name}   </label>
                                  )
                                })
                              }
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    )
                  })
                }
              </div>
              <div className="col-md-6" >
                <div className="col-md-12" style={{ backgroundColor: "lightcyan" }}>
                  <h3>LeadTime(Weeks)</h3>
                  <Field type="text" name="leadTimeWeeks" style={{ width: "100px" }} value={values.leadTimeWeeks || ''}
                    onChange={(event) => {
                      values.leadTimeWeeks = event.currentTarget.value
                      setValues(values);
                    }}
                  />

                </div>
                <div className="col-md-12 mt10" style={{ backgroundColor: "lightcyan" }}>
                  <h3>Aufpreis</h3>
                  <p>Auf Anfragen = -1</p>
                  <table className="table table-hover table-job-positions baugroesse-list">
                    <thead><tr><th>Baugröße</th><th>Aufpreise</th><th>Aufpreise %</th></tr></thead>
                    <tbody>
                      {
                        [63, 71, 80, 90, 100, 112, 132, 160, 180, 200, 225, 250, 280, 315, 355, 400, 450,].map((b, index) => {
                          return (
                            <tr key={"baug_" + index}>
                              <td>{b}</td>
                              <td>
                                <Field type="text" name="price" style={{ width: "100px" }} value={values.prices["baugroesse_" + b] || ''}
                                  onChange={(event) => {
                                    values.prices["baugroesse_" + b] = event.currentTarget.value
                                    setValues(values);
                                  }}
                                />
                              </td>
                              <td>
                                <Field type="text" name="prozentPrice" style={{ width: "100px" }} value={values.prozentPrices["baugroesse_" + b] || ''}
                                  onChange={(event) => {
                                    values.prices["baugroesse_" + b] = values.prices["baugroesse_" + b] || ''
                                    values.prozentPrices["baugroesse_" + b] = event.currentTarget.value
                                    setValues(values);
                                  }}
                                /> %
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <button type="submit" className="button button-primary">Submit</button>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}

export default SimpleAufpreisRuleEditor;
