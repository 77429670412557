import { RESET_CURRENT_PRODUCTS, SEARCH_PRODUCT_PRICE_SUCCESS } from "../action/product-price.action";

const initialState = {
  products:[],
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case SEARCH_PRODUCT_PRICE_SUCCESS:
      return {
        ...state,
        products:action.payload
      }
    case RESET_CURRENT_PRODUCTS:
      return {
        ...state,
        products:[]
      }
    default:
      return state;
  }
}