import React, { useEffect, useState } from 'react';
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import { fetchCurrentEditProperty, resetCurrentEditProperty, updateProperty } from '../../../redux/action/category.action';
import { Field, Formik } from 'formik';
import { updateClaimReason } from '../../../redux/action/claim.action';
import { Button, Col, Form, FormControl, InputGroup, Row } from 'react-bootstrap';
import { searchCustomers } from '../../../redux/action/admin/admin-customers.action';
import { addCustomerToUser, removeUserCustomer } from '../../../redux/action/admin/admin-users.action';

const AdminEditUser = () => {
  const dispatch = useDispatch()
  const customers = useSelector(state => state.customers.list);
  const seq = useSelector(state => state.customers.seq);
  const currentUser =  useSelector(state => state.users.currentUser);
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
  }, [seq])

  return (
    <>
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md={2}>
          {currentUser.picture &&
            <img
              src={currentUser.picture}
              alt="Profile"
              className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
            />
          }
        </Col>
        <Col md>
          <h2>{currentUser.name}</h2>
          <p className="lead text-muted">{currentUser.company || ''}</p>
          <p className="lead text-muted">{currentUser.email}</p>
        </Col>
      </Row>
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md={12}>
          <table className='table'>
            <tbody>
              {currentUser.userCustomers &&
                currentUser.userCustomers.map((uc) => {
                  return (
                    <tr key={"user_custoemr_" + uc.id}>
                      <td>{uc.customer.id}</td>
                      <td>{uc.customer.name1}</td>
                      <td>
                        <Button variant="warning" size="sm" onClick={(e) => {
                          dispatch(removeUserCustomer({ parameters: { userId: currentUser.id, userCustomerId: uc.id } }))
                        }}>
                          remove customer from user
                        </Button>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>

        </Col>
      </Row>
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md={6}>
          <Form>
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Search Customer"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value)
                }}
                onKeyPress={e => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    setSearchText(e.target.value)
                    dispatch(searchCustomers({ term: searchText }))
                  }
                }}
              />
              <Button onClick={(e) => {
                dispatch(searchCustomers({ term: searchText }))
              }}>
                Search
              </Button>
            </InputGroup>
          </Form>
        </Col>
      </Row>
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md={12}>
          {customers &&
            <table className='table'>
              <tbody>
                {customers.map(c => {
                  return (
                    <tr key={"customer_" + c.id}>
                      <td>
                        <Button variant="primary" size="sm" onClick={(e) => {
                          dispatch(addCustomerToUser({ parameters: { customerId: c.id, userId: currentUser.id } }))
                        }}>
                          add customer to user
                        </Button>
                      </td>
                      <td>{c.kunNr}</td>
                      <td>{c.name1}</td>
                      <td>{c.name2}</td>
                      <td>{c.telf1}</td>
                      <td>{c.email}</td>
                    </tr>
                  )
                })
                }
              </tbody>
            </table >
          }
        </Col>
      </Row>
    </>
  );
}

export default AdminEditUser;
