export const  UPDATE_UI = "prev step"

export const ADD_TO_BLOCK_ARRAY = "add to block array"
export const REMOVE_FROM_BLOCK_ARRAY  = "remove from block array"

export const ADD_TO_DEFAULT_ARRAY = "add to default array"
export const REMOVE_FROM_DEFAULT_ARRAY  = "remove from default array"

export const ADD_TO_MAX_VALUE_ARRAY = "add to max value array"
export const REMOVE_FROM_MAX_VALUE_ARRAY  = "remove from max value array"

export const SHOW_ADD_RULE_MODAL = "show add rule modal"

export const SHOW_EDIT_CATEGORY_MODAL = "show edit category modal"

export const ADD_TO_CHANGED_CATEGORY_IDS = "add to changed category ids"

export const CLEAN_REDIRECT = "clean redirect"

export const cleanRedirect = (data) => ({
  type: CLEAN_REDIRECT,
  payload: data
});

export const updateUi = (data) => ({
  type: UPDATE_UI,
  payload: data
});

export const addToBlockArray = (data) => ({
  type: ADD_TO_BLOCK_ARRAY,
  payload: data
});

export const removeFromBlockArray = (data) => ({
  type: REMOVE_FROM_BLOCK_ARRAY,
  payload: data
});

export const addToDefaultArray = (data) => ({
  type: ADD_TO_DEFAULT_ARRAY,
  payload: data
});

export const removeFromDefaultArray = (data) => ({
  type: REMOVE_FROM_DEFAULT_ARRAY,
  payload: data
});

export const setShowAddRuleModal = (data) => ({
  type: SHOW_ADD_RULE_MODAL,
  payload: data
});

export const setShowEditCategoryModal = (data) => ({
  type: SHOW_EDIT_CATEGORY_MODAL,
  payload: data
});

export const addToMaxValueArray = (data) => ({
  type: ADD_TO_MAX_VALUE_ARRAY,
  payload: data
});

export const removeFromMaxValueArray = (data) => ({
  type: REMOVE_FROM_MAX_VALUE_ARRAY,
  payload: data
});

export const addToChangedCategoryIds = (data) => ({
  type: ADD_TO_CHANGED_CATEGORY_IDS,
  payload: data
});