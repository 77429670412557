import { FETCH_CLAIM_BY_ID_SUCCESS, FETCH_CLAIM_HISTORY_BY_CLAIM_ID_SUCCESS, FETCH_CLAIM_LIST_SUCCESS, GET_SERIEN_NUMBERS_SUCCESS, SET_CURRENT_CLAIM, SET_CURRENT_CLAIM_ITEM, UPDATE_CLAIM_ITEM } from "../action/claim.action";

const initialState = {
  list: [],
  current: {
    alertTo: "nordenham.service@de.atb-motors.com",
    items: [],
    tempAttachments: [],
    tempImages: [],
    kundenname: "",
    kundennummer: "",
    telefon: "",
    email: "",
    lieferscheinNr: "",
    auftragNr: "",
    status:"DRAFT"
  },
  currentItem: {},
  seq: 0,
  items: [],
  reasons: [],
  serienNumbers: [],
  currentHistory:[],
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CLAIM_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload
      }
    case FETCH_CLAIM_BY_ID_SUCCESS:
      return {
        ...state,
        current: action.payload
      }
    case SET_CURRENT_CLAIM:
      Object.assign(state.current, action.payload)
      return {
        ...state,
        seq: state.seq + 1
      }
    case SET_CURRENT_CLAIM_ITEM:
      return {
        ...state,
        currentItem: action.payload
      }
    case UPDATE_CLAIM_ITEM:
      state.current.items.forEach(i => {
        if (i.uuid && i.uuid === action.payload.uuid)
          i = action.payload
      })
      return {
        ...state,
        currentItem: action.payload
      }
    case GET_SERIEN_NUMBERS_SUCCESS:
      let newItems = state.current.items
      action.payload.forEach(n => {
        newItems.push({
          tempImages: [], tempAttachments: [], images: [], attachments: [],
          menge: 1,
          classification: "",
          reasonIds: [],
          serialNr: n.serNr, 
          artikeNr: n.matNr,
          artikeKtx: n.maKtx
        })
      })

      Object.assign(state.current.items, newItems)

      return {
        ...state,
        serienNumbers: action.payload,
        seq: state.seq + 1
      }
    case FETCH_CLAIM_HISTORY_BY_CLAIM_ID_SUCCESS:
      return {
        ...state,
        currentHistory: action.payload,
        seq: state.seq + 1
      }
    default:
      return state;
  }
}