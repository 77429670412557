import React, { useEffect, useState, useRef } from 'react';
import { Button, Collapse, Modal } from 'react-bootstrap';
import _ from 'lodash'
import { Field, useFormikContext } from 'formik';
import * as Icon from 'react-bootstrap-icons';
import { updateUi } from '../../redux/action/ui.action';
import { useDispatch } from 'react-redux';
import { Fragment } from 'react';
import EditCategory from './EditCategory.Component';


const TreeComponent = (props) => {
  const currentCategory = props.category
  const [showChild, setShowChild] = useState(props.showChild);
  const lastChild = props.lastChild
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  return (
    <div>
      <Modal show={show} size="xl" dialogClassName="modal-details">
        <Modal.Header>
          <Modal.Title>{currentCategory.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditCategory category = {currentCategory} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="button button-icon button-icon-left button-winona" onClick={(e) => {
            setShow(false);
          }}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <ul className="maketree">
        <li className={lastChild?"last":""}>
          <a href="" 
            onClick={(e)=>{
              e.preventDefault();
              setShowChild(!showChild);
            }}>
            {currentCategory.name} 
          </a>
          <a href="" onClick={ e=> {
            e.preventDefault();
            setShow(true);
          }}>
            <span style={{paddingLeft:"10px"}}><Icon.PencilSquare /></span>
          </a>
          { currentCategory.wordpressId &&
            <a href={"https://digicat-atb-nordenham.de/?p=" + currentCategory.wordpressId} target="_blanke">
              <i className="fa fa-wordpress"/>
            </a> 
          }

          { currentCategory.subCategories && showChild &&
            currentCategory.subCategories.map((c, index) => {
              return (
                <TreeComponent parents={props.parents + "," + currentCategory.id} lastChild={(index+1)===currentCategory.subCategories.length?true:false} key={"tree_" + index} category={c} showChild = {false}/>
              )
            })
          }
          { currentCategory.properties && currentCategory.properties.length > 0 &&
            <select >
            {
              currentCategory.properties.map((p, index) => {
                return (
                  <option key={"properties_" + p.id} value={p.value}>{p.name}</option>
                )
              })
            }
            </select>
          }
        </li>
      </ul>
    </div>
  );
}

export default TreeComponent;
