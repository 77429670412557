import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./react/App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./react/utils/history";
import { getConfig } from "./config";
import rootReducer from './redux/reducer/root.reducer';
import thunk from "redux-thunk";
import { applyMiddleware, createStore } from "redux";
import { middlewares } from './redux/middleware/root.middleware'
import { Provider } from 'react-redux';
import "react-toastify/dist/ReactToastify.min.css"
import { ToastContainer } from 'react-toastify-redux/';
import i18n from './i18n'


export const store = createStore(
  rootReducer,
  applyMiddleware(thunk, ...middlewares)
);

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

// Please see https://auth0.github.io/auth0-react/interfaces/auth0provideroptions.html
// for a full list of the available properties on the provider
const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  ...(config.audience ? { audience: config.audience } : null),
  redirectUri: window.location.origin + "/digicat",
  onRedirectCallback,
};

ReactDOM.render(
  <Provider store={store}>
    <Auth0Provider {...providerConfig}>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <App />
    </Auth0Provider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
