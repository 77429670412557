import React, { useEffect, useState } from "react";

import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { copyUiRule, deleteUiRule, fetchAllRules, fetchRules, resetCurrentEditRule } from "../../redux/action/rules.action";
import { Fragment } from "react";
import { Button, Collapse, Modal } from 'react-bootstrap';
import SimpleRuleEditor from "../components/SimpleRuleEditor.Component";
import { fetchCategoyGroupList, fetchCategoyList } from "../../redux/action/category.action";
import { setShowAddRuleModal } from "../../redux/action/ui.action";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import { updateToken } from "../../redux/action/auth.action";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import {useTranslation} from "react-i18next";

const products = [{ id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 2, name: "sadf", price: 234.4 }]

export const RulesView = () => {
  const { t, i18n } = useTranslation('common');//国际化
  const dispatch = useDispatch()
  const { user, getAccessTokenSilently } = useAuth0();
  const rules = useSelector(state => state.rules.rules);
  const [token, setToken] = useState('')
  const showAddRuleModal = useSelector(state => state.ui.showAddRuleModal);

  const [currentRule, setCurrentRule] = useState({});

  const { SearchBar } = Search;

  function actionFormatter(cell, row) {
    return (
      <div className="group group-middle">
        <button type="submit" className="button button-sm button-primary-outline button-winona" onClick={(e) => {
          dispatch(setShowAddRuleModal({ show: true }));
          setCurrentRule(row)
        }}
        >{t('home.Edit')}</button>
        <button type="submit" className="button button-sm button-primary-outline button-winona" onClick={(e) => {
          dispatch(copyUiRule({ id: row.id }));
        }}
        >{t('home.Copy')}</button>
        <button type="submit" className="button button-sm button-primary-outline button-winona" onClick={(e) => {
          if (window.confirm('Are you sure you wish to delete this item?')) {
            dispatch(deleteUiRule({ token: token, id: row.id }));
          }
        }}
        >{t('home.Delete')}</button>
      </div>
    );
  }

  function conditionsFormatter(cell, row) {
    return (
      <Fragment key={"rule_" + row.id}>
        <div style={{ backgroundColor: "lightyellow" }}>
          {row.simpleConditions &&
            row.simpleConditions.map((c, index) => {
              return (
                <div key={"condi_" + index}>{c}</div>
              )
            })
          }
        </div>
        <div style={{ backgroundColor: "lightcyan", marginTop: "10px" }}>
          {row.simpleEvents &&
            row.simpleEvents.map((c, index) => {
              return (
                <div key={"events_" + index}>{c}</div>
              )
            })
          }
        </div>
        <div style={{ backgroundColor: "lightgreen", marginTop: "10px" }}>
          {row.simpleDefaults &&
            row.simpleDefaults.map((c, index) => {
              return (
                <div key={"condi_default_" + index}>{c}</div>
              )
            })
          }
        </div>
      </Fragment>
    );
  }

  const columns = [{
    dataField: 'id',
    text: 'ID',
    sort: true,
    headerClasses: 'width-procent-10'
  }, {
    dataField: 'name',
    text: 'Rule Name',
    sort: true,
    headerClasses: 'width-procent-20'
  }, 
  {
    dataField: 'listOrder',
    text: 'Rule Check Order',
    sort: true,
    headerClasses: 'width-procent-20'
  },
  {
    dataField: 'condition',
    text: 'Conditions & Events',
    formatter: conditionsFormatter,
    filterValue: (cell, row) => {
      let ret = ""
      ret = row.simpleConditions.map((c, index) => c )
      ret += row.simpleEvents.map((c, index) => c)
      ret += row.simpleDefaults.map((c, index) => c)
      return ret;
    }
  }, {
    dataField: 'action',
    text: 'Action',
    formatter: actionFormatter,
    headerClasses: 'width-procent-20'
  }];


  useEffect(() => {
    getAccessTokenSilently().then(token => {
      setToken(token)
      dispatch(fetchAllRules({ token: token }))
      dispatch(fetchCategoyList({ token: token }))
      dispatch(fetchCategoyGroupList({ token: token }))
      dispatch(updateToken({ token: token }))
    })
  }, [])

  const options = {
    custom: true,
    totalSize: rules.length
  };

  return (
    <>
      <Modal show={showAddRuleModal} size="xl" dialogClassName="modal-details">
        <Modal.Header>
          <Modal.Title>{t('home.AddnewRule')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SimpleRuleEditor ruleId = {currentRule.id?currentRule.id:""}/>
        </Modal.Body>
        <Modal.Footer>
          <Button className="button button-icon button-icon-left button-winona" onClick={(e) => {
            dispatch(setShowAddRuleModal({ show: false }));
          }}>
            {t('home.Close')}
          </Button>
        </Modal.Footer>
      </Modal>

      <section className="section section-lg text-center">
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <div className="group group-middle">
                <button type="button" className="button" onClick={(e) => {
                  e.preventDefault();
                  setCurrentRule({})
                  dispatch(resetCurrentEditRule())
                  dispatch(setShowAddRuleModal({ show: true }));
                }}
                >{t('home.Addnewrule')}</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {rules && rules.length > 0 &&
        <section className="section section-lg text-center">
          <div className="container">
            <PaginationProvider
              pagination={paginationFactory(options)}
            >
              {
                ({
                  paginationProps,
                  paginationTableProps
                }) => (
                  <div>
                    <PaginationListStandalone
                      {...paginationProps}
                    />
                    <ToolkitProvider
                      bootstrap4
                      keyField="id"
                      data={rules}
                      columns={columns}
                      search
                    >
                      {
                        toolkitprops => (
                          <div>
                            <div style={{float:"left", padding:"10px 0px"}}>
                              <SearchBar {...toolkitprops.searchProps} style={{float:"left"}}  placeholder="Search Rule Name / ID"/>
                            </div>
                            <BootstrapTable
                              striped
                              hover
                              {...toolkitprops.baseProps}
                              {...paginationTableProps}
                            />
                          </div>
                        )
                      }
                    </ToolkitProvider>
                    <PaginationListStandalone
                      {...paginationProps}
                    />
                  </div>
                )
              }
            </PaginationProvider>
          </div>
        </section>
      }
    </>
  );
};

export default withAuthenticationRequired(RulesView, {
  onRedirecting: () => <Loading />,
});
