import React, { useEffect, useState } from "react";
import { Container, Row, Col, Accordion, Card, Button } from "react-bootstrap";

import Highlight from "../components/Highlight";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { fetchCategoryTree, fetchCategoryTreeByStep } from "../../redux/action/category.action";
import { useDispatch, useSelector } from "react-redux";
import PreSelections from "../components/PreSelections.Compnent";
import { ErrorMessage, Field, Formik, useFormikContext } from 'formik';
import { updateUi } from "../../redux/action/ui.action";
import { ChatSquareDotsFill } from "react-bootstrap-icons";
import { nextStep } from "../../redux/action/step.action";
import { Fragment } from "react";
import { ProductPriceList } from "./ProductPriceList";
import Merkmalen from "./Merkmalen.Component";
import SelectedParameters from "./SelectedParameters.Component";
import { updateToken } from "../../redux/action/auth.action";
import { saveRfq } from "../../redux/action/rfq.action";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import _ from 'lodash'
import ReactTooltip from "react-tooltip";

export const Configurator = () => {
  const dispatch = useDispatch();
  const { user, getAccessTokenSilently } = useAuth0();
  const tree = useSelector(state => state.category.tree);
  const ui = useSelector(state => state.ui);
  const currentStep = useSelector(state => state.steps.currentStep);
  const [token, setToken] = useState('')
  const { values, setFieldValue, submitForm, setValues } = useFormikContext();

  let aufpreisSeq = useSelector(state => state.priceRule.seq);
  let uiSeq = useSelector(state => state.ui.seq);

  let aufpreis = useSelector(state => state.priceRule.currentAufpreisen);
  const { t, i18n } = useTranslation('common');

  useEffect(() => {
  }, [aufpreisSeq, uiSeq])

  useEffect(() => {
    getAccessTokenSilently().then(token => {
      dispatch(fetchCategoryTreeByStep({ token: token, categoryId: currentStep.id }))
      setToken(token)
      dispatch(updateToken({ token: token }))
    })
  }, [currentStep])

  const productComposite = useSelector(state => state.productComposite);

  let unitPrice = 0
  let basePriceProcent = 0.0;

  if (values["product"]) {

    Object.entries(values).filter(([key, value]) => key.indexOf("cate") === 0).forEach(([key, value]) => {

      let aufPreisKey = `category_${value.category.id}_baugroesse_${values["product"].baugroesse}`
      let preis = ""
      if (aufpreis && aufpreis[aufPreisKey]) {
        if (aufpreis[aufPreisKey].aufpreis < 0) {
          preis = -1
        } else {
          preis = aufpreis[aufPreisKey].aufpreis
          values[`category_${value.category.id}`]["price"] = aufpreis[aufPreisKey]["aufpreis"]
        }

        if (aufpreis[aufPreisKey].prozentAufpreis > 0) {
          basePriceProcent += aufpreis[aufPreisKey].prozentAufpreis
        }
      }

      if (preis < 0 || unitPrice < 0) {
        unitPrice = -1;
      } else if (preis === "") {
      } else if (preis >= 0) {
        unitPrice = (parseFloat(unitPrice) + parseFloat(preis)).toFixed(2);
      }

    })

    if (values["product"].price >= 0) {
      unitPrice = (parseFloat(unitPrice) + values["product"].price * (1 + basePriceProcent)).toFixed(2);
    } else {
      unitPrice = -1;
    }
  }

  values["unitPrice"] = unitPrice
  values["totalPrice"] = unitPrice * values["quantity"]


  let leadTimeWeeks = 0
  if (values["product"]) {
    Object.entries(aufpreis).forEach(([key, value]) => {
      leadTimeWeeks += value.leadTimeWeeks
    }) 
  }

  values["leadTimeWeeks"] = leadTimeWeeks



  return (
    <div className="mt20">
      <div className="row row-30">
        {tree &&
          tree.map((c, index) => {
            return (
              <Fragment key={"catelog_" + c.id}>
                {!c.hasLeaves &&
                  <div className="col-sm-4">
                    <a href="" style={{ color: "#2d3742" }} onClick={(e) => {
                      e.preventDefault()
                      if (c.active) {
                        setFieldValue(`category_${c.id}`, { category: c })
                        dispatch(nextStep({ category: c }))
                      }
                    }}>
                      <article className="post-classic">
                        <div className="post-image" style={{ backgroundImage: 'url(' + (c.image ? c.image : '/digicat/images/sidebar-blog-1-370x264.jpg') + ')' }} />
                        <h4 className="post-classic-title">{t(`category.name.${_.snakeCase(c.name)}`)}
                          <div data-tip className="inline-block inline-block" data-for={"tooltips_" + c.id} style={{paddingLeft:"10px"}}>
                            <i className="icon icon-sm link-default mdi mdi-help-circle"></i>
                          </div>
                          <ReactTooltip className="custom-mouseover-tooltip" place="right" type="dark" effect="float" id={"tooltips_" + c.id} >
                            <div className="custom-tooltip" style={{ width: "400px" }}>
                              <p style={{whiteSpace:"pre-line"}}>{t(`category.desc.${_.snakeCase(c.name)}`)}</p>
                            </div>
                          </ReactTooltip>
                        </h4>
                      </article>
                    </a>
                  </div>
                }
                {c.hasLeaves && c.name === 'pre_selection' &&
                  <div className="col-sm-12">
                    <PreSelections category={c} />
                    <ProductPriceList category={c} />
                  </div>
                }
                {c.hasLeaves && c.name.startsWith('merkmalen') &&
                  <div className="col-sm-12">

                    <div className="row">
                      <div className="col-sm-6">
                        <Merkmalen category={c} />
                      </div>
                      <div className="col-sm-6">
                        {values["product"] &&
                          <div className="row row-50 row-xxl-70">
                            <div className="col-sm-12">
                              <table className="table table-hover table-job-positions nowrap product-list" style={{ backgroundColor: "lightgray" }}>
                                <thead>
                                  <tr>
                                    <th>Category</th>
                                    <th>Model</th>
                                    <th>Price</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td style={{ textAlign: "left" }}>{values["product"].motorTyp}</td>
                                    <td>
                                      {"Baugröße: " + values["product"].baugroesse}<br />
                                      {"Ex Schutz Motor: " + values["product"].exSchutzMotor} <br />
                                      {"Frequenz: " + values["product"].frequenz} <br />
                                      {"Leistung: " + values["product"].leistung} <br />
                                      {"SynchronDrehzahl: " + values["product"].synchronDrehzahl} <br />
                                      {"Wirkungsgradklasse: " + values["product"].wirkungsgrad}
                                    </td>
                                    <td style={{ textAlign: "right" }}>{values["product"].price ? `${values["product"].price.toFixed(2)} €` : "Auf anfrage"}</td>
                                  </tr>
                                  {basePriceProcent > 0 &&
                                    <tr>
                                      <td style={{ textAlign: "left" }}>Base Aufpreis</td>
                                      <td></td>
                                      <td style={{ textAlign: "right" }}>{(basePriceProcent * 100).toFixed(0)} %</td>
                                    </tr>
                                  }
                                </tbody>
                              </table>
                            </div>
                          </div>
                        }
                        <SelectedParameters />

                        {values["product"] &&
                          <div className="row row-50 row-xxl-70">
                            <div className="col-sm-12">
                              <div className="row">
                                <div className="col-md-12">
                                  <table className="mb0 table table-hover table-job-positions product-list" style={{ backgroundColor: "lightgray" }}>
                                    <tbody>
                                      <tr>
                                        <td style={{ textAlign: "left" }}>Leadtime (weeks)</td>
                                        <td style={{ textAlign: "right" }}>{values["leadTimeWeeks"] > 0 ? `${values["leadTimeWeeks"]}` : 'Auf Anfrage'}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        }

                        {values["product"] &&
                          <div className="row row-50 row-xxl-70">
                            <div className="col-sm-12">
                              <div className="row">
                                <div className="col-md-12">
                                  <table className="mb0 table table-hover table-job-positions product-list" style={{ backgroundColor: "lightgray" }}>
                                    <tbody>
                                      <tr>
                                        <td style={{ textAlign: "left" }}>Unit Price</td>
                                        <td style={{ textAlign: "right" }}>{values["unitPrice"] > 0 ? `${values["unitPrice"]} €` : 'Auf Anfrage'}</td>
                                      </tr>
                                      <tr>
                                        <td style={{ textAlign: "left" }}>Quantity</td>
                                        <td style={{ textAlign: "right" }}>
                                          <Field id="quantity" type="text" style={{ textAlign: "right" }} name="quantity" placeholder="Quantity" className="col-xs-6  form-input form-control" value={values.quantity || ''}
                                            onChange={(event) => {
                                              setFieldValue("quantity", event.currentTarget.value);
                                            }} />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style={{ textAlign: "left" }}>Total Price * </td>
                                        <td style={{ textAlign: "right" }}>{values["totalPrice"] > 0 ? `${parseFloat(values["totalPrice"]).toFixed(2)} €` : 'Auf Anfrage'}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className="row mt0">
                                <div className="col-md-12">
                                  <p>*Bruttolistenpreis zzgl.CU-Zuschlag nach DEL-Notierung.</p>
                                </div>
                              </div>
                              <div className="row mt10" >
                                <div className="col-md-12">
                                  <Field id="notice" as="textarea" type="text" name="notice" placeholder="Notice" className="col-xs-6  form-input form-control" value={values.notice || ''}
                                    onChange={(event) => {
                                      setFieldValue("notice", event.currentTarget.value);
                                    }} />
                                </div>
                              </div>
                              <div className="row mt10" >
                                <div className="col-md-12">
                                  <NavLink to="/rfq">
                                    <button type="submit" style={{ width: "100%" }} className="button button-icon button-icon-left button-primary button-winona" onClick={(e) => {
                                      let items = []
                                      items.push(values)
                                      dispatch(saveRfq({ parameters: { items: items }, user }))
                                    }}
                                    >send Request For Quotation</button>
                                  </NavLink>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                }
              </Fragment>
            )
          })
        }
      </div>
    </div>
  );
};

export default withAuthenticationRequired(Configurator, {
  onRedirecting: () => <Loading />,
});
