import React, { Fragment, useEffect, useState } from "react";
import { Field, Formik } from "formik";
import { Button, Collapse, Modal } from 'react-bootstrap';
import _ from 'lodash'
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { updateToken } from "../../../redux/action/auth.action";
import Loading from "../../components/Loading";
import { deleteUserClaimItemById, fetchUserClaimById, postUserClaim, setCurrentUserClaimItem } from "../../../redux/action/user/claim.action";
import EditUserClaimItem from "../../components/user/EditUserClaimItem.Component";
import Dropzone from 'react-dropzone-uploader'
import { v4 as uuidv4 } from 'uuid';
import { getHost } from "../../utils/host";
import "../../styles/dropzone_style.css"
import '../../styles/suggestions_style.css'
import { deleteClaimAttachmentById, fetchClaimReasonTree } from "../../../redux/action/claim.action";
import { cleanRedirect } from "../../../redux/action/ui.action";
import {useTranslation} from "react-i18next";

function getParams(location, name) {
  const searchParams = new URLSearchParams(location.search);
  let ret = {};
  ret[name] = searchParams.get(name) || "";
  return ret;
}

const UserNewClaimView = () => {
  const { t, i18n } = useTranslation('common');//国际化
  const dispatch = useDispatch();
  const redirect = useSelector(state => state.claimUi.redirect);
  let history = useHistory();
  const { user, getAccessTokenSilently } = useAuth0();
  let currentClaim = useSelector(state => state.userClaims.current);
  let currentClaimItem = useSelector(state => state.userClaims.currentItem);
  const [show, setShow] = useState(false);

  const reasonTree = useSelector(state => state.reasons.tree);

  const token = useSelector(state => state.auth.token);

  useEffect(() => {
    if (redirect) {
      history.push(redirect)
      dispatch(cleanRedirect())
    }
  }, [redirect])


  useEffect(() => {
    getAccessTokenSilently().then(token => {
      dispatch(updateToken({ token: token }))
      let urlParams = getParams(window.location, "id");
      if (urlParams.id) {
        dispatch(fetchUserClaimById({ id: urlParams.id, token: token }))
      }
      dispatch(fetchClaimReasonTree())
    })
  }, [])

  const addOrUpdateItem = (item) => {
    if (currentClaim.id) {

    } else {
      if (item.uuid) {
        let newItems = currentClaim.items.filter(i => i.uuid !== item.uuid)
        currentClaim.items = newItems
      } else {
        item["uuid"] = uuidv4()
      }
      currentClaim.items.push(item);
    }
  }

  return (
    <Fragment>
      <Modal show={show} size="xl" dialogClassName="modal-details">
        <Modal.Header>
          <Modal.Title>{t('user.EditClaimItem')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditUserClaimItem setShow={setShow} addOrUpdateItem={addOrUpdateItem} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="button button-icon button-icon-left button-winona" onClick={(e) => {
            setShow(false);
          }}>
            {t('user.Close')}
          </Button>
        </Modal.Footer>
      </Modal>
      <section className="section">
        <div className="range justify-content-xl-between">
          <div className="cell-xl-12 align-self-center container">
            <div className="row">
              <div className="col-lg-12 cell-inner">
                <h3 className=""><span className="wow slideInDown">{t('user.Reklamation')}</span></h3>
                <Formik
                  initialValues={currentClaim}
                  enableReinitialize={true}
                  validate={values => {
                    const errors = {};
                    console.info("validate form values: ", values);
                    return errors;
                  }}
                  onSubmit={(values) => {
                    dispatch(postUserClaim({ parameters: values }))
                  }}>
                  {({ values, errors, handleSubmit, setFieldValue, setValues }) => (
                    <form onSubmit={handleSubmit} autoComplete="off" className="">
                      <div className="row row-10">
                        <div className="col-md-3 ">
                          <div className="form-wrap ">
                            <label className="form-label-outside" htmlFor="Kundenname">{t('user.Kundenname')}</label>
                            <Field id="Kundenname" type="text" name="Kundenname" placeholder="Kundenname" className="form-input" value={values.kundenname || ''}
                              onChange={(event) => {
                                setFieldValue("kundenname", event.currentTarget.value);
                              }} />
                          </div>
                        </div>
                        <div className="col-md-3 ">
                          <div className="form-wrap ">
                            <label className="form-label-outside" htmlFor="Kundennummer">{t('user.Kundennummer')}</label>
                            <Field id="Kundennummer" type="text" name="Kundennummer" placeholder="Kundennummer" className="form-input" value={values.kundennummer || ''}
                              onChange={(event) => {
                                setFieldValue("kundennummer", event.currentTarget.value);
                              }} />
                          </div>
                        </div>
                        <div className="col-md-6 "></div>
                        <div className="col-md-6 ">
                          <div className="form-wrap ">
                            <label className="form-label-outside" htmlFor="Sachbearbeiter">{t('user.Sachbearbeiter')}</label>
                            <Field id="Sachbearbeiter" type="text" name="Sachbearbeiter" placeholder="Sachbearbeiter" className="form-input" value={values.sachbearbeiter || ''}
                              onChange={(event) => {
                                setFieldValue("sachbearbeiter", event.currentTarget.value);
                              }} />
                          </div>
                        </div>
                        <div className="col-md-6 "></div>
                        <div className="col-md-6 ">
                          <div className="form-wrap ">
                            <label className="form-label-outside" htmlFor="Telefon">{t('user.Telefon')}</label>
                            <Field id="Telefon" type="text" name="Telefon" placeholder="Telefon" className="form-input" value={values.telefon || ''}
                              onChange={(event) => {
                                setFieldValue("telefon", event.currentTarget.value);
                              }} />
                          </div>
                        </div>
                        <div className="col-md-6 "></div>
                        <div className="col-md-6 ">
                          <div className="form-wrap ">
                            <label className="form-label-outside" htmlFor="Email">{t('user.Email')}</label>
                            <Field id="Email" type="text" name="Email" placeholder="Email" className="form-input" value={values.email || ''}
                              onChange={(event) => {
                                setFieldValue("email", event.currentTarget.value);
                              }} />
                          </div>
                        </div>
                        <div className="col-md-6 "></div>
                        <div className="col-md-6 ">
                          <div className="form-wrap ">
                            <label className="form-label-outside" htmlFor="ATB-Lieferschein-Nr">{t('user.ATB-Lieferschein-Nr')}</label>
                            <Field id="ATB-Lieferschein-Nr" type="text" name="ATB-Lieferschein-Nr" placeholder="ATB-Lieferschein-Nr" className="form-input" value={values.lieferscheinNr || ''}
                              onChange={(event) => {
                                setFieldValue("lieferscheinNr", event.currentTarget.value);
                              }} />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <table className="table table-striped table-hover table-bordered product-list nowrap" >
                            <thead>
                              <tr>
                                <th>{t('user.ArtikeNr')}</th>
                                <th>{t('user.Menge')}</th>
                                <th>{t('user.Reason')}</th>
                                <th>{t('user.Description')}</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {values.items.map((r, index) => {
                                return (
                                  <tr key={"row_" + index}>
                                    <td>
                                      {r.artikeNr}<br />
                                      {r.artikeKtx}
                                    </td>
                                    <td>{r.menge}</td>
                                    <td>
                                      {reasonTree && r.reasonIds &&
                                        reasonTree.map((t, index) => {
                                          return (
                                            <div key={"reason_r_" + index}>
                                              {t.children.filter(c => r.reasonIds.includes(c.id.toString())).length > 0 &&
                                                <>
                                                  <b>{t.name}: </b>
                                                  {t.children.filter(c => r.reasonIds.includes(c.id.toString())).map((c, index) => {
                                                    return (
                                                      <span key={"cp_" + c.id}>
                                                        {c.name},
                                                      </span>
                                                    )
                                                  })
                                                  }
                                                </>
                                              }
                                            </div>
                                          )
                                        })
                                      }
                                    </td>
                                    <td>{r.description}</td>
                                    <td>
                                      <div className="group group-middle">
                                        <button className="button button-sm button-winona" style={{ padding: "5px" }} type="button" onClick={() => {
                                          dispatch(setCurrentUserClaimItem(r))
                                          setShow(true)
                                        }}>{t('user.edit')}</button>
                                        <button className="button button-sm button-winona" style={{ padding: "5px" }} type="button" onClick={() => {
                                          if (r.id) {
                                            dispatch(deleteUserClaimItemById({ id: currentClaim.id, claimItemId: r.id }))
                                          }
                                        }}>{t('user.delete')}</button>
                                      </div>
                                    </td>
                                  </tr>
                                )
                              })
                              }

                              <tr>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td>
                                  <button type="button" className="button button-sm button-winona" onClick={(e) => {
                                    dispatch(setCurrentUserClaimItem({}))
                                    setShow(true);
                                  }}>
                                    {t('user.addnewartikel')}
                                  </button>
                                </td>
                              </tr>

                            </tbody>
                          </table>
                        </div>

                        <div className="col-md-12 "></div>
                        <div className="col-md-6">
                          <Dropzone
                            inputContent="Images Upload"
                            accept="image/*"
                            getUploadParams={({ file, meta }) => {
                              const uuid = uuidv4();
                              const body = new FormData()
                              body.append('uuid', uuid)
                              body.append('file', file)
                              body.append('type', 'IMAGE')
                              meta["uuid"] = uuid;
                              let headers = []
                              headers["Authorization"] = `Bearer ${token}`
                              let url = `${getHost()}/user-api/v1/claims/upload-attachment/${uuid}`
                              if (values.id) {
                                url = `${getHost()}/user-api/v1/claims/${values.id}/upload-attachment/${uuid}`
                              }
                              return { url: url, headers, body }
                            }}
                            onChangeStatus={({ meta, file, remove, xhr }, status) => {
                              if (status === "done") {
                                file["uuid"] = meta.uuid
                                file["file-type"] = 'IMAGE'
                                file["file-name"] = meta.name
                                let response = JSON.parse(xhr.response)
                                file["file-path"] = response.meta["file-path"]

                                if (response.meta["id"]) {
                                  let uploadFiles = values.images
                                  uploadFiles.push(file)
                                  setFieldValue("images", uploadFiles);
                                  remove();
                                } else {
                                  let uploadFiles = values.tempImages
                                  uploadFiles.push(file)
                                  setFieldValue("tempImages", uploadFiles);
                                  remove();
                                }
                              }
                            }}
                            accept="image/*"
                          />
                          {values.tempImages && values.tempImages.length > 0 &&
                            <table className="table table-default">
                              <thead></thead>
                              <tbody>
                                {
                                  values.tempImages.map((attachment, index) => {
                                    return (
                                      <tr key={"temp_attachment_" + attachment["uuid"]}>
                                        <td>{attachment["file-name"]}</td>
                                        <td>
                                        </td>
                                      </tr>
                                    )
                                  })
                                }
                              </tbody>
                            </table>
                          }
                          {values.images && values.images.length > 0 &&
                            <table className="table table-default">
                              <thead></thead>
                              <tbody>
                                {
                                  values.images.map((attachment) => {
                                    return (
                                      <tr key={"attachment_" + attachment["uuid"]}>
                                        <td>{attachment["file-name"]}</td>
                                        <td>
                                        </td>
                                      </tr>
                                    )
                                  })
                                }
                              </tbody>
                            </table>
                          }
                        </div>

                        <div className="col-md-6">
                          <Dropzone
                            inputContent="Attachments Upload"
                            getUploadParams={({ file, meta }) => {
                              const uuid = uuidv4();
                              const body = new FormData()
                              body.append('uuid', uuid)
                              body.append('file', file)
                              body.append('type', 'ATTACHMENT')
                              meta["uuid"] = uuid;
                              let headers = []
                              headers["Authorization"] = `Bearer ${token}`
                              let url = `${getHost()}/user-api/v1/claims/upload-attachment/${uuid}`
                              if (values.id) {
                                url = `${getHost()}/user-api/v1/claims/${values.id}/upload-attachment/${uuid}`
                              }
                              return { url: url, headers, body }
                            }}
                            onChangeStatus={({ meta, file, remove, xhr }, status) => {
                              if (status === "done") {
                                file["uuid"] = meta.uuid
                                file["file-type"] = 'ATTACHMENT'
                                file["file-name"] = meta.name
                                let response = JSON.parse(xhr.response)
                                file["file-path"] = response.meta["file-path"]
                                if (response.meta["id"]) {
                                  let uploadFiles = values.attachments
                                  uploadFiles.push(file)
                                  setFieldValue("attachments", uploadFiles);
                                  remove();
                                } else {
                                  let uploadFiles = values.tempAttachments
                                  uploadFiles.push(file)
                                  setFieldValue("tempAttachments", uploadFiles);
                                  remove();
                                }
                              }
                            }}
                            accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          />


                          {values.tempAttachments && values.tempAttachments.length > 0 &&
                            <table className="table table-default">
                              <thead></thead>
                              <tbody>
                                {
                                  values.tempAttachments.map((attachment, index) => {
                                    return (
                                      <tr key={"temp_attachment_" + attachment["uuid"]}>
                                        <td>{attachment["file-name"]}</td>
                                        <td>
                                        </td>
                                      </tr>
                                    )
                                  })
                                }
                              </tbody>
                            </table>
                          }
                          {values.attachments && values.attachments.length > 0 &&
                            <table className="table table-default">
                              <thead></thead>
                              <tbody>
                                {
                                  values.attachments.map((attachment) => {
                                    return (
                                      <tr key={"attachment_" + attachment["uuid"]}>
                                        <td>{attachment["file-name"]}</td>
                                        <td>
                                        </td>
                                      </tr>
                                    )
                                  })
                                }
                              </tbody>
                            </table>
                          }
                        </div>

                        <div className="col-md-12 ">
                          <div className="form-wrap ">
                            <label className="form-label-outside" htmlFor="Bemerkung">{t('user.Bemerkung')}</label>
                            <Field id="Bemerkung" as="textarea" type="text" name="Bemerkung" placeholder="Bemerkung" className="form-input" value={values.bemerkung || ''}
                              onChange={(event) => {
                                setFieldValue("bemerkung", event.currentTarget.value);
                              }} />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <button className="button button-primary button-winona" type="submit" >{t('user.submitnewclaim')}</button>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment >
  )
}

export default withAuthenticationRequired(UserNewClaimView, {
  onRedirecting: () => <Loading />,
});
