import React, { Fragment, useEffect } from "react";

import Hero from "../components/Hero";
import { useDispatch, useSelector } from "react-redux";
import { fetchWebContents } from "../../redux/action/public/web-content.action";
import Script from "../useScript";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import { useTranslation } from "react-i18next";


const LeadTimes = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation('common');

  console.info("111", i18n.language);

  return (
    <Fragment>
      <section className="section section-lg">
        <div className="container">
          {i18n.language === 'en' &&
            <img src="/digicat/images/doc/Leadtimes_en.jpg" style={{ width: "100%" }}></img>
          }

          {i18n.language === 'de' &&
            <img src="/digicat/images/doc/Leadtimes_de.jpg" style={{ width: "100%" }}></img>
          }
        </div>
      </section>
    </Fragment>
  )
};

export default withAuthenticationRequired(LeadTimes, {
  onRedirecting: () => <Loading />,
});

