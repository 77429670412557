import { ADD_TO_RFQ, FETCH_RFQ_DETAIL_BY_ID_SUCCESS } from "../action/rfq.action";

const initialState = {
  items: [],
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_RFQ_DETAIL_BY_ID_SUCCESS:
      state = action.payload
      return {
        ...state,
      }
    default:
      return state;
  }
}