import React, { Fragment, useEffect } from "react";
import _ from 'lodash'
import { useDispatch, useSelector } from "react-redux";
import { fetchClaimList, postClaim } from "../../../redux/action/claim.action";
import { useHistory } from "react-router-dom";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { updateToken } from "../../../redux/action/auth.action";
import Loading from "../../components/Loading";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import { fetchUserClaimList } from "../../../redux/action/user/claim.action";
import {useTranslation} from "react-i18next";

const UserMyClaimsView = () => {
  const { t, i18n } = useTranslation('common');//国际化
  const dispatch = useDispatch();
  const redirect = useSelector(state => state.ui.redirect);
  let history = useHistory();
  const { user, getAccessTokenSilently } = useAuth0();

  const claims = useSelector(state => state.userClaims.list);

  useEffect(() => {
    getAccessTokenSilently().then(token => {
      dispatch(updateToken({ token: token }))
      dispatch(fetchUserClaimList())
    })
  }, [])


  const { SearchBar } = Search;

  function actionFormatter(cell, row) {
    return (
      <div className="group group-middle">
        <a href={"/user/new-claim?&id=" + row.id}>
          <button type="submit" className="button button-sm button-primary-outline button-winona" >{t('user.Edit')}</button>
        </a>
      </div>
    );
  }

  function conditionsFormatter(cell, row) {
    return (
      <Fragment key={"rule_" + row.id}>
        {row.properties &&
          row.properties.map(function (elem) {
            return elem.name;
          }).join("; ")
        }
      </Fragment>
    );
  }

  function groupInfoFormatter(cell, row) {
    return (
      <Fragment>
        {t('user.Name')}: {row.kundenname}<br />
        {t('user.Kundenummer')}: {row.kundennummer}<br />
        {t('user.LieferscheinNr')}: {row.lieferschein_nr}
      </Fragment>
    );
  }

  const columns = [{
    dataField: 'id',
    text: 'ID',
    sort: true,
    headerClasses: 'width-procent-10'
  }, {
    dataField: 'name',
    text: 'Claim',
    sort: true,
    formatter: groupInfoFormatter,
    headerClasses: 'width-procent-20'
  }, {
    dataField: 'bemerkung',
    text: 'Bemerkung',
  }, {
    dataField: 'action',
    text: 'Action',
    formatter: actionFormatter,
    headerClasses: 'width-procent-20'
  }];


  const options = {
    custom: true,
    totalSize: claims.length
  };

  return (
    <Fragment>
      <section className="section section-lg text-center">
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <div className="group group-middle">
                <a href="/user/new-claim">
                  <button type="button" className="button" >{t('user.Addnewclaim')}</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {claims && claims.length > 0 &&
        <section className="section section-lg text-center">
          <div className="container">
            <PaginationProvider
              pagination={paginationFactory(options)}
            >
              {
                ({
                  paginationProps,
                  paginationTableProps,
                }) => (
                  <div>
                    <PaginationListStandalone
                      {...paginationProps}
                    />
                    <ToolkitProvider
                      bootstrap4
                      keyField="id"
                      data={claims}
                      columns={columns}
                      search
                    >
                      {
                        toolkitprops => (
                          <div>
                            <div style={{ float: "left", padding: "10px 0px" }}>
                              <SearchBar {...toolkitprops.searchProps} style={{ float: "left" }} placeholder="Search Category Name / ID" />
                            </div>
                            <BootstrapTable
                              striped
                              hover
                              {...toolkitprops.baseProps}
                              {...paginationTableProps}
                            />
                          </div>
                        )
                      }
                    </ToolkitProvider>
                    <PaginationListStandalone
                      {...paginationProps}
                    />
                  </div>

                )
              }
            </PaginationProvider>
          </div>
        </section>
      }

    </Fragment>
  )
}

export default withAuthenticationRequired(UserMyClaimsView, {
  onRedirecting: () => <Loading />,
});
