export const  FETCH_RULES = "fetch rules"
export const  FETCH_RULES_SUCCESS = "fetch rules success"
export const  FETCH_RULES_FAILURE = "fetch rules failure"

export const  FETCH_ALL_RULES = "fetch all rules"
export const  FETCH_ALL_RULES_SUCCESS = "fetch all rules success"
export const  FETCH_ALL_RULES_FAILURE = "fetch all rules failure"

export const  ADD_UI_RULE = "add ui rule"
export const  ADD_UI_RULE_SUCCESS = "add ui rule success"
export const  ADD_UI_RULE_FAILURE = "add ui rule failure"

export const  DELETE_UI_RULE = "delete ui rule"
export const  DELETE_UI_RULE_SUCCESS = "delete ui rule success"
export const  DELETE_UI_RULE_FAILURE = "delete ui rule failure"

export const  COPY_UI_RULE = "copy ui rule"
export const  COPY_UI_RULE_SUCCESS = "copy ui rule success"
export const  COPY_UI_RULE_FAILURE = "copy ui rule failure"

export const  RESET_CURRENT_EDIT_RULE = "reset current edit rule"

export const  FETCH_EDIT_RULE_BY_ID = "fetch rule by id"
export const  FETCH_EDIT_RULE_BY_ID_SUCCESS = "fetch rule by id success"
export const  FETCH_EDIT_RULE_BY_ID_FAILURE = "fetch rule by id failure"


export const fetchAllRules = (data) => ({
  type: FETCH_ALL_RULES,
  payload: data
});

export const fetchRules = (data) => ({
  type: FETCH_RULES,
  payload: data
});

export const addUiRule = (data) => ({
  type: ADD_UI_RULE,
  payload: data
});

export const deleteUiRule = (data) => ({
  type: DELETE_UI_RULE,
  payload: data
});

export const copyUiRule = (data) => ({
  type: COPY_UI_RULE,
  payload: data
});

export const resetCurrentEditRule = (data) => ({
  type: RESET_CURRENT_EDIT_RULE,
  payload: data
});

export const fetchEditRuleById = (data) => ({
  type: FETCH_EDIT_RULE_BY_ID,
  payload: data
});



