export const FETCH_USERS = "fetch users"
export const FETCH_USERS_SUCCESS = "fetch users success"
export const FETCH_USERS_FAILURE = "fetch users failure"

export const FETCH_USER_BY_ID = "fetch user by id"
export const FETCH_USER_BY_ID_SUCCESS = "fetch user by id success"
export const FETCH_USER_BY_ID_FAILURE = "fetch user by id failure"

export const ADD_CUSTOMER_TO_USER = "add customer to user"
export const ADD_CUSTOMER_TO_USER_SUCCESS = "add customer to user success"
export const ADD_CUSTOMER_TO_USER_FAILURE = "add customer to user failure"

export const REMOVE_USER_CUSTOMER = "remove user customer"
export const REMOVE_USER_CUSTOMER_SUCCESS = "remove user customer success"
export const REMOVE_USER_CUSTOMER_FAILURE = "remove user customer failure"

export const SET_CURRENT_USER = "set current user"

export const fetchUsers = (data) => ({
  type: FETCH_USERS,
  payload: data
});

export const fetchUserById = (data) => ({
  type: FETCH_USER_BY_ID,
  payload: data
});

export const addCustomerToUser = (data) => ({
  type: ADD_CUSTOMER_TO_USER,
  payload: data
});

export const removeUserCustomer = (data) => ({
  type: REMOVE_USER_CUSTOMER,
  payload: data
});

export const setCurrentUser = (data) => ({
  type: SET_CURRENT_USER,
  payload: data
});
