import React, { useEffect, useState } from "react";
import _ from 'lodash'
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Configurator } from "../components/Configurator";
import { ConfiguratorSteps } from "../components/ConfiguratorSteps";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { resetSteps } from "../../redux/action/step.action";
import { Engine } from "json-rules-engine";
import { addToBlockArray, addToDefaultArray, addToMaxValueArray, removeFromBlockArray, removeFromDefaultArray, removeFromMaxValueArray } from "../../redux/action/ui.action";
import { fetchRules } from "../../redux/action/rules.action";
import { fetchCountryGroup, fetchCountryGroupCategory } from "../../redux/action/country.action";
import { fetchCategoyList } from "../../redux/action/category.action";
import { updateToken } from "../../redux/action/auth.action";
import { addToAufpreisen, fetchPriceRules, removeFromAufpreisen } from "../../redux/action/user/price-rule.action";
import { fetchUserProfile } from "../../redux/action/user/user.action";
import { Redirect, useHistory } from "react-router";
import {useTranslation} from "react-i18next";

async function checkRule(rules, facts) {
  const engine = new Engine()
  rules.forEach(r => engine.addRule(r))
  const { events, failureEvents } = await engine.run(facts);
  return { events, failureEvents }
}

export const ConfiguratorWrapper = () => {
  const { t, i18n } = useTranslation('common');//国际化
  const dispatch = useDispatch()
  const rules = useSelector(state => state.rules.rules);
  const priceRules = useSelector(state => state.priceRule.rules);

  const { user, getAccessTokenSilently } = useAuth0();
  const categoryList = useSelector(state => state.category.list);
  const userProfile = useSelector(state => state.user.profile);

  let aufpreis = useSelector(state => state.priceRule.currentAufpreisen);
  let changedCategoryIds = useSelector(state => state.ui.changedCategoryIds);

  let history = useHistory();

  useEffect(() => {
    dispatch(resetSteps())
    getAccessTokenSilently().then(token => {
      dispatch(fetchUserProfile())
      dispatch(fetchRules({ token: token }))
      dispatch(fetchCountryGroup({ token: token }))
      dispatch(fetchCountryGroupCategory({ token: token }))
      dispatch(fetchCategoyList({ token: token }))
      dispatch(updateToken({ token: token }))
      dispatch(fetchPriceRules())
    })
  }, [])

  useEffect(() => {
    if (userProfile.id && !userProfile.company) {
      history.push("/user/profile")
    }
  }, [userProfile])

  async function checkUiRuls(rules, values) {
    if (rules) {

      let rulesArray = []
      rules.forEach(rule => {
        let r = {
          conditions: "",
          event: {
            type: 'block',
            params: []
          }
        };

        r.conditions = JSON.parse(rule.conditions)
        rule.uiRuleEvents.forEach(p => {
          r.event.params.push(JSON.parse(p.eventParams))
        })
        rulesArray.push(r)
      })

      const facts = { product: values["product"], values: values };
      if (values["product"]) {
        facts.product.baugroesse = facts.product.baugroesse.toString();
      }

      checkRule(rulesArray, facts).then(allEvents => {
        let removeBlocks = []
        let removeDefaults = []
        let removeMaxValues = []
        allEvents.failureEvents.forEach(event => {
          if (event.type === 'block') {
            event.params.forEach(p => {
              if (p.eventType === 'BLOCK') {
                removeBlocks.push(p.name + "_" + p.id)
              } else if (p.eventType === 'DEFAULT') {
                removeDefaults.push({ name: p.name, id: p.id, value: p.value })
              } else if (p.eventType === 'CHECK_MAX_VALUE') {
                removeMaxValues.push({ name: p.name + "_" + p.id, value: p.value })
              }
            })
          }
        })
        dispatch(removeFromBlockArray(removeBlocks))
        dispatch(removeFromDefaultArray(removeDefaults))
        dispatch(removeFromMaxValueArray(removeMaxValues))


        let addBlocks = []
        let addDefaults = []
        let addMaxValues = []
        allEvents.events.forEach(event => {
          if (event.type === 'block') {
            event.params.forEach(p => {
              if (p.eventType === 'BLOCK') {
                addBlocks.push(p.name + "_" + p.id)
                if (p.name === "category") {
                  delete values[`category_${p.id}`]
                }
              } else if (p.eventType === 'DEFAULT') {
                addDefaults.push({ name: p.name, id: p.id, value: p.value })
                let categroy = categoryList.find(x => x.id.toString() === p.id.toString())
                if (!values[p.name + "_" + p.id]) {
                  values[p.name + "_" + p.id] = { category: categroy }
                  values[p.name + "_" + p.id]["value"] = ""
                }
                if (categroy.categoryType === "CHECKBOX") {
                  values[p.name + "_" + p.id]["value"] = values[p.name + "_" + p.id]["value"].replaceAll(";" + p.categoryPropertyId + ";", "")
                  values[p.name + "_" + p.id]["value"] = values[p.name + "_" + p.id]["value"] + ";" + p.categoryPropertyId + ";"
                } else if (!values[p.name + "_" + p.id]["value"] || !changedCategoryIds.has(p.id)){
                  values[p.name + "_" + p.id]["value"] = p.value
                }

              } else if (p.eventType === 'CHECK_MAX_VALUE') {
                addMaxValues.push({ name: p.name + "_" + p.id, value: p.value })
              }
            })
          }
        })
        dispatch(addToBlockArray(addBlocks))
        dispatch(addToDefaultArray(addDefaults))
        dispatch(addToMaxValueArray(addMaxValues))

        return addBlocks
      })
    }
  }

  async function checkPriceRule(rules, values) {
    if (priceRules) {
      let priceRulesArray = []
      priceRules.forEach(rule => {
        let r = {
          conditions: "",
          event: {
            type: 'add',
            params: []
          }
        };

        r.conditions = JSON.parse(rule.conditions)
        rule.priceRuleEvents.forEach(p => {
          r.event.params.push(JSON.parse(p.eventParams))
        })
        priceRulesArray.push(r)
      })

      const facts = { product: values["product"], values: values };
      if (values["product"]) {
        facts.product.baugroesse = facts.product.baugroesse.toString();
      }

      checkRule(priceRulesArray, facts).then(allEvents => {
        let removeAufpreisen = []
        allEvents.failureEvents.forEach(event => {
          event.params.forEach(p => {
            removeAufpreisen.push({ key: "category_" + p.categoryId + "_baugroesse_" + p.baugroesse })
          })
        })

        dispatch(removeFromAufpreisen(removeAufpreisen))

        let addAufpreisen = []
        allEvents.events.forEach(event => {
          event.params.forEach(p => {
            addAufpreisen.push({ key: "category_" + p.categoryId + "_baugroesse_" + p.baugroesse, value: p.aufpreis, prozentValue: p.aufpreisProzent, leadTimeWeeks:p.leadTimeWeeks, eventType: p.eventType })
          })
        })

        dispatch(addToAufpreisen(addAufpreisen))
      })

    }
  }

  async function checkCountRule(values, errors) {
    if (values['product']?.baugroesse) {
      let maxCounter = categoryList.filter(c => c.id === 58)[0].properties.filter(p => p.name === values['product'].baugroesse)[0].counter;
      let sumCounter = Object.keys(values).filter(k => k.startsWith("category_")).map(key => values[key].counter || 0).reduce((prev, next) => prev + next);
      if (sumCounter > maxCounter) {
        Object.keys(values).filter(k => k.startsWith("category_")).filter(key => values[key].counter > 0).map(key => errors[key] = "combination not possible")
      }
    }
  }

  async function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  return (
    <>
      <Formik
        initialValues={{ quantity: 1, changedCategory: {} }}
        enableReinitialize={false}
        validate={async (values) => {
          const errors = {};
          if (values.changedCategory?.categoryType !== 'CHECKBOX') {
            await checkUiRuls(rules, values);
            await timeout(500);
            await checkUiRuls(rules, values);
            await timeout(500);
            await checkUiRuls(rules, values);
            await timeout(500);
            await checkUiRuls(rules, values);
            await timeout(500);
            await checkPriceRule(priceRules, values);
            await checkCountRule(values, errors)
          }

          if (values["max_errors"]) {
            errors[values["max_errors"].name] = values["max_errors"].message
            delete values["max_errors"]
          }
          return errors;
        }}

        onSubmit={(values) => {

        }}>
        {({ values, errors, handleSubmit, setFieldValue, setValues }) => (
          <form onSubmit={handleSubmit} autoComplete="off" className="">
            <ConfiguratorSteps />
            <Configurator />
          </form>
        )}
      </Formik>
    </>
  );
};

export default withAuthenticationRequired(ConfiguratorWrapper, {
  onRedirecting: () => <Loading />,
});
