import { apiRequest } from "../../action/api.action"
import { FETCH_SUGGESTIONS_FAILURE, FETCH_SUGGESTIONS_SUCCESS, GET_CUSTOMER_SUGGESTIONS, GET_MATERIAL_SUGGESTIONS } from "../../action/internal/suggestions.action";
import { getHost } from "../../../react/utils/host"

export const suggestionsFlow = ({ dispatch }) => next => action => {

  next(action)

  if (action.type === GET_MATERIAL_SUGGESTIONS) {
    let url = `${getHost()}/internal-api/v1/search/materials?term=${action.payload}`;
    dispatch(apiRequest('GET', url, null, FETCH_SUGGESTIONS_SUCCESS, FETCH_SUGGESTIONS_FAILURE));
  }

  if (action.type === GET_CUSTOMER_SUGGESTIONS) {
    let url = `${getHost()}/internal-api/v1/search/customers?term=${action.payload}`;
    dispatch(apiRequest('GET', url, null, FETCH_SUGGESTIONS_SUCCESS, FETCH_SUGGESTIONS_FAILURE));
  }
};

export const suggestionsMdl = [suggestionsFlow];