import { CLEAN_SUGGESTIONS, FETCH_SUGGESTIONS_SUCCESS } from "../action/internal/suggestions.action";

const initialState = [];

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SUGGESTIONS_SUCCESS:
      return action.payload;
    case CLEAN_SUGGESTIONS:
      return initialState;
    default:
      return state;
  }
}