export const FETCH_WEB_CONTENTS = "fetch web contents"
export const FETCH_WEB_CONTENTS_SUCCESS = "fetch web contents success"
export const FETCH_WEB_CONTENTS_FAILURE = "fetch web contents failure"

export const fetchWebContents = (data) => ({
  type: FETCH_WEB_CONTENTS,
  payload: data
});







