import { apiRequest } from "../../action/api.action"
import { getHost } from "../../../react/utils/host"
import { FETCH_PRICE_RULES, FETCH_PRICE_RULES_FAILURE, FETCH_PRICE_RULES_SUCCESS } from "../../action/user/price-rule.action";

export const priceRuleFlow = ({ dispatch }) => next => action => {
  next(action)
  if (action.type === FETCH_PRICE_RULES) {
    let url = `${getHost()}/api/v1/price-rules`
    dispatch(apiRequest("GET", url, null, FETCH_PRICE_RULES_SUCCESS, FETCH_PRICE_RULES_FAILURE));
  }
};

export const priceRuleMdl = [priceRuleFlow];