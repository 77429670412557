import { apiRequest } from "../../action/api.action"
import { getHost } from "../../../react/utils/host"
import { ADD_AUFPREIS_RULE, ADD_AUFPREIS_RULE_FAILURE, ADD_AUFPREIS_RULE_SUCCESS, DELETE_AUFPREIS_RULE, DELETE_AUFPREIS_RULE_FAILURE, DELETE_AUFPREIS_RULE_SUCCESS, FETCH_AUFPREIS_RULES, FETCH_AUFPREIS_RULES_FAILURE, FETCH_AUFPREIS_RULES_SUCCESS, FETCH_EDIT_AUFPREIS_RULE_BY_ID, FETCH_EDIT_AUFPREIS_RULE_BY_ID_FAILURE, FETCH_EDIT_AUFPREIS_RULE_BY_ID_SUCCESS } from "../../action/admin/aufpreisRules.action";

export const adminAufpreisRuleFlow = ({ dispatch }) => next => action => {
  next(action)
  if (action.type === FETCH_AUFPREIS_RULES || action.type === DELETE_AUFPREIS_RULE_SUCCESS || action.type === ADD_AUFPREIS_RULE_SUCCESS) {
    let url = `${getHost()}/api/v1/admin/price-rules`
    dispatch(apiRequest("GET", url, null, FETCH_AUFPREIS_RULES_SUCCESS, FETCH_AUFPREIS_RULES_FAILURE));
  }
  if (action.type === DELETE_AUFPREIS_RULE) {
    let url = `${getHost()}/api/v1/admin/price-rules/${action.payload.id}`
    dispatch(apiRequest("DELETE", url, null, DELETE_AUFPREIS_RULE_SUCCESS, DELETE_AUFPREIS_RULE_FAILURE));
  }

  if (action.type === ADD_AUFPREIS_RULE) {
    let url = `${getHost()}/api/v1/admin/price-rules`
    dispatch(apiRequest("POST", url, action.payload, ADD_AUFPREIS_RULE_SUCCESS, ADD_AUFPREIS_RULE_FAILURE));
  }

  if (action.type === FETCH_EDIT_AUFPREIS_RULE_BY_ID) {
    let url = `${getHost()}/api/v1/admin/price-rules/${action.payload.id}`
    dispatch(apiRequest("GET", url, null, FETCH_EDIT_AUFPREIS_RULE_BY_ID_SUCCESS, FETCH_EDIT_AUFPREIS_RULE_BY_ID_FAILURE));
  }
};

export const adminAufpreisRuleMdl = [adminAufpreisRuleFlow];