export const GET_MATERIAL_SUGGESTIONS = "Get Material Suggestions"
export const GET_CUSTOMER_SUGGESTIONS = "Get Customer Suggestions"

export const CLEAN_SUGGESTIONS = "Clean Suggestions"
export const FETCH_SUGGESTIONS_SUCCESS = "Get Suggestions success"
export const FETCH_SUGGESTIONS_FAILURE = "Get Suggestions failure"

export const GET_PORT_SUGGESTIONS = "Get Port Suggestion"

export const getMaterialSuggestions = (data) => ({
  type: GET_MATERIAL_SUGGESTIONS,
  payload: data,
});

export const getCustomerSuggestions = (data) => ({
  type: GET_CUSTOMER_SUGGESTIONS,
  payload: data,
});

export const cleanSuggestions = () => ({
  type: CLEAN_SUGGESTIONS,
});
