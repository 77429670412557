import React, { Fragment, useEffect, useState, useCallback } from "react";
import { Field, Formik } from "formik";
import { Button, Collapse, Modal } from 'react-bootstrap';
import _ from 'lodash'
import { useDispatch, useSelector } from "react-redux";
import { addClaimItemById, deleteClaimAttachmentById, deleteClaimItemById, fetchClaimById, fetchClaimReasonTree, postClaim, setCurrentClaimItem, submitClaimById } from "../../redux/action/claim.action";
import { browserHistory } from 'react-router'
import { useHistory } from "react-router-dom";
import { cleanRedirect } from "../../redux/action/ui.action";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { updateToken } from "../../redux/action/auth.action";
import Loading from "../components/Loading";
import EditClaimItem from "../components/EditClaimItem.Component";
import uuid from 'react-uuid'
import { ClaimClassificationMap, ClaimReasonMap } from "../utils/maps"
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import { v4 as uuidv4 } from 'uuid';
import { getHost } from "../utils/host";
import "../styles/dropzone_style.css"
import ViewClaimItem from "../components/internal/ViewClaimItem.Component";
import moment from 'moment';
import ClaimProcess from "../components/internal/ClaimProcess.Component";
import { AuthenticatedLink } from "../components/AuthenticatedLink";
import {useTranslation} from "react-i18next";

function getParams(location, name) {
  const searchParams = new URLSearchParams(location.search);
  let ret = {};
  ret[name] = searchParams.get(name) || "";
  return ret;
}

const ClaimView = () => {
  const { t, i18n } = useTranslation('common');//国际化
  const dispatch = useDispatch();
  const redirect = useSelector(state => state.ui.redirect);
  let history = useHistory();
  const { user, getAccessTokenSilently } = useAuth0();
  let currentClaim = useSelector(state => state.claims.current);
  let seq = useSelector(state => state.claims.seq);
  const [currentClaimItem, setCurrentClaimItem] = useState({});
  const [currentClaimItemIndex, setCurrentClaimItemIndex] = useState(-1);
  const reasonTree = useSelector(state => state.reasons.tree);
  const token = useSelector(state => state.auth.token);

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (redirect) {
      history.push(redirect)
      dispatch(cleanRedirect())
    }
  }, [redirect])

  useEffect(() => {
    getAccessTokenSilently().then(token => {
      dispatch(updateToken({ token: token }))
      let urlParams = getParams(window.location, "id");
      if (urlParams.id) {
        dispatch(fetchClaimById({ id: urlParams.id, token: token }))
      }
      dispatch(fetchClaimReasonTree())
    })
  }, [])

  useEffect(() => {
  }, [seq, currentClaim, reasonTree])


  return (
    <Fragment>

      <section className="section section-lg">
        <div className="range justify-content-xl-between">
          <div className="cell-xl-12 align-self-center container">
            <div className="row">
              <div className="col-lg-12 cell-inner">
                <h3 className=""><span className=" slideInDown">{t('home.Reklamation')}</span></h3>
                <Formik
                  initialValues={currentClaim}
                  enableReinitialize={true}
                  validate={values => {
                    const errors = {};
                    console.info("validate form values: ", values);
                    return errors;
                  }}
                  onSubmit={(values) => {
                    dispatch(postClaim({ parameters: values }))
                  }}>
                  {({ values, errors, handleSubmit, setFieldValue, setValues }) => (
                    <form onSubmit={handleSubmit} autoComplete="off" className="">
                      <Modal show={show} size="xl" dialogClassName="modal-details">
                        <Modal.Header>
                          <Modal.Title>{t('home.ClaimItem')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <ViewClaimItem item={currentClaimItem} claim={values} setItem={setCurrentClaimItem} index={currentClaimItemIndex} />
                        </Modal.Body>
                        <Modal.Footer>
                          <Button className="button button-icon button-icon-left button-winona" onClick={(e) => {
                            setShow(false);
                          }}>
                            {t('home.Close')}
                          </Button>
                        </Modal.Footer>
                      </Modal>

                      <ClaimProcess />

                      <div className="row row-10">
                        {values.id &&
                          <div className="col-md-6 ">
                            <div className="form-wrap  fadeSlideInUp">
                              <label className="form-label-outside" htmlFor="id">{t('home.ID')}</label>
                              <Field disabled id="id" type="text" name="id" className="form-input" value={values.id || ''}
                                disabled />
                            </div>
                          </div>
                        }
                        {!values.id &&
                          <div className="col-md-6 "></div>
                        }

                        <div className="col-md-6 ">
                          <div className="form-wrap  fadeSlideInUp">
                            <label className="form-label-outside" htmlFor="alertMail">{t('home.AlertMails')}</label>
                            <Field disabled id="alertMail" type="text" name="alertMail" className="form-input" value={values.alertTo || ''}
                              onChange={(event) => {
                                setFieldValue("alertTo", event.currentTarget.value);
                              }} />
                          </div>
                        </div>
                        <div className="col-md-3 ">
                          <div className="form-wrap  fadeSlideInUp">
                            <label className="form-label-outside" htmlFor="Kundenname">{t('home.Kundenname')}</label>
                            <Field disabled id="Kundenname" type="text" name="Kundenname" className="form-input" value={values.kundenname || ''}
                              onChange={(event) => {
                                setFieldValue("kundenname", event.currentTarget.value);
                              }} />
                          </div>
                        </div>
                        <div className="col-md-3 ">
                          <div className="form-wrap  fadeSlideInUp">
                            <label className="form-label-outside" htmlFor="Kundennummer">{t('home.Kundennummer')}</label>
                            <Field disabled id="Kundennummer" type="text" name="Kundennummer" className="form-input" value={values.kundennummer || ''}
                              onChange={(event) => {
                                setFieldValue("kundennummer", event.currentTarget.value);
                              }} />
                          </div>
                        </div>
                        <div className="col-md-6 ">
                          <div className="form-wrap  fadeSlideInUp">
                            <label className="form-label-outside" htmlFor="status">{t('home.Status')}:</label>
                            <Field disabled id="status" type="text" name="status" className="form-input" value={values.status || ''}
                              onChange={(event) => {
                              }} />
                          </div>
                        </div>
                        <div className="col-md-6 ">
                          <div className="form-wrap  fadeSlideInUp">
                            <label className="form-label-outside" htmlFor="Sachbearbeiter">{t('home.Sachbearbeiter')}</label>
                            <Field disabled id="Sachbearbeiter" type="text" name="Sachbearbeiter" className="form-input" value={values.sachbearbeiter || ''}
                              onChange={(event) => {
                                setFieldValue("sachbearbeiter", event.currentTarget.value);
                              }} />
                          </div>
                        </div>
                        <div className="col-md-6 ">
                          <div className="form-wrap  fadeSlideInUp">
                            <label className="form-label-outside" htmlFor="createdBy">{t('home.Createdby')}:</label>
                            <Field disabled id="createdBy" type="text" name="createdBy" className="form-input" value={values.user?.name || ''}
                              onChange={(event) => {
                              }} />
                          </div>
                        </div>
                        <div className="col-md-6 ">
                          <div className="form-wrap  fadeSlideInUp">
                            <label className="form-label-outside" htmlFor="Telefon">{t('home.Telefon')}</label>
                            <Field disabled id="Telefon" type="text" name="Telefon" className="form-input" value={values.telefon || ''}
                              onChange={(event) => {
                                setFieldValue("telefon", event.currentTarget.value);
                              }} />
                          </div>
                        </div>
                        <div className="col-md-6 ">
                          <div className="form-wrap  fadeSlideInUp">
                            <label className="form-label-outside" htmlFor="submitDate">{t('home.Submitat')}:</label>
                            <Field disabled id="submitDate" type="text" name="submitDate" className="form-input" value={moment(values.submitDate).format('YYYY-MM-DD') || ''}
                              onChange={(event) => {
                              }} />
                          </div>
                        </div>
                        <div className="col-md-6 ">
                          <div className="form-wrap  fadeSlideInUp">
                            <label className="form-label-outside" htmlFor="Email">{t('home.Email')}</label>
                            <Field disabled id="Email" type="text" name="Email" className="form-input" value={values.email || ''}
                              onChange={(event) => {
                                setFieldValue("email", event.currentTarget.value);
                              }} />
                          </div>
                        </div>
                        <div className="col-md-6 ">
                          <div className="form-wrap  fadeSlideInUp">
                            <label className="form-label-outside" htmlFor="creationDate">{t('home.Createdat')}:</label>
                            <Field disabled id="creationDate" type="text" name="creationDate" className="form-input" value={moment(values.creationDate).format('YYYY-MM-DD') || ''}
                              onChange={(event) => {
                              }} />
                          </div>
                        </div>
                        <div className="col-md-6 ">
                          <div className="form-wrap  fadeSlideInUp">
                            <label className="form-label-outside" htmlFor="ATB-Lieferschein-Nr">{t('home.ATB-Lieferschein-Nr')}</label>
                            <Field disabled id="ATB-Lieferschein-Nr" type="text" name="ATB-Lieferschein-Nr" className="form-input" value={values.lieferscheinNr || ''}
                              onChange={(event) => {
                                setFieldValue("lieferscheinNr", event.currentTarget.value);
                              }} />
                          </div>
                        </div>
                        <div className="col-md-6 "></div>
                        <div className="col-md-6 ">
                          <div className="form-wrap  fadeSlideInUp">
                            <label className="form-label-outside" htmlFor="ATB-Auftrag-Nr">{t('home.ATB-Auftrag-Nr')}</label>
                            <Field disabled id="ATB-Auftrag-Nr" type="text" name="ATB-Auftrag-Nr" className="form-input" value={values.auftragNr || ''}
                              onChange={(event) => {
                                setFieldValue("auftragNr", event.currentTarget.value);
                              }} />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <table className="table table-striped table-hover table-bordered product-list nowrap" >
                            <thead>
                              <tr>
                                <th className="width-procent-20">{t('home.SerialNr')}</th>
                                <th className="width-procent-20">{t('home.ArtikeNr')}</th>
                                <th className="width-procent-10">{t('home.Menge')}</th>
                                <th className="width-procent-15">{t('home.Reasons')}</th>
                                <th className="width-procent-20">{t('home.Description')}</th>
                                <th className="width-procent-5"></th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {values.items.map((r, index) => {
                                return (
                                  <tr key={"row_" + index}>
                                    <td>{r.serialNr}</td>
                                    <td>
                                      {r.artikeNr}<br />
                                      {r.artikeKtx}
                                    </td>
                                    <td>{r.menge}</td>
                                    <td>
                                      {reasonTree &&
                                        reasonTree.map((t, index) => {
                                          return (
                                            <div key={"reason_r_" + index}>
                                              {t.children.filter(c => r.reasonIds.includes(c.id.toString())).length > 0 &&
                                                <>
                                                  <b>{t.name}: </b>
                                                  {t.children.filter(c => r.reasonIds.includes(c.id.toString())).map((c, index) => {
                                                    return (
                                                      <span key={"cp_" + c.id}>
                                                        {c.name},
                                                      </span>
                                                    )
                                                  })
                                                  }
                                                </>
                                              }
                                            </div>
                                          )
                                        })
                                      }
                                    </td>
                                    <td>{r.description}</td>
                                    <td>
                                      {(r.images.length > 0 || r.attachments.length > 0 || r.tempImages.length > 0 || r.tempAttachments.length > 0) &&
                                        <i className="icon icon-sm link-default mdi mdi-paperclip pointer"></i>
                                      }
                                    </td>
                                    <td>
                                      <div className="group group-middle">
                                        <button className="button button-sm button-primary button-winona" style={{ padding: "5px" }} type="button" onClick={() => {
                                          setCurrentClaimItem(r)
                                          setCurrentClaimItemIndex(index)
                                          setShow(true)
                                        }}>{t('home.details')}</button>
                                      </div>
                                    </td>
                                  </tr>
                                )
                              })
                              }
                            </tbody>
                          </table>
                        </div>
                        <div className="col-md-12 ">
                          <div className="form-wrap  fadeSlideInUp">
                            <label className="form-label-outside" htmlFor="Bemerkung">{t('home.Bemerkung')}</label>
                            <Field disabled id="Bemerkung" as="textarea" type="text" name="Bemerkung" className="form-input" value={values.bemerkung || ''}
                              onChange={(event) => {
                                setFieldValue("bemerkung", event.currentTarget.value);
                              }} />
                          </div>
                        </div>

                        <div className="col-md-6">
                          {values.images && values.images.length > 0 &&
                            <table className="table table-default">
                              <thead></thead>
                              <tbody>
                                {
                                  values.images.map((attachment) => {
                                    return (
                                      <tr key={"attachment_" + attachment["uuid"]}>
                                        <td>{attachment["file-name"]}</td>
                                        <td>
                                          <AuthenticatedLink url={`/internal-api/v1/claims/download`} filepath={attachment["file-path"]} filename={attachment["file-name"]}>
                                            <i className="icon icon-sm link-default mdi mdi-eye"></i>
                                          </AuthenticatedLink>
                                          <AuthenticatedLink url={`/internal-api/v1/claims/download`} filepath={attachment["file-path"]} filename={attachment["file-name"]} downloadLink={true}>
                                            <i className="icon icon-sm link-default mdi mdi-download"></i>
                                          </AuthenticatedLink>
                                        </td>
                                      </tr>
                                    )
                                  })
                                }
                              </tbody>
                            </table>
                          }
                        </div>

                        <div className="col-md-6">
                          {values.attachments && values.attachments.length > 0 &&
                            <table className="table table-default">
                              <thead></thead>
                              <tbody>
                                {
                                  values.attachments.map((attachment) => {
                                    return (
                                      <tr key={"attachment_" + attachment["uuid"]}>
                                        <td>{attachment["file-name"]}</td>
                                        <td>
                                          <AuthenticatedLink url={`/internal-api/v1/claims/download`} filepath={attachment["file-path"]} filename={attachment["file-name"]}>
                                            <i className="icon icon-sm link-default mdi mdi-eye"></i>
                                          </AuthenticatedLink>
                                          <AuthenticatedLink url={`/internal-api/v1/claims/download`} filepath={attachment["file-path"]} filename={attachment["file-name"]} downloadLink={true}>
                                            <i className="icon icon-sm link-default mdi mdi-download"></i>
                                          </AuthenticatedLink>
                                        </td>
                                      </tr>
                                    )
                                  })
                                }
                              </tbody>
                            </table>
                          }
                        </div>

                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  )
}

export default withAuthenticationRequired(ClaimView, {
  onRedirecting: () => <Loading />,
});
