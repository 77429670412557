import { apiRequest } from "../../action/api.action"
import { getHost } from "../../../react/utils/host"
import { FETCH_WEB_CONTENTS, FETCH_WEB_CONTENTS_FAILURE, FETCH_WEB_CONTENTS_SUCCESS } from "../../action/public/web-content.action";

export const webContentsFlow = ({ dispatch }) => next => action => {
  next(action)
  if (action.type === FETCH_WEB_CONTENTS) {
    let url = `${getHost()}/public-api/v1/web-contents`
    dispatch(apiRequest("GET", url, null, FETCH_WEB_CONTENTS_SUCCESS, FETCH_WEB_CONTENTS_FAILURE));
  }

};

export const webContentsMdl = [webContentsFlow];