import { DELETE_UI_RULE, FETCH_EDIT_RULE_BY_ID, FETCH_EDIT_RULE_BY_ID_SUCCESS, FETCH_RULES_SUCCESS, RESET_CURRENT_EDIT_RULE } from "../action/rules.action";
import _ from 'lodash'

const initialState = {
  rules:[],
  currentEditRule: {
    constaintCategories: [], 
    eventCategories: []
  },
  categoryWithRules:[]
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_EDIT_RULE_BY_ID_SUCCESS:
      let categories = {}
      action.payload.categoryValues.forEach(element => {
        categories[_.snakeCase(element.name)] = element.values
        categories[_.snakeCase(element.name) + "_default"] = element.defaultValue
        categories[_.snakeCase(element.name) + "_default_values"] = element.defaultValues
        categories[_.snakeCase(element.name) + "_value_constraint"] = element.valueConstraint
        categories[_.snakeCase(element.name) + "_block_category"] = element.blockCategory
      }); 

      return {
        ...state,
        currentEditRule: {
          ...action.payload,
          ...categories
        }
      }
    case RESET_CURRENT_EDIT_RULE:
      return {
        ...state,
        currentEditRule: {
          constaintCategories: [], 
          eventCategories: []
        }
      }
    case FETCH_RULES_SUCCESS:
      return {
        ...state,
        rules: action.payload
      }
    default:
      return state;
  }
}