import { combineReducers } from "redux";
import ui from './ui.reducer'
import category from './category.reducer'
import productComposite from './productComposite.reducer'
import steps from './steps.reducer'
import productPrice from './product-price.reducer'
import rfq from './rfq.reducer'
import rfqHistory from './rfqHistory.reducer'
import aufpreis from './admin/aufpreis.reducer'
import rfqDetail from './rfqDetail.reducer'
import rules from './rules.reducer'
import country from './country.reducer'
import auth from './auth.reducer'
import claimUi from './claimUi.reducer'
import claims from './claims.reducer'
import userClaims from './user/claims.reducer'
import priceRule from './user/price-rule.reducer'
import webContents from './public/web-contents.reducer'
import user from './user/user.reducer'
import {toastsReducer as toasts} from 'react-toastify-redux';
import suggestions from './suggestions.reducer'
import reasons from './admin/reasons.reducer'
import users from './admin/admin-users.reducer'
import customers from './admin/admin-customers.reducer'
import orders from './user/user-orders.reducer'
import translation from './public/translation.reducer'

export default combineReducers({
  ui,
  category,
  productComposite,
  steps,
  productPrice,
  rfq,
  rfqHistory,
  aufpreis,
  rfqDetail,
  rules,
  country,
  auth,
  claimUi,
  claims,
  userClaims,
  priceRule,
  webContents,
  user,
  toasts,
  suggestions,
  reasons,
  users,
  customers,
  orders,
  translation,
});