import { apiRequest } from "../../action/api.action"
import { getHost } from "../../../react/utils/host"
import { FETCH_USER_PROFILE, FETCH_USER_PROFILE_FAILURE, FETCH_USER_PROFILE_SUCCESS, UPDATE_USER, UPDATE_USER_FAILURE, UPDATE_USER_SUCCESS } from "../../action/user/user.action";

export const userFlow = ({ dispatch }) => next => action => {
  next(action)

  if (action.type === FETCH_USER_PROFILE || action.type === UPDATE_USER_SUCCESS  ) {
    let url = `${getHost()}/user-api/v1/user`
    dispatch(apiRequest("GET", url, null, FETCH_USER_PROFILE_SUCCESS, FETCH_USER_PROFILE_FAILURE));
  }

  if (action.type === UPDATE_USER) {
    let url = `${getHost()}/user-api/v1/user`
    dispatch(apiRequest("PUT", url, action.payload, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE));
  }
};

export const userMdl = [userFlow];