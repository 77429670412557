export const  FETCH_PRICE_RULES = "fetch price rules"
export const  FETCH_PRICE_RULES_SUCCESS = "fetch price rules success"
export const  FETCH_PRICE_RULES_FAILURE = "fetch price rules failure"

export const  REMOVE_FROM_AUFPREISEN = "remove from aufpreisen"
export const  ADD_TO_AUFPREISEN = "add to aufpreisen"

export const fetchPriceRules = (data) => ({
  type: FETCH_PRICE_RULES,
  payload: data
});

export const removeFromAufpreisen = (data) => ({
  type: REMOVE_FROM_AUFPREISEN,
  payload: data
});

export const addToAufpreisen = (data) => ({
  type: ADD_TO_AUFPREISEN,
  payload: data
});




