export const NEXT_STEP = "next step"

export const SET_CURRENT_STEP = "set current step"

export const RESET_STEPS = "reset steps"

export const nextStep = (data) => ({
  type: NEXT_STEP,
  payload: data
});

export const setCurrentStep = (data) => ({
  type: SET_CURRENT_STEP,
  payload: data
});

export const resetSteps = (data) => ({
  type: RESET_STEPS,
  payload: data
});


