import { useAuth0 } from "@auth0/auth0-react";
import { apiRequest } from "../action/api.action"
import { FETCH_RFQ_DETAIL_BY_ID, FETCH_RFQ_DETAIL_BY_ID_FAILURE, FETCH_RFQ_DETAIL_BY_ID_SUCCESS, FETCH_RFQ_HISTORY, FETCH_RFQ_HISTORY_FAILURE, FETCH_RFQ_HISTORY_SUCCESS, SAVE_RFQ, SAVE_RFQ_FAILURE, SAVE_RFQ_SUCCESS } from "../action/rfq.action";
import { getHost } from "../../react/utils/host"
import { FETCH_ALL_RFQS, FETCH_ALL_RFQS_FAILURE, FETCH_ALL_RFQS_SUCCESS } from "../action/admin/adminRfq.action";

export const rfqFlow = ({ dispatch }) => next => action => {
  next(action)
  if (action.type === SAVE_RFQ) {
    let items = []
    let newPayLoad = {
      parameters: {},
      token: action.payload.token
    }
    action.payload.parameters.items.map(item => {
      let newItem = { unitPrice: item.unitPrice, totalPrice: item.totalPrice, quantity: item.quantity, productPrice: item.product, categories: [], notice: item.notice }
      Object.entries(item).filter(([key, value]) => key.indexOf("cate") === 0).map(([key, value]) => {
        newItem.categories.push(value);
      });
      items.push(newItem)
    })
    newPayLoad.parameters["items"] = items
    newPayLoad.parameters["company"] = {}
    newPayLoad.parameters["user"] = action.payload.user
    let url = getHost() + "/api/v1/rfq/new"
    dispatch(apiRequest("POST", url, newPayLoad, SAVE_RFQ_SUCCESS, SAVE_RFQ_FAILURE));
  } else if (action.type === FETCH_RFQ_HISTORY) {
    let url = getHost() + "/api/v1/rfq/history"
    dispatch(apiRequest("GET", url, action.payload, FETCH_RFQ_HISTORY_SUCCESS, FETCH_RFQ_HISTORY_FAILURE));
  } else if (action.type === FETCH_RFQ_DETAIL_BY_ID) {
    let url = getHost() + "/user-api/v1/rfq/" + action.payload.id
    if (action.payload.apiPath) {
      url = getHost() + action.payload.apiPath + action.payload.id
    }

    dispatch(apiRequest("GET", url, null, FETCH_RFQ_DETAIL_BY_ID_SUCCESS, FETCH_RFQ_DETAIL_BY_ID_FAILURE));
  }

  if (action.type === FETCH_ALL_RFQS) {
    let url = getHost() + "/admin-api/v1/rfq"
    dispatch(apiRequest("GET", url, null, FETCH_ALL_RFQS_SUCCESS, FETCH_ALL_RFQS_FAILURE));
  }

};

export const rfqMdl = [rfqFlow];