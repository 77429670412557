import React, { useEffect, useState } from "react";

import Loading from "../../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { deleteUiRule, fetchRules, resetCurrentEditRule } from "../../../redux/action/rules.action";
import { Fragment } from "react";
import { Button, Collapse, Modal } from 'react-bootstrap';
import SimpleRuleEditor from "../../components/SimpleRuleEditor.Component";
import { fetchCategoyGroupList, fetchCategoyList } from "../../../redux/action/category.action";
import { setShowAddRuleModal } from "../../../redux/action/ui.action";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import { updateToken } from "../../../redux/action/auth.action";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { deleteAufpreisRule, fetchAufpreisRules, resetCurrentEditAufpreisRule } from "../../../redux/action/admin/aufpreisRules.action";
import SimpleAufpreisRuleEditor from "../../components/admin/SimpleAufpreisRuleEditor.Component";
import { deleteClaimReasonById, fetchClaimReasons, fetchClaimReasonTree } from "../../../redux/action/claim.action";
import EditReason from "../../components/admin/EditReason.Component";
import {useTranslation} from "react-i18next";

const products = [{ id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 2, name: "sadf", price: 234.4 }]

export const AdminClaimReasonsView = () => {
  const { t, i18n } = useTranslation('common');//国际化
  const dispatch = useDispatch()
  const { user, getAccessTokenSilently } = useAuth0();
  const reasons = useSelector(state => state.reasons.list);
  const reasonTree = useSelector(state => state.reasons.tree);
  const [token, setToken] = useState('')
  const showAddRuleModal = useSelector(state => state.ui.showAddRuleModal);

  const [currentReason, setCurrentReason] = useState({});

  const { SearchBar } = Search;

  function actionFormatter(cell, row) {
    return (
      <div className="group group-middle">
        <button type="submit" className="button button-sm button-primary-outline button-winona" onClick={(e) => {
          dispatch(setShowAddRuleModal({ show: true }));
          setCurrentReason(row)
        }}
        >{t('admin.Edit')}</button>
        <button type="submit" className="button button-sm button-primary-outline button-winona" onClick={(e) => {
          if (window.confirm('Are you sure you wish to delete this item?')) {
            dispatch(deleteClaimReasonById({ id: row.id }));
          }
        }}
        >{t('admin.Delete')}</button>
      </div>
    );
  }

  const columns = [{
    dataField: 'id',
    text: 'ID',
    sort: true,
  }, {
    dataField: 'name',
    text: 'Name',
    sort: true,
  },
  {
    dataField: 'parent.name',
    text: 'Parent',
    sort: true,
  },
  {
    dataField: 'action',
    text: 'Action',
    formatter: actionFormatter,
  }];


  useEffect(() => {
    getAccessTokenSilently().then(token => {
      dispatch(updateToken({ token: token }))
      dispatch(fetchClaimReasons())
      dispatch(fetchClaimReasonTree())
    })
  }, [])

  const options = {
    custom: true,
    totalSize: reasons.length
  };

  return (
    <>
      <Modal show={showAddRuleModal} size="xl" dialogClassName="modal-details">
        <Modal.Header>
          <Modal.Title>{t('admin.ClaimReason')}Claim Reason</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditReason reason={currentReason}/>
        </Modal.Body>
        <Modal.Footer>
          <Button className="button button-icon button-icon-left button-winona" onClick={(e) => {
            dispatch(setShowAddRuleModal({ show: false }));
          }}>
            {t('admin.Close')}
          </Button>
        </Modal.Footer>
      </Modal>

      <section className="section section-lg text-center">
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <div className="group group-middle">
                <button type="button" className="button" onClick={(e) => {
                  e.preventDefault();
                  setCurrentReason({})
                  dispatch(setShowAddRuleModal({ show: true }));
                }}
                >{t('admin.AddnewClaimReason')}</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section section-lg">
        <div className="container">
          <div className="row">
            {reasonTree &&
              reasonTree.map((r, index) => {
                return (
                  <div className="col-md-12" key={"reason_r_" + index}>
                    <h4>{r.name}({t('admin.ID')}: {r.id})</h4>
                    {r.children.map((c, index) => {
                      return (
                        <span className="pr10" key={"reason_c_" + r.id + "_" + index}>{c.name}({t('admin.ID')}: {c.id})</span>
                      )
                    })
                    }
                  </div>
                )
              })
            }
          </div>
        </div>
      </section>

      {reasons && reasons.length > 0 &&
        <section className="section section-lg text-center">
          <div className="container">
            <PaginationProvider
              pagination={paginationFactory(options)}
            >
              {
                ({
                  paginationProps,
                  paginationTableProps
                }) => (
                  <div>
                    <PaginationListStandalone
                      {...paginationProps}
                    />
                    <ToolkitProvider
                      bootstrap4
                      keyField="id"
                      data={reasons}
                      columns={columns}
                      search
                    >
                      {
                        toolkitprops => (
                          <div>
                            <div style={{ float: "left", padding: "10px 0px" }}>
                              <SearchBar {...toolkitprops.searchProps} style={{ float: "left" }} placeholder="Search Rule Name / ID" />
                            </div>
                            <BootstrapTable
                              striped
                              hover
                              {...toolkitprops.baseProps}
                              {...paginationTableProps}
                            />
                          </div>
                        )
                      }
                    </ToolkitProvider>
                    <PaginationListStandalone
                      {...paginationProps}
                    />
                  </div>
                )
              }
            </PaginationProvider>
          </div>
        </section>
      }
    </>
  );
};

export default withAuthenticationRequired(AdminClaimReasonsView, {
  onRedirecting: () => <Loading />,
});
