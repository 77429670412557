import { apiRequest } from "../../action/api.action"
import { getHost } from "../../../react/utils/host"
import { FETCH_USER_ORDERS, FETCH_USER_ORDERS_FAILURE, FETCH_USER_ORDERS_SUCCESS } from "../../action/user/user-orders.action";

export const userOrdersFlow = ({ dispatch }) => next => action => {
  next(action)

  if (action.type === FETCH_USER_ORDERS) {
    let url = `${getHost()}/user-api/v1/orders`
    dispatch(apiRequest("GET", url, null, FETCH_USER_ORDERS_SUCCESS, FETCH_USER_ORDERS_FAILURE));
  }
};

export const userOrdersMdl = [userOrdersFlow];