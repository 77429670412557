import { apiRequest } from "../../action/api.action"
import { getHost } from "../../../react/utils/host"
import { ADD_CUSTOMER_TO_USER, ADD_CUSTOMER_TO_USER_FAILURE, ADD_CUSTOMER_TO_USER_SUCCESS, FETCH_USERS, FETCH_USERS_FAILURE, FETCH_USERS_SUCCESS, FETCH_USER_BY_ID, FETCH_USER_BY_ID_FAILURE, FETCH_USER_BY_ID_SUCCESS, REMOVE_USER_CUSTOMER, REMOVE_USER_CUSTOMER_FAILURE, REMOVE_USER_CUSTOMER_SUCCESS } from "../../action/admin/admin-users.action";

export const adminUsersFlow = ({ dispatch }) => next => action => {
  next(action)
  if (action.type === FETCH_USERS) {
    let url = `${getHost()}/admin-api/v1/users`
    dispatch(apiRequest("GET", url, null, FETCH_USERS_SUCCESS, FETCH_USERS_FAILURE));
  }

  if (action.type === ADD_CUSTOMER_TO_USER) {
    let url = `${getHost()}/admin-api/v1/users/${action.payload.parameters.userId}/add-customer`
    dispatch(apiRequest("POST", url, action.payload, ADD_CUSTOMER_TO_USER_SUCCESS, ADD_CUSTOMER_TO_USER_FAILURE));
  }
  
  if (action.type === REMOVE_USER_CUSTOMER) {
    let url = `${getHost()}/admin-api/v1/users/${action.payload.parameters.userId}/remove-customer/${action.payload.parameters.userCustomerId}`
    dispatch(apiRequest("DELETE", url, null, REMOVE_USER_CUSTOMER_SUCCESS, REMOVE_USER_CUSTOMER_FAILURE));
  }

  if (action.type === FETCH_USER_BY_ID || action.type === REMOVE_USER_CUSTOMER_SUCCESS || action.type === ADD_CUSTOMER_TO_USER_SUCCESS) {
    let url = `${getHost()}/admin-api/v1/users/${action.payload.id}`
    dispatch(apiRequest("GET", url, null, FETCH_USER_BY_ID_SUCCESS, FETCH_USER_BY_ID_FAILURE));
  }

};

export const adminUsersMdl = [adminUsersFlow];