import { addToAufpreisen, ADD_TO_AUFPREISEN, FETCH_PRICE_RULES_SUCCESS, REMOVE_FROM_AUFPREISEN } from "../../action/user/price-rule.action";
const initialState = {
  rules: [],
  currentAufpreisen: {},
  seq: 1
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PRICE_RULES_SUCCESS:
      return {
        ...state,
        rules: action.payload
      }
    case ADD_TO_AUFPREISEN:
      action.payload.forEach(d => {
        state.currentAufpreisen[d.key] = {aufpreis: d.value, prozentAufpreis : d.prozentValue, leadTimeWeeks:d.leadTimeWeeks}
      })
      return {
        ...state,
        seq: state.seq + 1
      }
    case REMOVE_FROM_AUFPREISEN:
      action.payload.forEach(d => {
        delete state.currentAufpreisen[d.key];
      })
      return {
        ...state,
        seq: state.seq + 1
      }
    default:
      return state;
  }
}