import React, { useEffect, useState } from "react";

import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Configurator } from "../components/Configurator";
import { ConfiguratorSteps } from "../components/ConfiguratorSteps";
import { fetchCategoryTree } from "../../redux/action/category.action";
import { useDispatch, useSelector } from "react-redux";
import TreeComponent from "../components/Tree.Compnent";
import { updateToken } from "../../redux/action/auth.action";


export const TreeView = () => {
  const dispatch = useDispatch()
  const { user, getAccessTokenSilently } = useAuth0();
  const tree = useSelector(state => state.category.tree);

  useEffect(() => {
    getAccessTokenSilently().then(token => {
      dispatch(fetchCategoryTree({ token: token}))
      dispatch(updateToken({ token: token }))
    })
  }, [])

  return (
    <>
      {tree && tree[0] &&
        <TreeComponent category={tree[0]} />
      }
    </>
  );
};

export default withAuthenticationRequired(TreeView, {
  onRedirecting: () => <Loading />,
});
