import React, {  } from 'react';
import _ from 'lodash'
import { ErrorMessage, Field, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { updateTranslation } from '../../redux/action/public/translation.action';

const EditTranslation = (props) => {
  const dispatch = useDispatch()
  let currentEditTranslation = props.translation;
  let setShow = props.setShow
  const { user, getAccessTokenSilently } = useAuth0();

  return (
    <>
      <Formik
        initialValues={currentEditTranslation}
        enableReinitialize={true}
        validate={values => {
          const errors = {};
          return errors;
        }}
        onSubmit={(values) => {
          dispatch(updateTranslation({ parameters: values }))
          setShow(false)
        }}>
        {({ values, errors, handleSubmit, setFieldValue, setValues }) => (
          <form onSubmit={handleSubmit} autoComplete="off" className="rd-form rd-mailform">
            {values.id &&
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="id">Id</label>
                    <Field id="id" type="text" name="id" disabled className="form-control" value={values.id || ''}
                    />
                  </div>
                </div>
              </div>
            }
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="key">Key<ErrorMessage name="key" render={msg => <span className="error">(*{msg})</span>} /></label>
                  <Field id="key" type="text" name="key" placeholder="Key Name" className="form-control" value={values.key || ''}
                    onChange={(event) => {
                      setFieldValue("key", event.currentTarget.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="de">DE</label>
                  <Field id="de" type="text" as="textarea" rows="10" name="de" placeholder="DE" className="form-control" value={values.de || ''}
                    onChange={(event) => {
                      setFieldValue("de", event.currentTarget.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="en">En</label>
                  <Field id="en" type="text" as="textarea" rows="10" name="en" placeholder="EN" className="form-control" value={values.en || ''}
                    onChange={(event) => {
                      setFieldValue("en", event.currentTarget.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="email">User Email</label>
                  <Field id="email" type="text" name="email" placeholder="User Email" className="form-control" value={values.email || ''}
                    onChange={(event) => {
                      setFieldValue("email", event.currentTarget.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <button type="submit" className="button button-primary">{values.id ? "Update" : "Create"}</button>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}

export default EditTranslation;
