import { FETCH_USERS_SUCCESS, FETCH_USER_BY_ID_SUCCESS, setCurrentUser, SET_CURRENT_USER } from "../../action/admin/admin-users.action";

const initialState = {
  list: [],
  currentUser: {},
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        list: action.payload
      }
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload
      }
    case FETCH_USER_BY_ID_SUCCESS:
      return {
        ...state,
        currentUser: action.payload
      }
    default:
      return state;
  }
}