import React, { useEffect, useState, useRef } from 'react';
import { Button, Collapse, Modal } from 'react-bootstrap';
import _ from 'lodash'
import { Field, useFormikContext } from 'formik';
import * as Icon from 'react-bootstrap-icons';
import { updateUi } from '../../redux/action/ui.action';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRfqHistory } from '../../redux/action/rfq.action';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import moment from 'moment';
import { updateToken } from '../../redux/action/auth.action';
import Loading from '../components/Loading';
import { AuthenticatedLink } from '../components/AuthenticatedLink';
import {useTranslation} from "react-i18next";

const RfqHistory = () => {
  const { t, i18n } = useTranslation('common');//国际化
  const history = useSelector(state => state.rfqHistory);

  const { user, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch()
  const [token, setToken] = useState('')

  useEffect(() => {
    getAccessTokenSilently().then(token => {
      dispatch(fetchRfqHistory({ token: token }))
      setToken(token)
      dispatch(updateToken({ token: token }))
    })
  }, [])

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <section className="section section-lg text-center">
      <div className="container">
        <div className="">
          {history &&
            <table className="table table-hover table-job-positions">
              <thead>
                <tr>
                  <th>{t('home.Date')}</th>
                  <th>{t('home.UnitPrice')}</th>
                  <th>{t('home.Quantity')}</th>
                  <th>{t('home.TotalPrice')}</th>
                  <th>{t('home.Notice')}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {history.map(p => {
                  return (
                    <tr key={"rfq_" + p.id}>
                      <td>{moment(p.creationDate).format('YYYY-MM-DD')}</td>
                      <td>{p.quotationItems[0] ? p.quotationItems[0].unitPrice > 0 ? `${parseFloat(p.quotationItems[0].unitPrice).toFixed(2)} €` : 'Auf Anfrage' : ''}</td>
                      <td>{p.quotationItems[0] ? p.quotationItems[0].quantity : ''}</td>
                      <td>{p.quotationItems[0] ? p.quotationItems[0].totalPrice > 0 ? `${parseFloat(p.quotationItems[0].totalPrice).toFixed(2)} €` : 'Auf Anfrage' : ''}</td>
                      <td>{p.quotationItems[0] ? p.quotationItems[0].notice : ''}</td>
                      <td>
                        <div className="group group-middle">
                          <a href={"/digicat/rfq-history/view?&id=" + p.id}>
                            <i className="icon icon-sm link-default mdi mdi-eye pointer" ></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          }
        </div>
      </div>
    </section>
  );
}

export default withAuthenticationRequired(RfqHistory, {
  onRedirecting: () => <Loading />,
});
