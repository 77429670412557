import { FETCH_USER_CLAIM_BY_ID_SUCCESS, FETCH_USER_CLAIM_LIST_SUCCESS, SET_CURRENT_USER_CLAIM_ITEM } from "../../action/user/claim.action";

const initialState = {
  list: [],
  current: {
    alertTo: "nordenham.service@de.atb-motors.com",
    items: [],
    tempAttachments: [],
    tempImages: [],
    kundenname: "",
    kundennummer: "",
    telefon: "",
    email: "",
    lieferscheinNr: "",
    auftragNr: "",
    status:"SUBMITTED",
  },
  currentItem: {
    tempAttachments: [],
    tempImages: [],
  }
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_USER_CLAIM_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload
      }
    case FETCH_USER_CLAIM_BY_ID_SUCCESS:
      return {
        ...state,
        current: action.payload
      }
    case SET_CURRENT_USER_CLAIM_ITEM:
      return {
        ...state,
        currentItem: action.payload
      }
    default:
      return state;
  }
}