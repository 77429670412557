import { FETCH_CATEGORY_TREE_SUCCESS, FETCH_CATEGOY_GROUP_LIST_SUCCESS, FETCH_CATEGOY_LIST_SUCCESS, FETCH_CHILD_CATEGORIES, FETCH_CHILD_CATEGORIES_SUCCESS, FETCH_CHILD_CATEGORY_TREE_SUCCESS, FETCH_CURRENT_EDIT_CATEGORY_GROUP_SUCCESS, FETCH_CURRENT_EDIT_CATEGORY_SUCCESS, FETCH_CURRENT_EDIT_PROPERTY_SUCCESS, FETCH_FULL_CHILD_CATEGORIES_FOR_MERKMAL_SUCCESS, FETCH_FULL_CHILD_CATEGORIES_SUCCESS, FETCH_PARENT_CATEGORY_CANDIDATES_SUCCESS, FETCH_SAP_MERKMAL_LIST_SUCCESS, RESET_CURRENT_EDIT_CATEGORY, RESET_CURRENT_EDIT_CATEGORY_GROUP, UPDATE_CATEGORY_GROUP_SUCCESS } from "../action/category.action";
import { RESET_STEPS } from "../action/step.action";

const initialState = {
  tree: [],
  children: [],
  merkmalenChildren: [],
  list: [],
  groupList: [],
  currentEditCategory: {},
  currentEditProperty:{},
  currentEditCategorySeq: 0,
  currentEditCategoryGroup: {},
  parentCandidates: [],
  childrenTree:[],
  sapMerkmal:[],
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_FULL_CHILD_CATEGORIES_SUCCESS:
      return {
        ...state,
        merkmalenChildren: action.payload[0].subCategories
      }
    case FETCH_FULL_CHILD_CATEGORIES_FOR_MERKMAL_SUCCESS:
      return {
        ...state,
        merkmalenChildren: action.payload[0].subCategories
      }
    case FETCH_CHILD_CATEGORIES_SUCCESS:
      return {
        ...state,
        children: action.payload
      }
    case FETCH_CATEGORY_TREE_SUCCESS:
      return {
        ...state,
        tree: action.payload
      }
    case FETCH_CATEGOY_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload
      }
    case FETCH_CATEGOY_GROUP_LIST_SUCCESS:
      return {
        ...state,
        groupList: action.payload
      }
    case RESET_STEPS:
      return {
        tree: [],
        children: [],
        merkmalenChildren: []
      }
    case FETCH_CURRENT_EDIT_CATEGORY_SUCCESS:
      return {
        ...state,
        currentEditCategory: action.payload,
        currentEditCategorySeq: state.currentEditCategorySeq + 1
      }
    case RESET_CURRENT_EDIT_CATEGORY:
      return {
        ...state,
        currentEditCategory: {}
      }
    case FETCH_PARENT_CATEGORY_CANDIDATES_SUCCESS:
      return {
        ...state,
        parentCandidates: action.payload,
        childrenTree:[],
      }
    case FETCH_CHILD_CATEGORY_TREE_SUCCESS:
      return {
        ...state,
        childrenTree: action.payload
      }
    case FETCH_CURRENT_EDIT_PROPERTY_SUCCESS:
      return {
        ...state,
        currentEditProperty: action.payload
      }
    case FETCH_CURRENT_EDIT_CATEGORY_GROUP_SUCCESS:
      return {
        ...state,
        currentEditCategoryGroup: action.payload
      }
    case RESET_CURRENT_EDIT_CATEGORY_GROUP:
      return {
        ...state,
        currentEditCategoryGroup: {}
      }
    case FETCH_SAP_MERKMAL_LIST_SUCCESS:
      return {
        ...state,
        sapMerkmal: action.payload
      }
    default:
      return state;
  }
}