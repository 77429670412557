import React, { useEffect, useState } from "react";

import Loading from "../../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { deleteUiRule, fetchRules, resetCurrentEditRule } from "../../../redux/action/rules.action";
import { Fragment } from "react";
import { Button, Collapse, Modal } from 'react-bootstrap';
import SimpleRuleEditor from "../../components/SimpleRuleEditor.Component";
import { fetchCategoyGroupList, fetchCategoyList } from "../../../redux/action/category.action";
import { setShowAddRuleModal } from "../../../redux/action/ui.action";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import { updateToken } from "../../../redux/action/auth.action";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { deleteAufpreisRule, fetchAufpreisRules, resetCurrentEditAufpreisRule } from "../../../redux/action/admin/aufpreisRules.action";
import SimpleAufpreisRuleEditor from "../../components/admin/SimpleAufpreisRuleEditor.Component";
import { deleteClaimReasonById, fetchClaimReasons, fetchClaimReasonTree } from "../../../redux/action/claim.action";
import EditReason from "../../components/admin/EditReason.Component";
import { fetchUsers, setCurrentUser } from "../../../redux/action/admin/admin-users.action";
import AdminEditUser from "../../components/admin/AdminEditUser.Component";
import {useTranslation} from "react-i18next";

const products = [{ id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 1, name: "sadf", price: 234.4 }, { id: 2, name: "sadf", price: 234.4 }]

export const AdminUsersView = () => {
  const { t, i18n } = useTranslation('common');//国际化
  const dispatch = useDispatch()
  const { user, getAccessTokenSilently } = useAuth0();
  const users = useSelector(state => state.users.list);
  const [showEditModal, setShowEditModal] = useState(false);

  const { SearchBar } = Search;

  function actionFormatter(cell, row) {
    return (
      <div className="group group-middle">
        <button type="submit" className="button button-sm button-primary-outline button-winona" onClick={(e) => {
          setShowEditModal(true);
          dispatch(setCurrentUser(row))
        }}
        >{t('admin.Edit')}</button>
      </div>
    );
  }

  function nameFormatter(cell, row) {
    return (
      <>
        {row.name}<br />
        {row.email}<br />
        {row.company}
      </>
    );
  }

  function customerFormatter(cell, row) {
    return (
      <>
        {row.userCustomers &&
          row.userCustomers.map(uc => {
            return (
              <div key={"customer_" + uc.id}>
                {uc.customer.name1}
              </div>
            )
          })
        }
      </>
    );
  }

  const customerFilter = (cell, row) => {
    let ret = ""
    ret = row.userCustomers?.map((uc, index) => uc.customer.name1)
    return ret;
  }

  const columns = [{
    dataField: 'id',
    text: 'ID',
    sort: true,
  },
  {
    dataField: 'name',
    text: 'Name',
    sort: true,
    formatter: nameFormatter,
  },
  {
    dataField: 'roles',
    text: 'Roles',
    sort: true,
  },
  {
    dataField: 'customer',
    text: 'Customers',
    sort: true,
    formatter: customerFormatter,
    filterValue: customerFilter
  },
  {
    dataField: 'discount',
    text: 'Discount',
    sort: true,
    formatter: (cell, row) => { return (<>{row.discount ? ((row.discount * 100) + " %") : ""}</>) },
  },
  {
    dataField: 'action',
    text: 'Action',
    formatter: actionFormatter,
  }];


  useEffect(() => {
    getAccessTokenSilently().then(token => {
      dispatch(updateToken({ token: token }))
      dispatch(fetchUsers())
    })
  }, [])

  const options = {
    custom: true,
    totalSize: users.length
  };

  return (
    <>
      <Modal show={showEditModal} size="xl" dialogClassName="modal-details">
        <Modal.Header>
          <Modal.Title>{t('admin.UserEdit')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AdminEditUser />
        </Modal.Body>
        <Modal.Footer>
          <Button className="button button-icon button-icon-left button-winona" onClick={(e) => {
            setShowEditModal(false);
          }}>
            {t('admin.Close')}
          </Button>
        </Modal.Footer>
      </Modal>

      {users && users.length > 0 &&
        <section className="section section-lg text-center">
          <div className="container">
            <PaginationProvider
              pagination={paginationFactory(options)}
            >
              {
                ({
                  paginationProps,
                  paginationTableProps
                }) => (
                  <div>
                    <PaginationListStandalone
                      {...paginationProps}
                    />
                    <ToolkitProvider
                      bootstrap4
                      keyField="id"
                      data={users}
                      columns={columns}
                      search
                    >
                      {
                        toolkitprops => (
                          <div>
                            <div style={{ float: "left", padding: "10px 0px" }}>
                              <SearchBar {...toolkitprops.searchProps} style={{ float: "left" }} placeholder="Search Rule Name / ID" />
                            </div>
                            <BootstrapTable
                              striped
                              hover
                              {...toolkitprops.baseProps}
                              {...paginationTableProps}
                            />
                          </div>
                        )
                      }
                    </ToolkitProvider>
                    <PaginationListStandalone
                      {...paginationProps}
                    />
                  </div>
                )
              }
            </PaginationProvider>
          </div>
        </section>
      }
    </>
  );
};

export default withAuthenticationRequired(AdminUsersView, {
  onRedirecting: () => <Loading />,
});
