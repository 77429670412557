import React, { useEffect, useState, useRef } from 'react';
import { Button, Collapse, Modal, Table } from 'react-bootstrap';
import _ from 'lodash'
import { Field, useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

import Stepper from 'react-stepper-js'
import 'react-stepper-js/dist/index.css'
import { useAuth0 } from '@auth0/auth0-react';

const ClaimProcess = (props) => {

  const { values, setFieldValue, submitForm, setValues, handleSubmit } = useFormikContext();
  let currentClaim = useSelector(state => state.claims.current);

  const { user, isAuthenticated } = useAuth0();

  const statusStep = {
    'SUBMITTED': 1,
    'ACCEPTED': 2,
    'REPLACEMENT': 3,
    'RETOUR': 3,
    'FINISHED': 4
  }

console.info("user  ", user);

  return (
    <>
      <div className="row row-10">
        <div className="col-md-12 ">
          <Stepper
            color="#2f4f4f"
            fontColor="#2d3742"
            steps={[
              { label: "Submitted" },
              { label: "Accepted" },
              { label: "Ersatzteil / Retour" },
              { label: "Finished" }
            ]}
            currentStep={statusStep[currentClaim.status]}
          />
        </div>
      </div>

      {isAuthenticated && 
        <>
          {currentClaim && currentClaim.status === 'SUBMITTED' && user["https://atb.51jobs.de/roles"].includes("claimInvestigator") &&
            <div className="row row-10">
              <div className="col-md-6 ">
                <div className="form-wrap">
                  <label className="form-label-outside" htmlFor="Bemerkung">Internal Bemerkung</label>
                  <Field id="Bemerkung" as="textarea" type="text" name="Bemerkung" placeholder="Internal Bemerkung" className="form-input" value={values.processComment || ''}
                    onChange={(event) => {
                      setFieldValue("processComment", event.currentTarget.value);
                    }} />
                </div>
              </div>
              <div className="col-md-6 ">
                <div className="form-wrap">
                  <label className="form-label-outside" htmlFor="externalBemerkung">External Bemerkung</label>
                  <Field id="externalBemerkung" as="textarea" type="text" name="externalBemerkung" placeholder="External Bemerkung" className="form-input" value={values.processCommentExternal || ''}
                    onChange={(event) => {
                      setFieldValue("processCommentExternal", event.currentTarget.value);
                    }} />
                </div>
              </div>

              <div className="col-md-12">
                <div className="group group-middle">
                  <Button className="button button-icon button-icon-left button-winona" onClick={(e) => {
                    setFieldValue("processStatus", 'ACCEPTED');
                    handleSubmit()
                  }}>
                    Accept
                  </Button>
                  <Button className="button button-icon button-icon-left button-winona" onClick={(e) => {
                    setFieldValue("processStatus", 'REJECTED');
                    handleSubmit()
                  }}>
                    Reject
                  </Button>
                </div>
              </div>
            </div>
          }

          {currentClaim && currentClaim.status === 'REJECTED' && currentClaim.user.sub === user.sub &&
            <div className="row row-10">
              <div className="col-md-12">
                <div className="group group-middle">
                  <Button className="button button-icon button-icon-left button-winona" onClick={(e) => {
                    setFieldValue("processStatus", 'DRAFT');
                    handleSubmit()
                  }}>
                    Edit
                  </Button>
                  <Button className="button button-icon button-icon-left button-winona" onClick={(e) => {
                    setFieldValue("processStatus", 'INVALID');
                    handleSubmit()
                  }}>
                    Invalid
                  </Button>
                </div>
              </div>
            </div>
          }

          {currentClaim && currentClaim.status === 'ACCEPTED' && user["https://atb.51jobs.de/roles"].includes("claimInvestigator") &&
            <div className="row row-10">
              <div className="col-md-6 ">
                <div className="form-wrap">
                  <label className="form-label-outside" htmlFor="Bemerkung">Internal Bemerkung</label>
                  <Field id="Bemerkung" as="textarea" type="text" name="Bemerkung" placeholder="Internal Bemerkung" className="form-input" value={values.processComment || ''}
                    onChange={(event) => {
                      setFieldValue("processComment", event.currentTarget.value);
                    }} />
                </div>
              </div>
              <div className="col-md-6 ">
                <div className="form-wrap">
                  <label className="form-label-outside" htmlFor="externalBemerkung">External Bemerkung</label>
                  <Field id="externalBemerkung" as="textarea" type="text" name="externalBemerkung" placeholder="External Bemerkung" className="form-input" value={values.processCommentExternal || ''}
                    onChange={(event) => {
                      setFieldValue("processCommentExternal", event.currentTarget.value);
                    }} />
                </div>
              </div>

              <div className="col-md-12">
                <div className="group group-middle">
                  <Button className="button button-icon button-icon-left button-winona" onClick={(e) => {
                    setFieldValue("processStatus", 'RETOUR');
                    handleSubmit()
                  }}>
                    Retour
                  </Button>
                  <Button className="button button-icon button-icon-left button-winona" onClick={(e) => {
                    setFieldValue("processStatus", 'REPLACEMENT');
                    handleSubmit()
                  }}>
                    Ersatzteil
                  </Button>
                  <Button className="button button-icon button-icon-left button-winona" onClick={(e) => {
                    setFieldValue("processStatus", 'FINISHED');
                    handleSubmit()
                  }}>
                    Finished
                  </Button>
                </div>
              </div>
            </div>
          }

          {currentClaim && (currentClaim.status === 'RETOUR' || currentClaim.status === 'REPLACEMENT') && user["https://atb.51jobs.de/roles"].includes("claimInvestigator") &&
            <div className="row row-10">
              <div className="col-md-6 ">
                <div className="form-wrap">
                  <label className="form-label-outside" htmlFor="Bemerkung">Internal Bemerkung</label>
                  <Field id="Bemerkung" as="textarea" type="text" name="Bemerkung" placeholder="Internal Bemerkung" className="form-input" value={values.processComment || ''}
                    onChange={(event) => {
                      setFieldValue("processComment", event.currentTarget.value);
                    }} />
                </div>
              </div>
              <div className="col-md-6 ">
                <div className="form-wrap">
                  <label className="form-label-outside" htmlFor="externalBemerkung">External Bemerkung</label>
                  <Field id="externalBemerkung" as="textarea" type="text" name="externalBemerkung" placeholder="External Bemerkung" className="form-input" value={values.processCommentExternal || ''}
                    onChange={(event) => {
                      setFieldValue("processCommentExternal", event.currentTarget.value);
                    }} />
                </div>
              </div>

              <div className="col-md-12">
                <div className="group group-middle">
                  <Button className="button button-icon button-icon-left button-winona" onClick={(e) => {
                    setFieldValue("processStatus", 'FINISHED');
                    handleSubmit()
                  }}>
                    Finished
                  </Button>
                </div>
              </div>
            </div>
          }
        </>
      }
    </>
  )
}

export default ClaimProcess;
