import { apiRequest } from "../action/api.action"
import { SEARCH_PRODUCT_PRICE, SEARCH_PRODUCT_PRICE_FAILURE, SEARCH_PRODUCT_PRICE_SUCCESS } from "../action/product-price.action";
import querystring from 'querystring'
import { getHost } from "../../react/utils/host";

export const productPriceFlow = ({ dispatch }) => next => action => {
  next(action)
  if (action.type === SEARCH_PRODUCT_PRICE) {
    let parameters = action.payload.parameters
    if (parameters.leistungMax) {
      parameters.leistungMax = parameters.leistungMax.replaceAll(",", ".")
    }
    if (parameters.leistungMin) {
      parameters.leistungMin = parameters.leistungMin.replaceAll(",", ".")
    }
    let parametersEncoded = querystring.encode(parameters);
    let url = `${getHost()}/api/v1/product-price/find?${parametersEncoded}`;
    delete action.payload["parameters"]
    dispatch(apiRequest("GET", url, action.payload, SEARCH_PRODUCT_PRICE_SUCCESS, SEARCH_PRODUCT_PRICE_FAILURE));
  }
};

export const productPriceMdl = [productPriceFlow];