import React, { Fragment, useEffect, useState, useCallback } from "react";
import { ErrorMessage, Field, Formik } from "formik";
import { Button, Collapse, Modal } from 'react-bootstrap';
import _ from 'lodash'
import { useDispatch, useSelector } from "react-redux";
import { addClaimItemById, deleteClaimAttachmentById, deleteClaimItemById, fetchClaimById, fetchClaimReasonTree, getSerienNumbers, postClaim, setCurrentClaimItem, submitClaimById } from "../../redux/action/claim.action";
import { browserHistory } from 'react-router'
import { useHistory } from "react-router-dom";
import { cleanRedirect } from "../../redux/action/ui.action";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { updateToken } from "../../redux/action/auth.action";
import Loading from "../components/Loading";
import EditClaimItem from "../components/EditClaimItem.Component";
import uuid from 'react-uuid'
import { ClaimClassificationMap, ClaimReasonMap } from "../utils/maps"
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import { v4 as uuidv4 } from 'uuid';
import { getHost } from "../utils/host";
import "../styles/dropzone_style.css"
import { fetchUserProfile } from "../../redux/action/user/user.action";
import Autosuggest from "react-autosuggest";
import { cleanSuggestions, getCustomerSuggestions } from "../../redux/action/internal/suggestions.action";
import '../styles/suggestions_style.css'
import {useTranslation} from "react-i18next";

function getParams(location, name) {
  const searchParams = new URLSearchParams(location.search);
  let ret = {};
  ret[name] = searchParams.get(name) || "";
  return ret;
}

const ClaimEdit = () => {
  const { t, i18n } = useTranslation('common');//国际化
  const dispatch = useDispatch();
  const redirect = useSelector(state => state.ui.redirect);
  let history = useHistory();
  const { user, getAccessTokenSilently } = useAuth0();
  let currentClaim = useSelector(state => state.claims.current);
  let seq = useSelector(state => state.claims.seq);
  const [currentClaimItem, setCurrentClaimItem] = useState({
    artikeNr: "",
    menge: "",
    classification: "",
    reason: "",
  });
  const [currentClaimItemIndex, setCurrentClaimItemIndex] = useState(-1);

  const suggestions = useSelector(state => state.suggestions);
  const [currentSuggestion, setCurrentSuggestion] = useState({});
  const token = useSelector(state => state.auth.token);
  const reasonTree = useSelector(state => state.reasons.tree);

  const [value, setValue] = useState({ customer: '' });

  const [show, setShow] = useState(false);

  const userProfile = useSelector(state => state.user.profile);

  useEffect(() => {
    if (userProfile.id && !userProfile.company) {
      history.push("/user/profile")
    }
  }, [userProfile])

  useEffect(() => {
    setValue({ customer: currentClaim.kundenname })
  }, [currentClaim.kundenname])

  useEffect(() => {
    if (redirect) {
      history.push(redirect)
      dispatch(cleanRedirect())
    }
  }, [redirect])

  useEffect(() => {
    getAccessTokenSilently().then(token => {
      dispatch(updateToken({ token: token }))
      dispatch(fetchUserProfile())
      let urlParams = getParams(window.location, "id");
      if (urlParams.id) {
        dispatch(fetchClaimById({ id: urlParams.id, token: token }))
      }
      dispatch(fetchClaimReasonTree())
    })
  }, [])

  useEffect(() => {
  }, [seq, currentClaim, currentClaim.items.length, reasonTree])


  return (
    <Fragment>

      <section className="section section-lg">
        <div className="range justify-content-xl-between">
          <div className="cell-xl-12 align-self-center container">
            <div className="row">
              <div className="col-lg-12 cell-inner">
                <h3 className=""><span className=" slideInDown">{t('home.Reklamation')}</span></h3>
                <Formik
                  initialValues={currentClaim}
                  enableReinitialize={true}
                  validate={values => {
                    const errors = {};
                    if (!values.kundenname) {
                      errors["kundenname"] = 'Required';
                    }
                    if (!values.kundennummer) {
                      errors["kundennummer"] = 'Required';
                    }
                    if (!values.telefon) {
                      errors["telefon"] = 'Required';
                    }
                    if (!values.email) {
                      errors["email"] = 'Required';
                    }
                    if (!values.lieferscheinNr) {
                      errors["lieferscheinNr"] = 'Required';
                    }
                    if (values.items.length <= 0) {
                      errors["items"] = 'Required';
                    }
                    return errors;
                  }}
                  onSubmit={(values) => {
                    dispatch(postClaim({ parameters: values }))
                  }}>
                  {({ values, errors, handleSubmit, setFieldValue, setValues }) => (
                    <form onSubmit={handleSubmit} autoComplete="off" className="">
                      <Modal show={show} size="xl" dialogClassName="modal-details">
                        <Modal.Header>
                          <Modal.Title>{t('home.EditClaimItem')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <EditClaimItem item={currentClaimItem} claim={values} setItem={setCurrentClaimItem} index={currentClaimItemIndex} currentSuggestion={currentSuggestion} />
                        </Modal.Body>
                        <Modal.Footer>
                          <Button className="button button-icon button-icon-left button-winona" onClick={(e) => {
                            setShow(false);
                          }}>
                            {t('home.Close')}
                          </Button>
                        </Modal.Footer>
                      </Modal>


                      <div className="row row-10">
                        {values.id &&
                          <div className="col-md-6 ">
                            <div className="form-wrap  ">
                              <label className="" htmlFor="id">{t('home.ID')}</label>
                              <Field id="id" type="text" name="id" placeholder="id" className="form-input" value={values.id || ''}
                                disabled />
                            </div>
                          </div>
                        }
                        {!values.id &&
                          <div className="col-md-6 "></div>
                        }

                        <div className="col-md-6 ">
                          <div className="form-wrap  ">
                            <label className="" htmlFor="alertMail">{t('home.AlertMails')}</label>
                            <Field id="alertMail" type="text" name="alertMail" placeholder="Alert Mail" className="form-input" value={values.alertTo || ''}
                              onChange={(event) => {
                                setFieldValue("alertTo", event.currentTarget.value);
                              }} />
                          </div>
                        </div>
                        <div className="col-md-3 ">
                          <label className="" htmlFor="kundenname">{t('home.Kundenname')} <ErrorMessage name="kundenname" render={msg => <span className="error">(*{msg})</span>} /> </label>
                          <Autosuggest
                            suggestions={suggestions}
                            onSuggestionsFetchRequested={({ value }) => {
                              dispatch(getCustomerSuggestions(value));
                            }}
                            onSuggestionsClearRequested={() => {
                              dispatch(cleanSuggestions());
                            }}
                            getSuggestionValue={(suggestion) => {
                              setCurrentSuggestion(suggestion);
                              setFieldValue("kundenname", suggestion.customer.name);
                              setFieldValue("kundennummer", suggestion.customer.kunNr);
                              setFieldValue("telefon", suggestion.customer.telf1);
                              setFieldValue("email", suggestion.customer.email);
                              return `${suggestion.customer.name}`;
                            }}
                            renderSuggestion={(suggestion) => (
                              <div>
                                {suggestion.name}
                              </div>
                            )}
                            inputProps={{
                              placeholder: 'Kundenname suchen',
                              style: { "width": "100%" },
                              value: value.customer,
                              className: "form-input",
                              onChange: (event, { newValue }) => {
                                setValue({
                                  customer: newValue,
                                });
                              }
                            }}
                          />
                        </div>
                        <div className="col-md-3 ">
                          <div className="form-wrap  ">
                            <label className="" htmlFor="Kundennummer">{t('home.Kundennummer')} <ErrorMessage name="kundennummer" render={msg => <span className="error">(*{msg})</span>} /> </label>
                            <Field id="Kundennummer" type="text" name="Kundennummer" placeholder="Kundennummer" className="form-input" value={values.kundennummer || ''}
                              onChange={(event) => {
                                setFieldValue("kundennummer", event.currentTarget.value);
                              }} />
                          </div>
                        </div>
                        <div className="col-md-6 "></div>
                        <div className="col-md-6 ">
                          <div className="form-wrap  ">
                            <label className="" htmlFor="Sachbearbeiter">{t('home.Sachbearbeiter')} <ErrorMessage name="sachbearbeiter" render={msg => <span className="error">(*{msg})</span>} /> </label>
                            <Field id="Sachbearbeiter" type="text" name="Sachbearbeiter" placeholder="Sachbearbeiter" className="form-input" value={values.sachbearbeiter || ''}
                              onChange={(event) => {
                                setFieldValue("sachbearbeiter", event.currentTarget.value);
                              }} />
                          </div>
                        </div>
                        <div className="col-md-6 "></div>
                        <div className="col-md-6 ">
                          <div className="form-wrap  ">
                            <label className="" htmlFor="Telefon">{t('home.Telefon')} <ErrorMessage name="telefon" render={msg => <span className="error">(*{msg})</span>} /> </label>
                            <Field id="Telefon" type="text" name="Telefon" placeholder="Telefon" className="form-input" value={values.telefon || ''}
                              onChange={(event) => {
                                setFieldValue("telefon", event.currentTarget.value);
                              }} />
                          </div>
                        </div>
                        <div className="col-md-6 "></div>
                        <div className="col-md-6 ">
                          <div className="form-wrap  ">
                            <label className="" htmlFor="Email">{t('home.Email')} <ErrorMessage name="email" render={msg => <span className="error">(*{msg})</span>} /> </label>
                            <Field id="Email" type="text" name="Email" placeholder="Email" className="form-input" value={values.email || ''}
                              onChange={(event) => {
                                setFieldValue("email", event.currentTarget.value);
                              }} />
                          </div>
                        </div>
                        <div className="col-md-6 "></div>
                        <div className="col-md-6 ">
                          <div className="form-wrap  ">
                            <label className="" htmlFor="ATB-Auftrag-Nr">{t('home.ATB-Auftrag-Nr')} <ErrorMessage name="auftragNr" render={msg => <span className="error">(*{msg})</span>} /> </label>
                            <Field id="ATB-Auftrag-Nr" type="text" name="ATB-Auftrag-Nr" placeholder="ATB-Auftrag-Nr" className="form-input" value={values.auftragNr || ''}
                              onChange={(event) => {
                                setFieldValue("auftragNr", event.currentTarget.value);
                              }} />
                          </div>
                        </div>
                        <div className="col-md-6 "></div>
                        <div className="col-md-6 ">
                          <div className="form-wrap  ">
                            <label className="" htmlFor="ATB-Lieferschein-Nr">{t('home.ATB-Lieferschein-Nr')} <ErrorMessage name="lieferscheinNr" render={msg => <span className="error">(*{msg})</span>} /> </label>
                            {!currentSuggestion.customer &&
                              <Field id="lieferscheinNr" type="text" name="lieferscheinNr" placeholder="ATB-Lieferschein-Nr" className="form-input" value={values.lieferscheinNr || ''}
                                onChange={(event) => {
                                  setFieldValue("lieferscheinNr", event.currentTarget.value);
                                }} />
                            }
                            {currentSuggestion.customer &&
                              <select className="form-input select" value={values.lieferscheinNr || ''} onChange={(event) => {
                                setFieldValue("lieferscheinNr", event.currentTarget.value);
                                setFieldValue("auftragNr", currentSuggestion.customer.lieferscheinAuftrags[event.currentTarget.value]);
                                dispatch(getSerienNumbers({ "lieferscheinNr": event.currentTarget.value }))
                              }}>
                                {values.lieferscheinNr &&
                                  <option value={values.lieferscheinNr} label={values.lieferscheinNr} />
                                }
                                <option value="" label="please select" />
                                {currentSuggestion.customer && currentSuggestion.customer.lieferscheinAuftrags &&
                                  Object.entries(currentSuggestion.customer.lieferscheinAuftrags).map(([k, v]) => {
                                    return (
                                      <option key={"lie_" + k} value={k} label={v ? k + " - Auftrag Nr.: " + v : k} />
                                    )
                                  })
                                }
                              </select>
                            }
                          </div>
                        </div>
                        <div className="col-md-12">
                          <label className="" htmlFor="items">{t('home.Items')} <ErrorMessage name="items" render={msg => <span className="error">(*{msg})</span>} /> </label>
                          <table className="table table-striped table-hover table-bordered product-list nowrap">
                            <thead>
                              <tr>
                                <th className="width-procent-20">{t('home.SerialNr')}</th>
                                <th className="width-procent-20">{t('home.ArtikeNr')}</th>
                                <th className="width-procent-10">{t('home.Menge')}</th>
                                <th className="width-procent-15">{t('home.Reasons')}</th>
                                <th className="width-procent-20">{t('home.Description')}</th>
                                <th className="width-procent-5"> {t('home.Attachments')}</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {values.items.map((r, index) => {
                                return (
                                  <tr key={"row_" + index}>
                                    <td>{r.serialNr}</td>
                                    <td>
                                      {r.artikeNr}<br />
                                      {r.artikeKtx}
                                    </td>
                                    <td>{r.menge}</td>
                                    <td>
                                      {reasonTree &&
                                        reasonTree.map((t, index) => {
                                          return (
                                            <div key={"reason_r_" + index}>
                                              {t.children.filter(c => r.reasonIds.includes(c.id.toString())).length > 0 &&
                                                <>
                                                  <b>{t.name}: </b>
                                                  {t.children.filter(c => r.reasonIds.includes(c.id.toString())).map((c, index) => {
                                                    return (
                                                      <span key={"cp_" + c.id}>
                                                        {c.name},
                                                      </span>
                                                    )
                                                  })
                                                  }
                                                </>
                                              }
                                            </div>
                                          )
                                        })
                                      }
                                    </td>
                                    <td>{r.description}</td>
                                    <td>
                                      {(r.images.length > 0 || r.attachments.length > 0 || r.tempImages.length > 0 || r.tempAttachments.length > 0) &&
                                        <i className="icon icon-sm link-default mdi mdi-paperclip pointer"></i>
                                      }
                                    </td>
                                    <td>
                                      <div className="group group-middle">
                                        <button className="button button-sm button-primary button-winona" style={{ padding: "5px" }} type="button" onClick={() => {
                                          setCurrentClaimItem(r)
                                          setCurrentClaimItemIndex(index)
                                          setShow(true)
                                        }}>
                                        {["DRAFT"].includes(currentClaim.status)? "edit":"view"}
                                        </button>
                                        {["DRAFT"].includes(currentClaim.status) &&
                                          <button className="button button-sm button-primary button-winona" style={{ padding: "5px" }} type="button" onClick={() => {
                                            if (r.id) {
                                              dispatch(deleteClaimItemById({ id: currentClaim.id, claimItemId: r.id }))
                                            } else {
                                              values.items.splice(index, 1)
                                              setValues(values);
                                            }
                                          }}>{t('home.delete')}</button>
                                        }
                                      </div>
                                    </td>
                                  </tr>
                                )
                              })
                              }
                              {["DRAFT"].includes(currentClaim.status) &&
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td><button className="button button-sm button-primary button-winona" style={{ padding: "5px" }} type="button" onClick={() => {
                                    setCurrentClaimItem({
                                      tempImages: [], tempAttachments: [], images: [], attachments: [], artikeNr: "",
                                      menge: 1,
                                      classification: "",
                                      reasonIds: [],
                                    })
                                    setCurrentClaimItemIndex(null)
                                    setShow(true)
                                  }}>{t('home.AddClaimItem')}</button></td>
                                </tr>
                              }
                            </tbody>
                          </table>
                        </div>
                        <div className="col-md-12 ">
                          <div className="form-wrap  ">
                            <label className="" htmlFor="Bemerkung">{t('home.Bemerkung')}</label>
                            <Field id="Bemerkung" as="textarea" type="text" name="Bemerkung" placeholder="Bemerkung" className="form-input" value={values.bemerkung || ''}
                              onChange={(event) => {
                                setFieldValue("bemerkung", event.currentTarget.value);
                              }} />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <Dropzone
                            inputContent="Images Upload"
                            accept="image/*"
                            getUploadParams={({ file, meta }) => {
                              const uuid = uuidv4();
                              const body = new FormData()
                              body.append('uuid', uuid)
                              body.append('file', file)
                              body.append('type', 'IMAGE')
                              meta["uuid"] = uuid;
                              let headers = []
                              headers["Authorization"] = `Bearer ${token}`
                              let url = `${getHost()}/internal-api/v1/claims/upload-attachment/${uuid}`
                              if (values.id) {
                                url = `${getHost()}/internal-api/v1/claims/${values.id}/upload-attachment/${uuid}`
                              }
                              return { url: url, headers, body }
                            }}
                            onChangeStatus={({ meta, file, remove, xhr }, status) => {
                              if (status === "done") {
                                file["uuid"] = meta.uuid
                                file["file-type"] = 'IMAGE'
                                file["file-name"] = meta.name
                                let response = JSON.parse(xhr.response)
                                file["file-path"] = response.meta["file-path"]

                                if (response.meta["id"]) {
                                  let uploadFiles = values.images
                                  uploadFiles.push(file)
                                  setFieldValue("images", uploadFiles);
                                  remove();
                                } else {
                                  let uploadFiles = values.tempImages
                                  uploadFiles.push(file)
                                  setFieldValue("tempImages", uploadFiles);
                                  remove();
                                }
                              }
                            }}
                            accept="image/*"
                          />
                          {values.tempImages && values.tempImages.length > 0 &&
                            <table className="table table-default">
                              <thead></thead>
                              <tbody>
                                {
                                  values.tempImages.map((attachment, index) => {
                                    return (
                                      <tr key={"temp_attachment_" + attachment["uuid"]}>
                                        <td>{attachment["file-name"]}</td>
                                        <td>
                                          <a href={`/digicat/atb-attachments/claims/${attachment["file-path"]}`} target="_blank">
                                            <i className="icon icon-sm link-default mdi mdi-eye"></i>
                                          </a>
                                          <a href={`/digicat/atb-attachments/claims/${attachment["file-path"]}`} download={attachment["file-name"]} target="_blank">
                                            <i className="icon icon-sm link-default mdi mdi-download"></i>
                                          </a>
                                          {["DRAFT"].includes(currentClaim.status) &&
                                            <i className="icon icon-sm link-default mdi mdi-delete pointer" onClick={() => {
                                              if (window.confirm('Are you sure you wish to delete this item?')) {
                                                let uploadFiles = values.tempImages
                                                uploadFiles.splice(index, 1);
                                                setFieldValue("tempImages", uploadFiles);
                                              }
                                            }}></i>
                                          }
                                        </td>
                                      </tr>
                                    )
                                  })
                                }
                              </tbody>
                            </table>
                          }
                          {values.images && values.images.length > 0 &&
                            <table className="table table-default">
                              <thead></thead>
                              <tbody>
                                {
                                  values.images.map((attachment) => {
                                    return (
                                      <tr key={"attachment_" + attachment["uuid"]}>
                                        <td>{attachment["file-name"]}</td>
                                        <td>
                                          <a href={`/digicat/atb-attachments/claims/${attachment["file-path"]}`} target="_blank">
                                            <i className="icon icon-sm link-default mdi mdi-eye"></i>
                                          </a>
                                          <a href={`/digicat/atb-attachments/claims/${attachment["file-path"]}`} download={attachment["file-name"]} target="_blank">
                                            <i className="icon icon-sm link-default mdi mdi-download"></i>
                                          </a>
                                          {["DRAFT"].includes(currentClaim.status) &&
                                            <i className="icon icon-sm link-default mdi mdi-delete pointer" onClick={() => {
                                              if (window.confirm('Are you sure you wish to delete this item?')) {
                                                dispatch(deleteClaimAttachmentById({ id: values.id, claimAttachmentId: attachment.id }))
                                              }
                                            }}></i>
                                          }
                                        </td>
                                      </tr>
                                    )
                                  })
                                }
                              </tbody>
                            </table>
                          }
                        </div>

                        <div className="col-md-6">
                          <Dropzone
                            inputContent="Attachments Upload"
                            getUploadParams={({ file, meta }) => {
                              const uuid = uuidv4();
                              const body = new FormData()
                              body.append('uuid', uuid)
                              body.append('file', file)
                              body.append('type', 'ATTACHMENT')
                              meta["uuid"] = uuid;
                              let headers = []
                              headers["Authorization"] = `Bearer ${token}`
                              let url = `${getHost()}/internal-api/v1/claims/upload-attachment/${uuid}`
                              if (values.id) {
                                url = `${getHost()}/internal-api/v1/claims/${values.id}/upload-attachment/${uuid}`
                              }
                              return { url: url, headers, body }
                            }}
                            onChangeStatus={({ meta, file, remove, xhr }, status) => {
                              if (status === "done") {
                                file["uuid"] = meta.uuid
                                file["file-type"] = 'ATTACHMENT'
                                file["file-name"] = meta.name
                                let response = JSON.parse(xhr.response)
                                file["file-path"] = response.meta["file-path"]
                                if (response.meta["id"]) {
                                  let uploadFiles = values.attachments
                                  uploadFiles.push(file)
                                  setFieldValue("attachments", uploadFiles);
                                  remove();
                                } else {
                                  let uploadFiles = values.tempAttachments
                                  uploadFiles.push(file)
                                  setFieldValue("tempAttachments", uploadFiles);
                                  remove();
                                }
                              }
                            }}
                            accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          />


                          {values.tempAttachments && values.tempAttachments.length > 0 &&
                            <table className="table table-default">
                              <thead></thead>
                              <tbody>
                                {
                                  values.tempAttachments.map((attachment, index) => {
                                    return (
                                      <tr key={"temp_attachment_" + attachment["uuid"]}>
                                        <td>{attachment["file-name"]}</td>
                                        <td>
                                          <a href={`/digicat/atb-attachments/claims/${attachment["file-path"]}`} target="_blank">
                                            <i className="icon icon-sm link-default mdi mdi-eye"></i>
                                          </a>
                                          <a href={`/digicat/atb-attachments/claims/${attachment["file-path"]}`} download={attachment["file-name"]} target="_blank">
                                            <i className="icon icon-sm link-default mdi mdi-download"></i>
                                          </a>
                                          {["DRAFT"].includes(currentClaim.status) &&
                                            <i className="icon icon-sm link-default mdi mdi-delete pointer" onClick={() => {
                                              if (window.confirm('Are you sure you wish to delete this item?')) {
                                                let uploadFiles = values.tempAttachments
                                                uploadFiles.splice(index, 1);
                                                setFieldValue("tempAttachments", uploadFiles);
                                              }
                                            }}></i>
                                          }
                                        </td>
                                      </tr>
                                    )
                                  })
                                }
                              </tbody>
                            </table>
                          }
                          {values.attachments && values.attachments.length > 0 &&
                            <table className="table table-default">
                              <thead></thead>
                              <tbody>
                                {
                                  values.attachments.map((attachment) => {
                                    return (
                                      <tr key={"attachment_" + attachment["uuid"]}>
                                        <td>{attachment["file-name"]}</td>
                                        <td>
                                          <a href={`/digicat/atb-attachments/claims/${attachment["file-path"]}`} target="_blank">
                                            <i className="icon icon-sm link-default mdi mdi-eye"></i>
                                          </a>
                                          <a href={`/digicat/atb-attachments/claims/${attachment["file-path"]}`} download={attachment["file-name"]} target="_blank">
                                            <i className="icon icon-sm link-default mdi mdi-download"></i>
                                          </a>
                                          {["DRAFT"].includes(currentClaim.status) &&
                                            <i className="icon icon-sm link-default mdi mdi-delete pointer" onClick={() => {
                                              if (window.confirm('Are you sure you wish to delete this item?')) {
                                                dispatch(deleteClaimAttachmentById({ id: values.id, claimAttachmentId: attachment.id }))
                                              }
                                            }}></i>
                                          }
                                        </td>
                                      </tr>
                                    )
                                  })
                                }
                              </tbody>
                            </table>
                          }
                        </div>

                        <div className="col-md-12">
                          <div className="group group-middle">
                            {["DRAFT"].includes(currentClaim.status) &&
                              <button className="button button-winona" type="submit" >{currentClaim.id ? "update claim" : "create new claim"}</button>
                            }
                            {currentClaim.id && ["DRAFT"].includes(currentClaim.status) &&
                              <button className="button button-primary button-winona" type="button" onClick={() => {
                                dispatch(submitClaimById({ id: values.id }))
                              }} >{t('home.SubmitClaim')}</button>
                            }
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  )
}

export default withAuthenticationRequired(ClaimEdit, {
  onRedirecting: () => <Loading />,
});
